import Dropdown from 'bootstrap/js/src/dropdown';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggle'];

  close() {
    const dropdown = Dropdown.getInstance(this.toggleTarget);
    if (dropdown) {
      // Dropdown may never have been open
      dropdown.hide();
    }
  }
}
