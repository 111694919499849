import { Controller } from 'stimulus';
import createActivityDetector from 'activity-detector';

const TIME_TO_IDLE = 3000;

/**
 * Assign the detector to the window for use in other
 * controllers. This is best used on top-level elements
 * so the instance exists before use. This must be initialised
 * by another controller.
 *
 * https://github.com/tuenti/activity-detector
 */
export default class extends Controller {
  connect() {
    window.activityDetector = createActivityDetector({
      timeToIdle: TIME_TO_IDLE,
      autoInit: false,
    });
  }

  disconnect() {
    window.activityDetector.stop();
  }
}
