import { Controller } from 'stimulus';
import Modal from 'bootstrap/js/src/modal';

export default class extends Controller {
  static targets = [
    'modal',
    'form',
    'mobileContainer',
    'desktopContainer',
    'filters',
    'badge',
  ];

  connect() {
    this.modal = new Modal(this.modalTarget);

    this.modalTarget.addEventListener(
      'show.bs.modal',
      this.toMobile.bind(this)
    );
    this.modalTarget.addEventListener(
      'hide.bs.modal',
      this.toDesktop.bind(this)
    );

    this.mediaQueryList = window.matchMedia('(min-width: 1280px)');
    this.onMediaQueryListChange = this.hideOnMatch.bind(this);
    this.mediaQueryList.addListener(this.onMediaQueryListChange);

    this.beforeCache = () => this.modal.hide();
    document.addEventListener('turbo:before-cache', this.beforeCache);

    // TODO: Move to be in the partial itself rather than done from JS
    this.updateBadge();
  }

  disconnect() {
    this.mediaQueryList.removeListener(this.onMediaQueryListChange);
    document.removeEventListener('turbo:before-cache', this.beforeCache);
  }

  toMobile() {
    this.mobileContainerTarget.appendChild(this.filtersTarget);
  }

  toDesktop() {
    this.desktopContainerTarget.appendChild(this.filtersTarget);
  }

  updateBadge() {
    const checkedInputs = this.filtersTarget.querySelectorAll('input:checked');
    const uniqueCount = new Set([...checkedInputs].map((input) => input.name))
      .size;
    // Badge may be missing on some renders (eg. in mobile app)
    if (this.hasBadgeTarget) {
      this.badgeTarget.textContent = uniqueCount > 0 ? uniqueCount : '';
    }
  }

  hideOnMatch(event) {
    if (event.matches) {
      this.modal.hide();
    }
  }
}
