import { Controller } from 'stimulus';

/**
 * Automatically focuses the content of a Boostrap collapse
 * when it gets revealed, in case it's before the toggle
 * that revealed it.
 */
export default class extends Controller {
  connect() {
    this.listener = this.onShown.bind(this);
    this.element.addEventListener('shown.bs.collapse', this.listener);
  }

  disconnect() {
    this.element.removeEventListener('shown.bs.collapse', this.listener);
  }

  onShown() {
    // IMPROVEMENT: If necessary to target something inside the `.collapse`
    // element introduce a Stimulus target to mark which element to focus
    this.element.focus();
  }
}
