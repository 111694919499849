import { Controller } from 'stimulus';

/**
 * Controller that dispatches `clickoutside` events
 * when user clicks or sets the focus outside of its element
 *
 * Dispatched event provides the original `event` within its `detail`,
 * in case checks need to happen against it (like checking whether the
 * click was on the toggle of a disclosure element)
 */
export default class extends Controller {
  connect() {
    // https://kittygiraudel.com/2021/03/18/close-on-outside-click/
    this.listener = (event) => {
      if (!this.element.contains(event.target)) {
        const clickOutsideEvent = new CustomEvent('clickoutside', {
          bubbles: true,
          detail: { event },
        });
        this.element.dispatchEvent(clickOutsideEvent);
      }
    };

    window.addEventListener('click', this.listener);
    window.addEventListener('focusin', this.listener);
  }

  disconnect() {
    window.removeEventListener('click', this.listener);
    window.removeEventListener('focusin', this.listener);
  }
}
