import { Controller } from 'stimulus';
import { disable, enable } from '../aria_disabled_controller';

export default class extends Controller {
  static targets = [
    'submitter', // To be set on each button to enable/disable
    'checkbox', // To be set on the checkboxes selecting individual rows (not the "Select All one")
  ];

  connect() {
    this.updateSubmitterAvailability =
      this.updateSubmitterAvailability.bind(this);

    this.updateSubmitterAvailability();
    this.element.addEventListener('input', this.updateSubmitterAvailability);
    this.element.addEventListener(
      'amba:bulk_actions.did.hideActions',
      this.updateSubmitterAvailability
    );
  }

  disconnect() {
    this.element.removeEventListener('input', this.updateSubmitterAvailability);
    this.element.removeEventListener(
      'amba:bulk_actions.did.hideActions',
      this.updateSubmitterAvailability
    );
  }

  updateSubmitterAvailability() {
    if (this.checkboxTargets.some((checkbox) => checkbox.checked)) {
      this.enableSubmitters();
    } else {
      this.disableSubmitters();
    }
  }

  enableSubmitters() {
    for (const submitter of this.submitterTargets) {
      enable(submitter);
    }
  }

  disableSubmitters() {
    for (const submitter of this.submitterTargets) {
      disable(submitter);
    }
  }
}
