import {
  roundToNearest,
  timeOfDay,
  apneaIndex,
  painScore,
} from './axis_formats';
import merge from 'lodash/merge';

/**
 * x-axes options are consistent, however y-axes options vary by data type,
 * so detect the desired format (passed in `config.y_axis_format`)
 * and apply the respective options
 */
export function axesOptions(data, options, config) {
  return {
    layout: {
      padding: {
        left: 14,
        right: 16,
        bottom: 5,
      },
    },
    scales: {
      x: {
        display: true,
        ticks: {
          padding: 10,
          display: true,
          maxRotation: 45,
        },
      },
      y: {
        ...yAxisOptions(data, options, config),
      },
    },
  };
}

function yAxisOptions(data, options, { y_axis_format, round_to_nearest_unit }) {
  const yOptions = options?.scales?.y;

  switch (y_axis_format) {
    case 'round_to_nearest':
      return merge(yOptions, roundToNearest(data, round_to_nearest_unit));

    case 'time_of_day':
      return merge(yOptions, timeOfDay());

    case 'apnea_index':
      return apneaIndex(data);

    case 'pain_score':
      return painScore(data);

    default:
      return yOptions;
  }
}
