import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.muted = false;
    this.element.loop = false;
    this.element.play();
    // When turbo restores from the Snapshot cache remove the controller attribute so that we dont play the sound again.
    this.element.removeAttribute('data-controller');
  }
}
