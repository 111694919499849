import Modal from 'bootstrap/js/src/modal';
import { Controller } from 'stimulus';

/**
 * Handle the opening & closing of the mobile modal menu
 *
 * If we use bootstrap attributes instead, there's no Modal instance for the
 * native app to reference until after the modal is opened, so handle everything
 * with this controller.
 */
export default class extends Controller {
  static targets = ['button', 'modal'];

  connect() {
    this.modal = new Modal(this.modalTarget, this.options);

    this.handleToggleModal = this.toggleModal.bind(this);
    this.handleHideModal = this.modal.hide.bind(this);

    document.addEventListener('turbo:before-cache', this.handleHideModal);

    // Toggle the modal when we receive the event from the native app
    window.addEventListener(
      'amba_native:toggle_nav_bar',
      this.handleToggleModal
    );
  }

  disconnect() {
    window.removeEventListener(
      'amba_native:toggle_nav_bar',
      this.handleToggleModal
    );
    document.removeEventListener('turbo:before-cache', this.handleHideModal);
  }

  toggleModal() {
    this.modal.toggle();
  }

  get options() {
    return { backdrop: false };
  }
}
