import { Controller } from 'stimulus';

/**
 * `autofocus` focuses the element it belongs to as soon as it enters the DOM.
 * This is too early when inside a Bootstrap modal
 * (see [last point of their docs](https://getbootstrap.com/docs/5.0/components/modal/#how-it-works)).
 * This controller, to setup on the `.modal` element, corrects that by waiting
 * for the modal to be fully shown before focusing any element with `autofocus` within it.
 *
 * Note: Adding `tabindex="-1"` to elements not natively focusable is still necessary
 */
export default class extends Controller {
  connect() {
    this.focusElementWithAutofocus = this.focusElementWithAutofocus.bind(this);

    this.element.addEventListener(
      'shown.bs.modal',
      this.focusElementWithAutofocus
    );
  }

  disconnect() {
    this.element.removeEventListener(
      'shown.bs.modal',
      this.focusElementWithAutofocus
    );
  }

  focusElementWithAutofocus() {
    // Per spec, there must not be multiple elements with `autofocus`
    // https://html.spec.whatwg.org/dev/interaction.html#the-autofocus-attribute
    const elementWithAutofocus = this.element.querySelector('[autofocus]');
    if (elementWithAutofocus) {
      elementWithAutofocus.focus();
    }
  }
}
