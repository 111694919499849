import { Controller } from 'stimulus';

/**
 * Submit selected chart properties in the date form, by
 * passing their ids into a date input field
 */
export default class extends Controller {
  static targets = ['tippy', 'propertyInput', 'dateInput'];

  connect() {
    this.tippyTarget.addEventListener('tippy.shown', () => {
      const props = this.propertyInputTargets
        .filter((prop) => prop.checked)
        .map((prop) => prop.value);

      // The form is duplicated with tippy, so select the target
      // we want
      this.dateInputTargets[0].value = props;
    });
  }
}
