import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.showMessagesOnLoad();
    this.setupCheckboxListeners();
  }

  showMessagesOnLoad() {
    const currentMediumElements = document.querySelectorAll(
      "[data-target='limited-recipients.currentMedium']"
    );
    currentMediumElements.forEach((currentMediumElement) => {
      const medium = currentMediumElement.dataset.medium;
      const checkbox = document.querySelector(`[data-medium='${medium}']`);
      if (checkbox && checkbox.checked) {
        const messageElement = document.getElementById(`${medium}Message`);
        if (messageElement) {
          this.showMessage(messageElement);
        }
      }
    });
  }

  setupCheckboxListeners() {
    const checkboxes = document.querySelectorAll(
      "[data-target='limited-recipients.checkbox']"
    );
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', (event) => {
        const medium = event.target.dataset.medium;
        const messageElement = document.getElementById(`${medium}Message`);
        if (messageElement) {
          if (event.target.checked) {
            this.showMessage(messageElement);
          } else {
            this.hideMessage(messageElement);
          }
        }
      });
    });
  }

  showMessage(messageElement) {
    messageElement.classList.remove('d-none');
    messageElement.classList.add('d-block');
  }

  hideMessage(messageElement) {
    messageElement.classList.remove('d-block');
    messageElement.classList.add('d-none');
  }
}
