import { Controller } from 'stimulus';
import { Loader } from '@googlemaps/js-api-loader';

const CLASS_HIDDEN = 'd-none';

const CIRCLE_COLOR = '#146ff7';
const CIRCLE_STROKE_OPACITY = 0.8;
const CIRCLE_FILL_OPACITY = 0.35;

const MAP_CONTROL_SIZE = 30;

/**
 * Renders a google map with single/multiple
 * radii drawn.
 *
 * @value mapData - Updating this will trigger the map
 * to re-render. Use following structure:
 *   {
 *     0: {
 *      name:"Home",
 *        center: {
 *          lat: 51.4357796,
 *          lng: -2.6069599
 *        },
 *        :radius=>1000.0
 *      }
 *    }
 *
 */
export default class extends Controller {
  static targets = ['map', 'mapLoadingError', 'notEnoughDataMessage'];

  static values = {
    apiKey: String,
    mapData: Object,
  };

  initialize() {
    // Loads google maps script & assigns `google` globally
    if (!window.loader) {
      window.loader = new Loader({
        apiKey: this.apiKeyValue,
        version: 'weekly',
        libraries: ['places'],
      });
    }
  }

  mapDataValueChanged(mapData, previousMapData) {
    if (Object.keys(mapData).length > 0) {
      // If only the radius has changed, just update that instead
      // of re-rendering the whole map
      if (onlyRadiusChanged(mapData[0], previousMapData[0])) {
        this.updateRadius(mapData[0].radius);
      } else {
        this.renderMap();
      }
    } else {
      this.showNotEnoughDataMessage();
    }
  }

  updateRadius(radius) {
    this.circle.setRadius(radius);

    // Create new bounds to override previous ones we no
    // longer need
    this.bounds = new window.google.maps.LatLngBounds();
    this.bounds.union(this.circle.getBounds());

    this.zoomAndPanMap();
  }

  renderMap() {
    window.loader
      .load()
      .then((google) => {
        this.mapTarget.classList.remove(CLASS_HIDDEN);
        this.map = new google.maps.Map(this.mapTarget, {
          controlSize: MAP_CONTROL_SIZE,
        });

        // Create a bounds object to handle auto-zooming/panning
        this.bounds = new google.maps.LatLngBounds();

        for (const zone in this.mapDataValue) {
          // Draw a radius circle
          this.circle = new google.maps.Circle({
            strokeColor: CIRCLE_COLOR,
            strokeOpacity: CIRCLE_STROKE_OPACITY,
            strokeWeight: 1,
            fillColor: CIRCLE_COLOR,
            fillOpacity: CIRCLE_FILL_OPACITY,
            map: this.map,
            center: this.mapDataValue[zone].center,
            radius: this.mapDataValue[zone].radius,
          });

          // Add the number as marker text if there are multiple
          // markers
          const hasMultipleMarkers =
            Object.entries(this.mapDataValue).length > 1;

          let label;
          if (hasMultipleMarkers) {
            label = {
              text: (parseInt(zone) + 1).toString(),
              color: '#fff',
            };
          }

          // Draw the marker (circle) inside the radius circle
          const marker = new google.maps.Marker({
            icon: circleSymbol(hasMultipleMarkers),
            position: this.circle.center,
            title: zone.name,
            label: label,
            labelAnchor: new google.maps.Point(15, 65),
          });
          marker.setMap(this.map);

          // Add the entire circle to the bounds
          this.bounds.union(this.circle.getBounds());
        }

        this.zoomAndPanMap();
        return;
      })
      .catch(() => {
        this.mapLoadingErrorTarget.classList.remove(CLASS_HIDDEN);
      });
  }

  zoomAndPanMap() {
    // Auto-zoom/pan to the map bounds
    this.map.fitBounds(this.bounds);
    this.map.panToBounds(this.bounds);
  }

  showNotEnoughDataMessage() {
    this.notEnoughDataMessageTarget.classList.remove(CLASS_HIDDEN);
  }
}

function onlyRadiusChanged(mapData, previousMapData) {
  return (
    JSON.stringify(mapData.center) ===
      JSON.stringify(previousMapData?.center) &&
    mapData.radius !== previousMapData.radius
  );
}

function circleSymbol(large) {
  return {
    path: 'M-13,0a13,13 0 1,0 26,0a13,13 0 1,0 -26,0',
    fillColor: CIRCLE_COLOR,
    fillOpacity: 1,
    strokeColor: 'transparent',
    scale: large ? 1 : 0.5,
  };
}
