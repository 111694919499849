import { Controller } from 'stimulus';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDN89o5C5hqiFINELTNlmznW7gNp94tfOs',
  authDomain: 'amba-android-44ec1.firebaseapp.com',
  projectId: 'amba-android-44ec1',
  storageBucket: 'amba-android-44ec1.appspot.com',
  messagingSenderId: '599398082069',
  appId: '1:599398082069:web:7d8354bc8c4339a957501c',
};
const VAPID_KEY =
  'BPg3XRTRNdVkw32RI7oirNXauSGOaHOTCLgCpIjObBs5l0wdBnFXs9SQ2nbCigrIO_x-BnbGwhNikiF4bUMbMsI';

const CLASS_HIDDEN = 'd-none';
const CLASS_DISABLED = 'disabled';

/**
 * Handle foreground push notifications & our custom notifications toggle
 */
export default class extends Controller {
  static targets = [
    'radioOff',
    'radioOn',
    'radioToggle',
    'browserPermissionDeniedText',
    'toggleContainer',
  ];

  static values = { organisationId: Number };

  async connect() {
    if (this.shouldConnect) {
      this.toggleContainerTarget.classList.remove(CLASS_HIDDEN);

      this.app = initializeApp(FIREBASE_CONFIG);
      this.messaging = getMessaging();
      this.registration = await navigator.serviceWorker.getRegistration(
        '/firebase-messaging-sw.js'
      );

      if (Notification.permission === 'granted') {
        if (this.hasRadioOffTarget) this.setToggleState();
        this.setupNativePermissionsChangeListener();
      } else if (Notification.permission === 'denied') {
        this.disableToggle();
      } else {
        this.requestPermission();
      }
    }
  }

  shouldConnect() {
    return (
      'serviceWorker' in navigator &&
      window.Notification &&
      this.hasOrganisationIdValue
    );
  }

  //// Browser permissions

  requestPermission() {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        getToken(this.messaging, {
          serviceWorkerRegistration: this.registration,
          vapidKey: VAPID_KEY,
        })
          .then((currentToken) => {
            if (currentToken) {
              this.postToken(currentToken);
            } else {
              window.appsignal.sendError('Failed to fetch token from FCM');
            }

            return;
          })
          .catch((error) => {
            window.appsignal.sendError(`Error creating token on FCM: ${error}`);
          });
      }
    });
  }

  setupNativePermissionsChangeListener() {
    if (
      Notification.permission === 'granted' ||
      Notification.permission === 'denied'
    ) {
      navigator.permissions
        .query({ name: 'notifications' })
        .then((notificationPermission) => {
          notificationPermission.onchange = () => {
            this.handleNativePermissionsChange(notificationPermission.state);
          };

          return;
        })
        .catch((error) => {
          window.appsignal.sendError(
            `Error handling browser permissions change: ${error}`
          );
        });
    }
  }

  async handleNativePermissionsChange(state) {
    if (state === 'denied') {
      if (this.currentToken) this.postTokenUnregistration(this.currentToken);
      this.disableToggle();
    } else if (state === 'granted') {
      this.requestPermission();
    }
  }

  //// Tokens

  async getTokensFromServer() {
    try {
      const response = await fetch(
        `/web_notification_tokens?organisation_id=${this.organisationIdValue}`
      );

      if (response.ok) {
        const data = await response.json();

        if (data.tokens) {
          return data.tokens;
        }
      } else {
        window.appsignal.sendError(
          `Failed to fetch tokens from server: Status: ${response.status}, Url: ${response.url}`
        );
      }
    } catch (error) {
      window.appsignal.sendError(`Error fetching tokens from server: ${error}`);
    }
  }

  postToken(token) {
    const data = {
      token: token,
      organisation_id: this.organisationIdValue,
    };

    fetch('/web_notification_tokens', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          this.currentToken = token;
          console.log(`INITIAL TOKEN: ${token}`);
          this.toggleRadioOn();
        } else {
          window.appsignal.sendError(
            `Failed to post token to server: Status: ${response.status}, Url: ${response.url}`
          );
        }

        return;
      })
      .catch((error) => {
        window.appsignal.sendError(`Error posting token to server: ${error}`);
      });
  }

  postTokenUnregistration(token) {
    const data = {
      token: token,
    };

    fetch(
      `/web_notification_tokens/${token}?organisation_id=${this.organisationIdValue}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.ok) {
          this.currentToken = null;

          return;
        } else {
          window.appsignal.sendError(
            `Failed to delete token from server: Status: ${response.status}, Url: ${response.url}`
          );

          return;
        }
      })
      .catch((error) => {
        window.appsignal.sendError(
          `Error deleting token from server: ${error}`
        );
      });
  }

  //// Notifications toggle

  async setToggleState() {
    const userTokens = await this.getTokensFromServer();

    getToken(this.messaging, {
      serviceWorkerRegistration: this.registration,
      vapidKey: VAPID_KEY,
    })
      .then((currentToken) => {
        if (Notification.permission === 'granted') {
          console.log(`FCM TOKEN: ${currentToken}`);
          console.log(`USER TOKENS: ${userTokens}`);
          console.log(userTokens.includes(currentToken));

          if (userTokens.includes(currentToken)) {
            this.currentToken = currentToken;

            this.toggleRadioOn();
          }
        }
        return;
      })
      .catch((error) => {
        window.appsignal.sendError(`Error creating token on FCM: ${error}`);
      });
  }

  async handleNotificationToggleChange() {
    if (this.radioOnTarget.checked === true) {
      this.requestPermission();
    } else {
      const userTokens = await this.getTokensFromServer();

      console.log(userTokens, this.currentToken);
      console.log(userTokens.includes(this.currentToken));

      if (this.currentToken && userTokens.includes(this.currentToken)) {
        this.postTokenUnregistration(this.currentToken);
      }
    }
  }

  toggleRadioOn() {
    if (this.hasRadioOnTarget) {
      this.radioOnTarget.checked = true;
      this.browserPermissionDeniedTextTarget.classList.add(CLASS_HIDDEN);
    }
  }

  enableToggle() {
    if (this.hasRadioOnTarget) {
      this.browserPermissionDeniedTextTarget.classList.add(CLASS_HIDDEN);
      this.radioToggleTarget.classList.remove(CLASS_DISABLED);
      [this.radioOnTarget, this.radioOffTarget].forEach((input) => {
        input.disabled = false;
      });
    }
  }

  disableToggle() {
    if (this.hasRadioOnTarget) {
      this.browserPermissionDeniedTextTarget.classList.remove(CLASS_HIDDEN);
      this.radioToggleTarget.classList.add(CLASS_DISABLED);
      [this.radioOnTarget, this.radioOffTarget].forEach((input) => {
        input.disabled = true;
      });
    }
  }
}
