import { Controller } from 'stimulus';

const LOCAL_STORAGE_KEY = 'sidenav-minimized';
const CLASS_MINIMIZED = 'amba-sidenav-slim';

export default class extends Controller {
  connect() {
    const minimized = localStorage.getItem(LOCAL_STORAGE_KEY) == 'true';

    this.element.classList.toggle(CLASS_MINIMIZED, minimized);
  }

  toggle(event) {
    event.preventDefault();

    this.element.classList.toggle(CLASS_MINIMIZED);

    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      this.element.classList.contains(CLASS_MINIMIZED)
    );

    this.element.dispatchEvent(
      new CustomEvent('sidenavToggled', {
        bubbles: true,
      })
    );
  }
}
