import { Controller } from 'stimulus';

/**
 * Controller added to the tippy popovers
 * allowing elements within the popover
 * to dismiss it when necessary
 */
export default class extends Controller {
  get tippy() {
    return this.element._tippy;
  }

  dismiss(event) {
    /**
     * Skip a frame to allow the DOM to be untouched
     * until the event has completely been processed.
     * This ensures things like `<form>` elements
     * generated by `button_to` properly submit
     */
    requestAnimationFrame(() => {
      this.tippy.hide();
      if (event.target.getAttribute('data-tippy-content-focus') == 'false') {
        this.tippy.reference.focus();
      }
    });
  }
}
