import { Controller } from 'stimulus';
import Sortable from 'sortablejs';

const CLASS_SORTABLE_GHOST = 'amba-sortable-ghost';

const SELECTOR_HANDLE_BTN = '.amba-sortable-handle';
const SELECTOR_BTN = '.amba-sortable-btn';
const SELECTOR_BTN_PREVIOUS = '.amba-sortable-btn--prev';
const SELECTOR_BTN_NEXT = '.amba-sortable-btn--next';
const SELECTOR_SORTABLE_KEY = '[data-sortable-key]';

const TEXT_START_OF_LIST = 'Already at start of list';
const TEXT_END_OF_LIST = 'Already at end of list';

/**
 * Manage a sortablejs list and add next/prev button functionality &
 * a11y announcements.
 * The controller updates a list of visible sortable elements on the page, as well
 * as a full list of sortable keys that are PATCH'ed to the urlValue
 */
export default class extends Controller {
  static targets = ['list', 'item', 'moveButton', 'announcement'];
  static values = { url: String, order: Array };

  connect() {
    this.sortable = Sortable.create(this.listTarget, {
      ghostClass: CLASS_SORTABLE_GHOST,
      handle: SELECTOR_HANDLE_BTN,
      onUpdate: this.onUpdate,
    });

    this.initialized = true;
  }

  moveButtonTargetConnected(btn) {
    // Disable the first previous & the last next buttons once they connect.
    // Use booleans to track whether this has already happened for both, and
    // avoid running this code unnecessarily.
    if (!this.disabledFirstPrevBtnOnLoad || !this.disabledLastNextBtnOnLoad) {
      const item = btn.closest("[data-sortable-target='item']");
      const itemIndex = this.itemTargets.indexOf(item);

      if (itemIndex === 0) {
        this.disablePreviousBtnFor(item);
        this.disabledFirstPrevBtnOnLoad = true;
      } else if (itemIndex === this.itemTargets.length - 1) {
        this.disableNextBtnFor(item);
        this.disabledLastNextBtnOnLoad = true;
      }
    }
  }

  onUpdate = ({ item, newIndex }) => {
    this.setBtnDisabledStates();
    this.updateOrderPosition(item, newIndex);
  };

  //// Order updating

  updateOrderPosition(item, newIndex) {
    // Clone the value so we can mutate it without updating it
    // before we're done with it
    const orderValue = this.orderValue;

    // For the key we need to move, find it's current index
    const currentItemIndex = orderValue.indexOf(item.dataset.sortableKey);

    // Remove and return the element from the order
    const elementToMove = orderValue.splice(currentItemIndex, 1)[0];

    // Get the target index
    const indexToInsertAt = this.getIndexForItemInFullArray(
      item,
      newIndex,
      orderValue
    );

    // Move the key to the new position
    orderValue.splice(indexToInsertAt, 0, elementToMove);

    // Update & announcement the change
    this.orderValue = orderValue;
    this.updateAnnouncement(elementToMove);
  }

  getIndexForItemInFullArray(item, newIndex, order) {
    if (newIndex == this.sortableItems.length - 1) {
      const key = item.previousElementSibling?.dataset?.sortableKey;
      return order.indexOf(key) + 1;
    } else {
      const key = item.nextElementSibling?.dataset?.sortableKey;
      return order.indexOf(key);
    }
  }

  //// Fetch request

  orderValueChanged(newOrder) {
    if (this.initialized) {
      fetch(this.urlValue, {
        method: 'PATCH',
        headers: this.headers,
        body: JSON.stringify({
          sortable_settings: {
            order: newOrder,
          },
        }),
      });
    }
  }

  //// Button functionality

  previous = (event) => {
    const item = event.currentTarget.closest(SELECTOR_SORTABLE_KEY);
    const previousItem = item?.previousElementSibling;

    if (previousItem) {
      item.parentNode.insertBefore(item, previousItem);

      this.setBtnDisabledStates();
      this.updateOrderPosition(item, this.orderPositionFor(item));

      event.currentTarget.focus();
    }
  };

  next = (event) => {
    const item = event.currentTarget.closest(SELECTOR_SORTABLE_KEY);
    const nextItem = item?.nextElementSibling;

    if (nextItem) {
      item.parentNode.insertBefore(item, nextItem.nextElementSibling);

      this.setBtnDisabledStates();
      this.updateOrderPosition(item, this.orderPositionFor(item));

      event.currentTarget.focus();
    }
  };

  orderPositionFor(item) {
    return this.sortableItems.indexOf(item);
  }

  setBtnDisabledStates() {
    this.sortableItems.forEach((item, index) => {
      this.enableAllBtnsFor(item);

      if (index === 0) {
        this.disablePreviousBtnFor(item);
      } else if (index === this.sortableItems.length - 1) {
        this.disableNextBtnFor(item);
      }
    });
  }

  disablePreviousBtnFor(item) {
    const previousBtn = item.querySelector(SELECTOR_BTN_PREVIOUS);

    previousBtn.setAttribute('aria-disabled', 'true');
    previousBtn.querySelector('span.visually-hidden').textContent =
      TEXT_START_OF_LIST;
  }

  disableNextBtnFor(item) {
    const nextBtn = item.querySelector(SELECTOR_BTN_NEXT);

    nextBtn.setAttribute('aria-disabled', 'true');
    nextBtn.querySelector('span.visually-hidden').textContent =
      TEXT_END_OF_LIST;
  }

  enableAllBtnsFor(item) {
    item.querySelectorAll(SELECTOR_BTN).forEach((btn) => {
      btn.setAttribute('aria-disabled', 'false');

      const accessibleTextEl = btn.querySelector('span.visually-hidden');
      accessibleTextEl.textContent = accessibleTextEl.dataset.originalMoveText;
    });
  }

  //// A11y announcements

  updateAnnouncement(elementToMove) {
    const position = this.sortableKeys.indexOf(elementToMove) + 1;
    const elementKeyHumanized = elementToMove.replaceAll('_', ' ');

    this.announcementTarget.textContent = `${elementKeyHumanized} moved to position ${position}`;
  }

  get sortableItems() {
    return this.itemTargets;
  }

  get sortableKeys() {
    return this.sortableItems.map((el) => el.dataset.sortableKey);
  }

  get headers() {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
        ?.content,
      'Content-type': 'application/json; charset=UTF-8',
    };
  }
}
