import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'valueInputContainer', 'fieldset'];

  connect() {
    if (this.activeInput && !this.activeInput.checked) {
      this.fieldsetTarget.disabled = true;
      this.fieldsetTarget.classList.add('d-none');

      if (this.hasValueInputContainerTarget) {
        this.valueInputContainerTarget.classList.add('d-none');
      }
    }
  }

  toggleFieldset() {
    if (this.activeInput.checked) {
      this.fieldsetTarget.disabled = false;
      this.fieldsetTarget.classList.remove('d-none');

      if (this.hasValueInputContainerTarget) {
        this.valueInputContainerTarget.classList.remove('d-none');
      }
    } else {
      this.fieldsetTarget.disabled = true;
      this.fieldsetTarget.classList.add('d-none');

      if (this.hasValueInputContainerTarget) {
        this.valueInputContainerTarget.classList.add('d-none');
      }
    }
  }

  get activeInput() {
    return this.inputTargets.find((el) => el.value === 'true');
  }
}
