import { Controller } from 'stimulus';

/**
 * Provide an action to reload the data tab frame
 */
export default class extends Controller {
  static targets = ['propertyInput', 'frame'];
  static values = { dataUrl: String };

  reloadDataFrame() {
    this.frameTarget.src = null;

    const url = new URL(this.dataUrlValue);
    url.searchParams.append('current_property_ids', this.selectedPropertyIds);

    this.frameTarget.src = url;
  }

  get selectedPropertyIds() {
    return this.propertyInputTargets
      .filter((input) => input.checked)
      .map((input) => input.value);
  }
}
