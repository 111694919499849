import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fieldset', 'checkbox'];

  connect() {
    this.updateFieldset([...this.checkboxTargets].find((el) => el.checked));
  }

  updateFieldsetFromEvent(event) {
    this.updateFieldset(event.currentTarget);
  }

  updateFieldset(inputElement) {
    if (typeof inputElement == 'undefined') {
      this.fieldsetTarget.classList.add('d-none');
      this.fieldsetTarget.disabled = true;
      return;
    }

    if (inputElement.value == 'custom') {
      this.fieldsetTarget.classList.remove('d-none');
      this.fieldsetTarget.disabled = false;
    } else {
      this.fieldsetTarget.classList.add('d-none');
      this.fieldsetTarget.disabled = true;
    }
  }
}
