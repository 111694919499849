import { Controller } from 'stimulus';

/**
 * Reveals the element when it gets some content
 */
export default class extends Controller {
  static targets = ['contentContainer'];

  get contentContainer() {
    if (this.hasContentContainerTarget) {
      return this.contentContainerTarget;
    }

    return this.element;
  }

  connect() {
    const observer = (this.observer = new MutationObserver(
      (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.target.children.length) {
            observer.disconnect();
            this.element.classList.remove('d-none');
          }
        }
      }
    ));
    observer.observe(this.contentContainer, { childList: true });
  }

  disconnect() {
    this.observer.takeRecords();
    this.observer.disconnect();
  }
}
