import { Controller } from 'stimulus';

const PROMPT = `Looks like you have some unsaved changes.
They'll be lost if you navigate away.

Are you sure you want to continue?`;

/**
 * Allow to ignore inputs in specific conditions:
 * - Non POST forms (unless data-confirm-navigation-force attribute is present)
 * - Marked with `data-confirm-navigation-ignore-input` or within an element marked as such
 */
const SELECTOR_IGNORE_INPUT = `
  form:not([method="post"]):not([data-confirm-navigation-force]) *,
  [data-confirm-navigation-ignore-input-within] * ,
  [data-confirm-navigation-ignore-input]
`;

/**
 * Monitors `input` on form controls within POST forms
 * and prompts users before they navigate away or take specific actions
 * (outside of submitting the form, of course).
 *
 * Supports:
 *  - browser navigation: page reloads, entering a URL, clicking a bookmark,
 *    back/forward navigation *leading outside the app*
 *  - Turbo drive: prompts before visiting and cancels the visit if user doesn't confirm.
 *    It *does not* handle back/forward navigation through Turbo Drive due to Turbo
 *    not having events to intercept visits happening through history manipulation.
 *    https://github.com/hotwired/turbo/issues/485
 *  - specific actions: use `data-action="EVENT->confirm-navigation#confirmIfNeeded"`
 *    to hook a confirmation on specific actions.
 *    The controller will prevent the event's default if the user doesn't confirm.
 *
 * The controller ignores any field that's not in a `method="post"` form.
 * It'll also ignore any field marked with `data-confirm-navigation-ignore-input`
 * or within an element marked with `data-confirm-navigation-ignore-input-within`.
 *
 * > Note: If form submission was to happen within a Turbo Frame,
 * > we'll need to introduce a way to clean up whether
 * > we still require confirmation after successful submissions
 * > This'll likely require to track the `name` or `id` of the modified fields
 * > and match them against the data of the `turbo:submit-end` event
 * > https://turbo.hotwired.dev/reference/events
 */
export default class extends Controller {
  initialize() {
    this.onInput = this.onInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitEnd = this.onSubmit.bind(this);
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.onBeforeVisit = this.onBeforeVisit.bind(this);
  }

  connect() {
    this.element.addEventListener('input', this.onInput);
    this.element.addEventListener('cocoon:after-remove', this.onInput);
    this.element.addEventListener('cocoon:after-insert', this.onInput);
    // Unfortunately, the `turbo:before-visit` event doesn't tell
    // if it's a link click or a form submission, so we need
    // to track this ourselves
    this.element.addEventListener('submit', this.onSubmit);
    this.element.addEventListener('turbo:submit-end', this.onSubmitEnd);
    this.element.addEventListener('amba:fetch-error', this.onSubmitEnd);
  }

  disconnect() {
    this.unregisterAlerts();
    this.element.removeEventListener('input', this.onInput);
    this.element.removeEventListener('cocoon:after-remove', this.onInput);
    this.element.removeEventListener('cocoon:after-insert', this.onInput);
    this.element.removeEventListener('submit', this.onSubmit);
    this.element.addEventListener('turbo:submit-end', this.onSubmitEnd);
    this.element.addEventListener('amba:fetch-error', this.onSubmitEnd);
  }

  confirmIfNeeded(event) {
    if (this.registeredAlerts) {
      if (confirm(PROMPT)) {
        this.unregisterAlerts();
      } else {
        // Ideally we'd want to only `preventDefault`
        // in case other controllers would be interested
        // in the event.
        // If possible, please update whatever handles
        // the event with `if (!event.defaultPrevented)`
        // before adding `event.stop[Immediate]Propagation()`
        event.preventDefault();
      }
    }
  }

  onInput(event) {
    if (!event.target.matches(SELECTOR_IGNORE_INPUT)) {
      this.registerAlerts();
    }
  }

  onSubmit(event) {
    // GET submissions are navigations, so we only want to mark
    // that we're submitting for POST forms
    this.isSubmit = event.target.method == 'post';
  }

  onSubmitEnd() {
    this.isSubmit = false;
  }

  registerAlerts() {
    if (!this.registeredAlerts) {
      window.onbeforeunload = this.onBeforeUnload;
      document.addEventListener('turbo:before-visit', this.onBeforeVisit);
      this.registeredAlerts = true;
    }
  }

  unregisterAlerts() {
    this.isDirty = false;
    window.onbeforeunload = null;
    document.removeEventListener('turbo:before-visit', this.onBeforeVisit);
    this.registeredAlerts = false;
  }

  onBeforeUnload() {
    // Only older browsers will show the custom message as Firefox, Chrome and Safari all
    // have dropped displaying a custom message. However, the method needs to return something
    // so may as well be our custom message.
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
    // https://bugzilla.mozilla.org/show_bug.cgi?id=588292
    // https://chromestatus.com/feature/5349061406228480
    // https://developer.apple.com/library/archive/releasenotes/General/WhatsNewInSafari/Articles/Safari_9_1.html#//apple_ref/doc/uid/TP40014305-CH10-SW11

    // Don't hold up POST form submissions
    if (!this.isSubmit) {
      return PROMPT;
    }
  }

  onBeforeVisit(event) {
    if (!this.isSubmit) {
      if (confirm(PROMPT)) {
        this.unregisterAlerts();
      } else {
        event.preventDefault();
      }
    }
  }
}
