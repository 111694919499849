import { Controller } from 'stimulus';
import Toast from 'bootstrap/js/src/toast';

export const INSTANCES = new WeakMap();
const DEFUALT_DELAY_VALUE = 5000;
const CLASSES_TOAST = ['toast-success', 'toast-error'];

/**
 * Initialize a bootstrap toast. Provide a method to update
 * the toast message & class from an ajax response.
 *
 * @target toastTextContent - Text content element adjacent to toast icon.
 * @value delay - Milliseconds toast should be shown.
 */

export default class extends Controller {
  static targets = ['toastTextContent'];
  static values = { delay: Number };

  initialize() {
    INSTANCES.set(this.element, this);
  }

  connect() {
    const options = {
      delay: this.delayValue || DEFUALT_DELAY_VALUE,
    };

    this.toast = new Toast(this.element, options);
  }

  renderToastWith(message, status) {
    this.toastTextContentTarget.textContent = message;

    this.element.classList.remove(...CLASSES_TOAST);
    this.element.classList.add(`toast-${status}`);

    this.toast.show();
  }
}
