import { Controller } from 'stimulus';

/**
 * Methods for validating the field values
 * for each of the error types
 */
const VALIDATION_ERRORS = {
  too_short(value, { minimum }) {
    return value.length < parseInt(minimum);
  },
  no_lowercase(value) {
    return !value.match(/[a-z]/);
  },
  no_uppercase(value) {
    return !value.match(/[A-Z]/);
  },
  no_special_characters(value, { regexp }) {
    return !value.match(new RegExp(`[${regexp}]`));
  },
};

/**
 * Methods for formatting the accessible announcement
 * for each of the error types
 */
const ANNOUNCEMENTS = {
  too_short(value, { minimum }) {
    const missing = minimum - value.length;
    if (missing > 0) {
      return `${missing} character${missing > 1 ? 's' : ''}`;
    }
  },
  no_lowercase: () => '1 lowercase letter',
  no_uppercase: () => '1 uppercase letter',
  no_special_characters: () => '1 special character',
};

/**
 * Handles validations of entered passwords as people type,
 * as well as when they exit the field. Hook the following
 * actions to get going:
 * - `input->password-validation#validate` to validate on input
 * - `focusout->password-validation#updateFieldState`
 *    to set the field as invalid when users leave the field
 *
 * The controller also takes care of announcing missed requirements
 * to assistive technologies so they get progress on matching
 * the password criteria too
 */
export default class extends Controller {
  static targets = [
    // The `<input>` element to watch the input in
    'input',
    // One or several element showing the different hints
    // for the password criteria. Each should also have a
    // data-password-validation-type attribute matching
    // one of the keys in the VALIDATIONS and ANNOUNCEMENTS
    'hint',
    // A visually-hidden div for announcement to assistive tech
    'liveRegion',
  ];

  static values = {
    // A Hash to hold options passed to the VALIDATIONS
    // and ANNOUNCEMENTS functions for each validation type
    // For ex:
    //
    // ```
    // {
    //   too_short: {minimum: 8}
    // }
    // ```
    options: Object,
  };

  connect() {
    this.hintElements = index(this.hintTargets, {
      by: (element) => element.dataset.passwordValidationType,
    });
  }

  disconnect() {
    clearTimeout(this.announcementTimeout);
  }

  get errors() {
    return this._errors;
  }

  /**
   * Custom setter for the `errors`
   * so we can track the previousErrors too.
   */
  set errors(value) {
    this.previousErrors = this.errors;
    this._errors = value;
  }

  validate() {
    const validationStates = Object.entries(VALIDATION_ERRORS).map(
      ([errorType, validator]) => {
        const validity = validator(
          this.inputTarget.value,
          this.optionsValue[errorType]
        );

        this.updateHintState(errorType, validity);

        return [errorType, validity];
      }
    );

    // ESLint seems to struggle with the destructuring in the arguments :(
    // eslint-disable-next-line no-unused-vars
    this.errors = validationStates.filter(([errorType, invalid]) => invalid);

    this.scheduleAnnouncement();
  }

  scheduleAnnouncement() {
    // Clear live region so that repeated errors
    // get re-announced after user made updates
    this.liveRegionTarget.textContent = '';

    clearTimeout(this.announcementTimeout);
    this.announcementTimeout = setTimeout(() => {
      this.liveRegionTarget.textContent = this.announcementMessage();
    }, 500);
  }

  announcementMessage() {
    if (this.errors.length) {
      const messageParts = this.errors
        .map(([errorType]) => {
          return ANNOUNCEMENTS[errorType](
            this.inputTarget.value,
            this.optionsValue[errorType]
          );
        })
        .filter(Boolean);

      return `Missing ${messageParts.join(', ')}`;
    } else {
      // We don't want to repeat the announcement again
      // and again while the password is valid
      // `previousErrors` may not be set if user pastes into the fields
      if (!this.previousErrors || this.previousErrors.length) {
        return 'Meets all criteria';
      }
    }
  }

  updateFieldState(event) {
    // Check if we're leaving the element to avoid
    // pre-emptively marking the field as invalid
    // if the user just goes to reveal their password
    if (!this.element.contains(event.relatedTarget)) {
      if (this.errors?.length) {
        this.inputTarget.classList.add('is-invalid');
        this.inputTarget.setAttribute('aria-invalid', '');
      } else {
        this.inputTarget.classList.remove('is-invalid');
        this.inputTarget.removeAttribute('aria-invalid');
      }
    }
  }

  updateHintState(validationType, invalid) {
    const hintElement = this.hintElements[validationType];

    if (invalid) {
      hintElement.classList.add('invalid-feedback');
      this.setIcon(hintElement, 'fa-times-circle text-danger');
    } else {
      hintElement.classList.remove('invalid-feedback');
      this.setIcon(hintElement, 'fa-check-circle text-success', {
        success: true,
      });
    }
  }

  setIcon(hintElement, iconClasses, { success } = {}) {
    const existingIcon = hintElement.querySelector('i');

    if (existingIcon) {
      existingIcon.parentElement.removeChild(existingIcon);
    }

    const newIcon = document.createElement('i');
    newIcon.setAttribute('class', `fas fa-fw ${iconClasses}`);
    newIcon.setAttribute('aria-label', success ? 'Meets' : 'Missing');
    hintElement.insertAdjacentElement('afterbegin', newIcon);
  }
}

/**
 * @param {Iterable} iterable
 * @param {Function} options.by - Getter run on each item to pick the index key
 * @returns {Object}
 */
function index(iterable, { by }) {
  const index = {};

  for (const item of iterable) {
    index[by(item)] = item;
  }

  return index;
}
