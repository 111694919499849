import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['all', 'single'];

  connect() {
    this.updateSelectAllCheckbox = this.updateSelectAllCheckbox.bind(this);
    this.updateSingleCheckboxes = this.updateSingleCheckboxes.bind(this);

    // Indeterminate state cannot be set from serve
    // so we need to start with an update
    this.updateSelectAllCheckbox();

    this.allTarget.addEventListener('input', this.updateSingleCheckboxes);
    for (const checkbox of this.singleTargets) {
      checkbox.addEventListener('input', this.updateSelectAllCheckbox);
    }
  }

  updateSelectAllCheckbox() {
    if (!this.hasAllTarget) return;

    let unchecked = 0;
    let checked = 0;

    for (const checkbox of this.singleTargets) {
      if (checkbox.checked) {
        checked++;
      } else {
        unchecked++;
      }
      // Break as soon as we find two checkboxes in different states
      if (unchecked > 0 && checked > 0) {
        this.allTarget.indeterminate = true;
        // Ensures that clicks on the indeterminate state consistently select all
        // rather than be dependent on the checkbox state before being indeterminate
        this.allTarget.checked = false;
        return;
      }
    }

    if (unchecked) {
      this.allTarget.checked = false;
      this.allTarget.indeterminate = false;
    } else {
      this.allTarget.checked = true;
      this.allTarget.indeterminate = false;
    }
  }

  updateSingleCheckboxes() {
    for (const checkbox of this.singleTargets) {
      if (!checkbox.disabled) {
        checkbox.checked = this.allTarget.checked;
      }
    }
  }

  clear() {
    this.allTarget.checked = false;
    this.allTarget.indeterminate = false;
    this.updateSingleCheckboxes();
  }
}
