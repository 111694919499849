import ClipboardJS from 'clipboard';
import { Controller } from 'stimulus';
import { announce } from '../javascript/lib/a11y/announcement';

/**
 * Generic setup for ClipboardJS that'll recognise
 * any button with a `data-clipboard-target` or `data-clipboard-text`
 * attribute and dispatch an event for hooking Stimulus actions
 * https://clipboardjs.com/
 *
 * Controller to be set-up on the page's body
 */
export default class extends Controller {
  connect() {
    this.clipboard = new ClipboardJS(
      '[data-clipboard-target],[data-clipboard-text]',
      {
        text(trigger) {
          // Gather content to copy ourselves, as Clipboard.js way
          // sets plenty of whitespace at the start of the strings
          if (trigger.hasAttribute('data-clipboard-target')) {
            return [
              ...document.querySelectorAll(
                trigger.getAttribute('data-clipboard-target')
              ),
            ]
              .map(contentToCopy)
              .map(normalizeWhitespace)
              .join('\n\n');
          }
        },
        container: document.documentElement,
      }
    );

    this.clipboard.on('success', function (copy) {
      copy.clearSelection();
      copy.trigger.dispatchEvent(
        new CustomEvent('clipboard-success', {
          bubbles: true,
        })
      );

      announce('Copied!');
    });

    this.clipboard.on('error', function (copy) {
      copy.trigger.dispatchEvent(
        new CustomEvent('clipboard-error', {
          bubbles: true,
        })
      );
    });
  }

  disconnect() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }
}

function normalizeWhitespace(text) {
  return text
    .replace(/[\s\n]+/g, ' ')
    .replace(/^\s/, '')
    .replace(/\s$/, '');
}

// Variation on https://github.com/github/clipboard-copy-element/blob/1bc0008929be00c219a208f63a7ee991b4c2b2f6/src/clipboard-copy-element.ts#L25
function contentToCopy(element) {
  if ('value' in element) {
    return element.value;
  } else if (
    element instanceof HTMLAnchorElement &&
    element.hasAttribute('href')
  ) {
    return element.href;
  } else {
    return element.textContent;
  }
}
