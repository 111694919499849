import SELECTOR_FOCUSABLE from 'focusable-selectors';
import { Controller } from 'stimulus';

/**
 * Turbo messes up with skip-links, especially if the hash is already in the URL
 * (which may happen after a reload, for example). It scrolls the page, but the focus
 * doesn't move, which is the whole point of having skip links.
 *
 * This controller ensures that clicking the skip links will also move the focus
 * to the right place.
 */
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.focusTargetElement);
  }
  disconnect() {
    this.element.removeEventListener('click', this.focusTargetElement);
  }
  focusTargetElement(event) {
    // Only consider click on links, not on other parts of the skip links
    if (event.target.href) {
      const hash = new URL(event.target.href).hash;
      if (hash) {
        const target = document.getElementById(hash.substring(1));
        focus(target);
      }
    }
  }
}

function makeFocusable(element) {
  if (!element.matches(SELECTOR_FOCUSABLE)) {
    element.setAttribute('tabindex', '-1');
    element.addEventListener(
      'blur',
      () => {
        element.removeAttribute('tabindex');
      },
      { once: true }
    );
  }
}

function focus(element) {
  // Inspired by https://axesslab.com/skip-links/, esp.
  // https://github.com/selfthinker/dokuwiki_template_writr/blob/master/js/skip-link-focus-fix.js
  makeFocusable(element);
  element.focus();
}
