import { Controller } from 'stimulus';

export default class extends Controller {
  validateFile(event) {
    const acceptedTypes = [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    if (!acceptedTypes.includes(event.file.type)) {
      event.preventDefault();
      alert('Only support attachment of jpeg, png, jpg, pdf, doc, docx files!');
    }

    const maxFileSize = 3 * 1024 * 1024;
    // Limit attachment size to 3MB
    if (event.file.size > maxFileSize) {
      event.preventDefault();
      alert('Only support attachment files up to 3MB!');
    }
  }
}
