import { Controller } from 'stimulus';
import OverflowMenu from 'overflow-menu';
import { throttle, sum } from 'lodash';

const THROTTLE_INTERVAL = 300;
const SIDENAV_TOGGLE_EVENT = 'sidenavToggled';
const BREAKPOINT = 575;

/**
 * Use overflow-menu library to move menu items into
 * a dropdown as horizontal space decreases. This controller
 * should attach to the immediate parents of the menu items.
 *
 * @target dropdownContainer - Menu item that contains the dropdown
 * button and dropdown iteself.
 * @target dropdownList - The dropdown itself.
 * @value recalculateOnSidenavToggle (optional) - Setting to true will
 * refresh the menu when global sidenav is expanded/collapsed.
 * @value navItemSelector - The selector of each menu item to be moved.
 */

export default class extends Controller {
  static targets = ['listItem', 'dropdownContainer', 'dropdownList'];
  static values = {
    recalculateOnSidenavToggle: Boolean,
    navItemSelector: String,
  };

  connect() {
    // The <ul> has to be a block element, so manually calculate a
    // max-width so it doesn't get wider than its items
    this.setMenuMaxWidth();

    this.menu = new OverflowMenu.OverflowMenu({
      itemContainer: this.element,
      itemSelector: this.navItemSelectorValue,
      overflowItem: this.dropdownContainerTarget,
      overflowContainer: this.dropdownListTarget,
    });

    this.monitor = new OverflowMenu.ResizeMonitor();
    this.monitor.start();

    this.setupOverflowMenuEvents();

    // Calculations aren't part of the setup so run them now
    this.refreshMenuAndSizes();
  }

  disconnect() {
    window.removeEventListener(
      'resize',
      this.refreshMenuAndSizesThrottledEvent
    );

    if (this.recalculateOnSidenavToggleValue) {
      document.removeEventListener(
        SIDENAV_TOGGLE_EVENT,
        this.refreshMenuAndSizes
      );
    }
  }

  setupOverflowMenuEvents() {
    // Refresh menu on window resize
    this.refreshMenuAndSizesThrottledEvent = throttle(
      this.refreshMenuAndSizes,
      THROTTLE_INTERVAL
    );
    window.addEventListener('resize', this.refreshMenuAndSizesThrottledEvent);

    // Use the resizeMonitor to catch all other cases
    this.monitor.onRecalculateSizes(() => {
      this.refreshMenuAndSizes();
    });

    if (this.recalculateOnSidenavToggleValue) {
      document.addEventListener(SIDENAV_TOGGLE_EVENT, this.refreshMenuAndSizes);
    }
  }

  refreshMenuAndSizes = () => {
    this.menu.refreshSizes();
    this.menu.refreshMenu();
  };

  setMenuMaxWidth() {
    const listWidth = sum(this.listItemTargets.map((item) => item.offsetWidth));

    // Although we're calculating the width of listItems, the overflow-menu library
    // will move the last element to the dropdown unless there's a bit of extra room.
    // We're also reducing the font-size below the breakpoint, so we have to
    // add on a lot more room to account for this if the viewport is expanded
    const offset = window.innerWidth <= BREAKPOINT ? 90 : 20;

    this.element.style.maxWidth = `${(listWidth + offset) / 16}rem`;
  }
}
