import { Controller } from 'stimulus';

const TIMEOUT_SUCCESS_BUTTON_TEXT = 5000;
const CLASS_DISPLAY_NONE = 'd-none';
const SELECTOR_IGNORE_INPUT = 'data-reactive-form-actions-ignore';
const COCOON_EVENTS = ['cocoon:after-insert', 'cocoon:after-remove'];

/**
 * Make form actions buttons/links react to form changes.
 * Supports duplicate buttons.
 *
 * Default: Submit button is disabled, showOnFormChange targets are hidden
 * On form change: Submit button is enabled & tooltip removed, showOnFormChange targets are shown
 * On successful ajax response (manually add the handleFormResponse action): Reset to default state
 */

export default class extends Controller {
  static targets = [
    'submitButton',
    'showOnFormChange',
    'formChangedHiddenField',
  ];
  static values = {
    successButtonText: String,
    reactOnLoad: Boolean,
  };

  connect() {
    this.formChanged = this.reactOnLoadValue;
    this.storeSubmitBtnText();
    this.element.addEventListener('input', this.activateButtons);

    if (this.formChanged) {
      this.disableAllTooltips();
    } else {
      this.toggleButtonStates();
    }

    COCOON_EVENTS.forEach(
      (method) => {
        document.addEventListener(method, this.activateButtons);
      },
      { once: true }
    );
  }

  disconnect() {
    this.element.removeEventListener('input', this.activateButtons);
  }

  preventSubmissionCheck() {
    // The button doesn't use the disabled attribute, so block
    // the submission if nothing's changed.
    if (!this.formChanged) {
      event.preventDefault();
      return;
    }
  }

  activateButtons = (event) => {
    if (
      !event.target.hasAttribute(SELECTOR_IGNORE_INPUT) &&
      !this.formChanged
    ) {
      this.formChanged = true;

      if (this.hasFormChangedHiddenFieldTarget) {
        this.formChangedHiddenFieldTarget.value = true;
      }

      if (this.buttonResponseTimeout) {
        clearTimeout(this.buttonResponseTimeout);
        this.resetButtonTextAndTooltip();
      }

      this.submitButtonTargets.forEach((btn) => {
        this.disableTooltipFor(btn);
      });

      this.toggleButtonStates();
    }
  };

  handleFormResponse({ detail }) {
    if (detail.success) {
      this.formChanged = false;

      if (this.hasFormChangedHiddenFieldTarget) {
        this.formChangedHiddenFieldTarget.value = false;
      }

      this.toggleButtonStates();
      this.submitButtonTarget.innerHTML = this.successButtonTextValue;

      // After the success message has been shown for x seconds inside
      // the button, revert it back to its original state to show the
      // behaviour can be repeated.
      this.buttonResponseTimeout = setTimeout(() => {
        this.resetButtonTextAndTooltip();
      }, TIMEOUT_SUCCESS_BUTTON_TEXT);
    }
  }

  toggleButtonStates() {
    this.submitButtonTargets.forEach((btn) => {
      btn.setAttribute('aria-disabled', !this.formChanged);
    });

    this.showOnFormChangeTargets.forEach((el) => {
      el.classList.toggle(CLASS_DISPLAY_NONE);
    });
  }

  resetButtonTextAndTooltip() {
    this.submitButtonTargets.forEach((btn) => {
      btn.textContent = btn.dataset.originalTextContent;
      this.enableTooltipFor(btn);
    });
  }

  enableTooltipFor(btn) {
    btn.dataset.tooltipDisabledValue = false;
  }

  disableTooltipFor(btn) {
    btn.dataset.tooltipDisabledValue = true;
  }

  disableAllTooltips() {
    this.submitButtonTargets.forEach((btn) => this.disableTooltipFor(btn));
  }

  storeSubmitBtnText() {
    this.submitButtonTargets.forEach((btn) => {
      btn.dataset.originalTextContent = btn.textContent;
    });
  }
}
