import { Controller } from 'stimulus';

const CLASS_STICKY = 'amba-sticky-element--stuck';

/**
 * Attach to a position: sticky; element and apply
 * a class when it's "stuck"
 *
 * This only works for elements that are sticky at the top
 * of the page. The rootMargin value means that the element
 * will intersect with the top of the page.
 * This'll need refactoring if it's to be used with other
 * positions
 */
export default class extends Controller {
  connect() {
    this.observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle(CLASS_STICKY, e.intersectionRatio < 1),
      {
        rootMargin: '-1px 0px 0px 0px',
        threshold: [1],
      }
    );

    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.unobserve(this.element);
  }
}
