import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'input'];

  toggle(event) {
    let targets = this.inputTargets.filter(
      (input) => input.dataset.targetIndex == event.target.dataset.targetIndex
    );

    targets.forEach((input) => (input.disabled = event.target.checked));
  }
}
