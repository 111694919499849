import { Controller } from 'stimulus';

/**
 * An action that will find an input whose value matches
 * the `event.detail.id`.
 *
 * It will also scroll the parent <fieldset> so the input is in view.
 */
export default class extends Controller {
  checkAndScrollToInput({ detail }) {
    const input = this.element.querySelector(`input[value="${detail.id}"]`);

    if (input) {
      input.checked = true;

      // Scroll the fieldset to the input label
      const fieldset = input.closest('fieldset');
      const label = input.labels[0];

      if (fieldset && label) {
        label.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
