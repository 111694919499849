import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['device', 'serial'];
  static values = {
    path: String,
  };

  clicked() {
    const serial_number = this.serialTarget.value;

    if (serial_number != '') {
      this.fetchDeviceId(serial_number);
    }
  }

  setDeviceId(id) {
    this.deviceTarget.value = id;
  }

  async fetchDeviceId(serial_number) {
    var url = new URL(this.pathValue.toString(), window.location.href);

    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({ serial_number: serial_number }),
    });

    if (response.ok) {
      const json = await response.json();

      this.setDeviceId(json['device_id']);
    } else {
      alert('Serial number not found');
    }
  }

  get headers() {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
        ?.content,
      'Content-type': 'application/json; charset=UTF-8',
    };
  }
}
