import { Controller } from 'stimulus';
import Modal from 'bootstrap/js/src/modal';

export default class extends Controller {
  static targets = [`remoteModal`, `frame`, 'dialog'];

  connect() {
    if (this.hasRemoteModalTarget) {
      this.modal = new Modal(this.remoteModalTarget);

      this.remoteModalTarget.addEventListener('hidden.bs.modal', () => {
        this.frameTarget.innerHTML = '';
        this.removeAdditionalDialogClass();
      });
    }
  }

  disconnect() {
    if (this.modal) {
      this.modal.hide();
    }
  }

  showModal(event) {
    this.opener = event.target;

    this.addAdditionalDialogClass();

    this.modal.show(event.target);
    this.frameTarget.innerHTML = '';
  }

  closeModal(event) {
    this.modal.hide();
    // Forward the submit-success to the opener so other actions can be triggered
    if (event.type == 'turbo:submit-success') {
      const event = new CustomEvent('amba:remote-modal.success', {
        bubbles: true,
        detail: { event },
      });
      this.opener.dispatchEvent(event);
    }
  }

  // There's only one remote modal on the page, so allow the triggering link
  // to pass a class to the `.modal-dialog` to adjust its appearance...
  addAdditionalDialogClass() {
    if (event.currentTarget.hasAttribute('data-remote-modal-dialog-class')) {
      this.additionalDialogClass =
        event.currentTarget.dataset.remoteModalDialogClass;
      this.dialogTarget.classList.add(this.additionalDialogClass);
    }
  }

  // ...and clean it up when hidden
  removeAdditionalDialogClass() {
    if (this.additionalDialogClass) {
      this.dialogTarget.classList.remove(this.additionalDialogClass);
      this.additionalDialogClass = null;
    }
  }
}
