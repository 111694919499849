import { Controller } from 'stimulus';
import { INSTANCES } from './toast_controller';

/**
 * Submit a form on an action, handle the disabling & enabling
 * of inputs and optionally render a toast message with response
 * details.
 *
 * @target input - input elements to be disabled/enabled.
 * @target toast (optional) - A toast element which has a
 * reference to a toast controller.
 */
export default class extends Controller {
  static targets = ['input', 'form', 'toast'];

  connect() {
    document.addEventListener(
      'turbo:before-cache',
      () => {
        this.toggleInputsDisabledState(false);
      },
      { once: true }
    );
  }

  submit = () => {
    this.formTarget.requestSubmit();
    this.toggleInputsDisabledState(true);
  };

  complete(event) {
    this.toggleInputsDisabledState(false);

    if (this.hasToastTarget) {
      const { message, status } = event.detail[0];
      INSTANCES.get(this.toastTarget).toast.renderToastWith(message, status);
    }
  }

  toggleInputsDisabledState(state) {
    this.inputTargets.forEach((target) => (target.disabled = state));
  }
}
