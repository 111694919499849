import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';
import { toggleShapesAndDashes } from './chart_controller/shapes_and_dashes';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(zoomPlugin);

const ZOOM_FACTOR = 0.2;
const PAN_OFFSET = 100;

/**
 * Handle chart panning & zooming
 **/
export default class extends Controller {
  static targets = ['canvas'];

  connect() {
    // Get the chart object one frame after the templates are rendered
    requestAnimationFrame(() => {
      this.chart = Chart.getChart(this.canvasTarget);
    });
  }

  toggleShapesAndDashes(event) {
    toggleShapesAndDashes(this.chart, event.target.id === 'shapes-dashes');
    this.chart.update();
  }

  zoomIn() {
    this.chart.zoom({ y: 1 + ZOOM_FACTOR });
  }

  zoomReset() {
    this.chart.resetZoom();
  }

  zoomOut() {
    this.chart.zoom({ y: 1 - ZOOM_FACTOR });
  }

  panUp() {
    this.chart.pan({ y: PAN_OFFSET }, undefined, 'default');
  }

  panDown() {
    this.chart.pan({ y: -PAN_OFFSET }, undefined, 'default');
  }
}
