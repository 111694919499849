import { Controller } from 'stimulus';

const CLASS_HIDDEN = 'd-none';

/**
 * The night summaries `index` page renders a list of lazy-loaded turbo
 * frames that load from `show`.
 *
 * If a VIP has no summary, the `show` will return a hidden element with data-attributes,
 * which this controller uses to populate a list of VIP names
 */
export default class extends Controller {
  static targets = ['vipWithoutSummary', 'list', 'listContainer'];

  connect() {
    this.listContainerTarget.setAttribute('aria-busy', 'true');

    this.element.addEventListener(
      'amba:turbo_frame_list_loaded',
      this.cleanUpListAfterLoading
    );
  }

  vipWithoutSummaryTargetConnected(item) {
    // Create and insert an new li element with the VIP name
    const li = document.createElement('li');
    li.textContent = item.dataset.vipName;
    this.listTarget.prepend(li);

    // Delete the frames parent li so we don't have empty
    // li's on the page
    const originalListItem = item.closest('li');
    originalListItem.remove();

    // Show the new list
    this.listContainerTarget.classList.remove(CLASS_HIDDEN);
  }

  cleanUpListAfterLoading = () => {
    this.listContainerTarget.setAttribute('aria-busy', 'false');
  };
}
