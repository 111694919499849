import Modal from 'bootstrap/js/src/modal';
import { Controller } from 'stimulus';

const DEFAULT_SELECTOR = 'a, button';

/**
 * Allows all interactive elements within the controller's element
 * to dismiss the modal they're in on click. Saves having to register
 * a `data-bs-dismiss` or `data-action="click->modal#close" on each of them.
 *
 * This is different from registering a "click->modal#close" on the element,
 * as this controller will ensure that only clicks on interactive elements (links & buttons),
 * trigger the hiding of the modal, not clicks on whitespace, plain text or other kinds of elements.
 *
 * If only specific elements need to trigger the hiding of the modal,
 * you can pass a custom `selector` value to pick only some of them,
 * for example using the `:not()` pseudo selector.
 * It may be more leggible to introduce an `ignoreSelector` value,
 * and update the check in `maybeDismiss` to check that the event's target:
 * - is within an element with the relevant `selector`
 * - but no with the `ignoreSelector`
 */

export default class extends Controller {
  static values = {
    selector: {
      type: String,
      default: DEFAULT_SELECTOR,
    },
  };

  connect() {
    this.maybeDismiss = this.maybeDismiss.bind(this);
    this.element.addEventListener('click', this.maybeDismiss);
  }

  disconnect() {
    this.element.removeEventListener('click', this.maybeDismiss);
  }

  maybeDismiss(event) {
    if (event.target.closest(this.selectorValue)) {
      this.dismiss(event);
    }
  }

  dismiss(event) {
    const modalElement = event.target.closest('.modal');
    const modal = Modal.getInstance(modalElement);
    modal.hide();
  }
}
