import { Controller } from 'stimulus';

const EVENT_AMBA_TURBO_FRAME_LIST_LOADED = 'amba:turbo_frame_list_loaded';

/**
 * Dispatches an event when all turbo frames have loaded.
 * Attach this to a container and make each relevant frame a target.
 *
 * Note: This currently only works for frames that 'load' successfully,
 *       not frames that are 'missing' or encounter server errors. Our Turbo upgrade
 *       will give us events to cater for all cases much easier than now.
 */
export default class extends Controller {
  static targets = ['frame'];

  connect() {
    this.frameTargets.forEach((frame) =>
      frame.addEventListener('turbo:frame-load', this.checkForAllFramesLoaded)
    );
  }

  disconnect() {
    this.frameTargets.forEach((frame) =>
      frame.removeEventListener(
        'turbo:frame-load',
        this.checkForAllFramesLoaded
      )
    );
  }

  checkForAllFramesLoaded = () => {
    const framesStillLoading = this.frameTargets.some((frame) =>
      frame.hasAttribute('busy')
    );

    if (!framesStillLoading) {
      this.dispatchLoadEvent();

      // Disconnect this behaviour here to prevent unwanted events being
      // dispatched (e.g a frame being reloaded or navigated)
      this.disconnect();
    }
  };

  dispatchLoadEvent() {
    const event = new CustomEvent(EVENT_AMBA_TURBO_FRAME_LIST_LOADED, {
      bubbles: true,
    });

    this.element.dispatchEvent(event);
  }
}
