import { Controller } from 'stimulus';

// Redirecting within a turbo visit causes the anchor string to be stripped
// (see - https://github.com/hotwired/turbo/issues/211)

// Instead we can use a `turbo_anchor` param in the same way and this
// controller will handle scrolling to the element
export default class extends Controller {
  connect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const turboAnchor = urlParams.get('turbo_anchor');

    if (turboAnchor?.length) {
      const targetEl = document.getElementById(turboAnchor);

      if (targetEl) {
        // Skip a frame to allow the template to be rendered
        requestAnimationFrame(() => {
          targetEl.scrollIntoView();
        });
      }
    }
  }
}
