import { render } from 'lit';
import { getActiveDatasetIndex } from '../chart_js';
import legendList from './legend/legend_list';

/**
 * Plugin for rendering a legend inside the `legendTarget`
 * of a Stimulus controller
 * @param {HTMLUListElement|HTMLOListElement} listElement
 * @returns
 */
// https://www.chartjs.org/docs/latest/samples/legend/html.html
export default function legendPlugin(listElement) {
  return {
    afterUpdate: (chart) => {
      const items = chart.options.plugins.legend.labels
        .generateLabels(chart)
        .filter((item) => !item.hidden);
      const activeDatasetIndex = getActiveDatasetIndex(chart);

      // If any line has dashes, separate the render of the point and line
      // to avoid hiding the dash pattern
      const separateLineAndPoint = chart.data.datasets.some(
        (dataset) => dataset.borderDash && dataset.borderDash.length
      );

      const list = legendList(items, {
        chart,
        activeDatasetIndex,
        separateLineAndPoint,
      });

      listElement.classList.toggle(
        'amba-chart-legend--has-active',
        typeof activeDatasetIndex !== 'undefined'
      );

      render(list, listElement);
    },
  };
}
