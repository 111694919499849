import { Controller } from 'stimulus';

/**
 * Complements the `bulk-actions` controller to ensure
 * that only one table displays its bulk actions when multiple actions
 * are on the same page.
 *
 * Usage: add this controller next to the `bulk-actions` controller
 * on the tables you want to be affected.
 */
export default class extends Controller {
  get bulkActionsController() {
    // https://stimulus.hotwired.dev/reference/controllers#directly-invoking-other-controllers
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'bulk-actions'
    );
  }

  connect() {
    this.hideIfOtherShowsActions = this.hideIfOtherShowsActions.bind(this);

    document.addEventListener(
      'amba:bulk_actions.will.showActions',
      this.hideIfOtherShowsActions
    );
  }

  disconnect() {
    document.removeEventListener(
      'amba:bulk_actions.will.showActions',
      this.hideIfOtherShowsActions
    );
  }

  hideIfOtherShowsActions(event) {
    if (event.target !== this.element) {
      this.bulkActionsController.hideActions();
    }
  }
}
