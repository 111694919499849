import { render } from 'lit';
import filtersCategory from './filters/filters_category';

/**
 * Plugin for rendering filters inside the `filtersTarget`
 * of a Stimulus controller
 * @param {HTMLUListElement|HTMLOListElement} listElement
 * @returns
 */
// Using the same concept as a custom legend - https://www.chartjs.org/docs/latest/samples/legend/html.html
export default function filtersPlugin(listElement, propertiesByCategory) {
  return {
    afterUpdate: (chart) => {
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      const listItems = propertiesByCategory
        .map((category) => associateItems(category, { items }))
        // associateItems() may create categories with no properties,
        // so filter empty categories out
        .filter((category) => category.items.length)
        .map((category) => filtersCategory(category, { chart }));

      render(listItems, listElement);
    },
  };
}

function associateItems(category, { items }) {
  return {
    ...category,
    items: category.properties
      .map((property) => itemFor(property, { items }))
      .filter(Boolean), // In case some properties don't have a corresponding chart series
  };
}

function itemFor(property, { items }) {
  return items.find((item) => item.text == property.name);
}
