import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['label'];
  static classes = ['selected', 'unselected'];

  changeClass(event) {
    this.labelTargets.forEach((el) => {
      el.classList.remove(this.selectedClass);
      el.classList.add(this.unselectedClass);
    });

    const radioLabel = this.getSelectLabelFromTarget(event.target);
    if (radioLabel) {
      radioLabel.classList.remove(this.unselectedClass);
      radioLabel.classList.add(this.selectedClass);
    }
  }

  getSelectLabelFromTarget(target) {
    return this.labelTargets.find(
      (element) => element.getAttribute('for') === target.id
    );
  }
}
