import { Controller } from 'stimulus';
import Popover from 'bootstrap/js/src/popover';

const DEFAULT_FALLBACK_PLACEMENTS = ['top', 'right', 'bottom', 'left'];

export default class extends Controller {
  static values = {
    fallbackPlacement: Array,
    showOnConnect: Boolean,
    initialDelayMs: Number,
    removeTimerMs: Number,
  };

  connect() {
    const options = {
      fallbackPlacements: this.fallbackPlacements,
    };

    this.popover = new Popover(this.element, options);

    this.setupTimers();

    // Popovers will overlap modals if they're open at the same time
    document.addEventListener('show.bs.modal', this.hidePopover);

    document.addEventListener('click', this.handlePopoverClose);

    // Hide the popover when entering fullscreen
    document.addEventListener(
      'fullscreenchange',
      () => {
        this.disconnect();
      },
      { once: true }
    );
  }

  setupTimers() {
    // Add an optional slight delay to avoid rendering issues when inside a modal/offcanvas
    if (this.showOnConnectValue) {
      this.initialDelayTimer = setTimeout(() => {
        this.popover.show();
      }, this.initialDelayMsValue);
    }

    // Add an optional timer to remove the popover
    if (this.hasRemoveTimerMsValue) {
      this.removeTimer = setTimeout(() => {
        this.disconnect();
      }, this.removeTimerMsValue);
    }
  }

  disconnect() {
    this.popover.hide();
    document.removeEventListener('show.bs.modal', this.hidePopover);

    clearTimeout(this.removeTimer);
    clearTimeout(this.initialDelayTimer);
  }

  hidePopover = () => {
    this.popover.hide();
  };

  handlePopoverClose = ({ target }) => {
    if (target.classList.contains('js-popover-close')) {
      this.popover.hide();
      document.removeEventListener('click', this.handlePopoverClose);
    }
  };

  get fallbackPlacements() {
    if (this.hasFallbackPlacementValue) {
      return this.fallbackPlacementValue;
    }

    return DEFAULT_FALLBACK_PLACEMENTS;
  }
}
