/**
 * Renders an alerted icon for the tick if the corresponding
 * alertedIndex position is true
 * @param {Array} alertedIndexes - An array of booleans, each one corresponding to
 * each tick in order. Specifies whether each tick should have an icon
 * @param {HTMLElement} alertedIcon - Image element of the icon to be rendered
 * @returns {Object}
 */

const PADDING_TOP = 5;

export function alertedTickX(alertedIndexes, alertedIcon, alertIconSize) {
  return {
    // Using `afterDatasetsDraw` instead of `afterDraw`, as
    // the latter fires after the tooltips are rendered, resulting
    // in the alerted icon appear on top of the tooltip
    afterDatasetsDraw: (chart) => {
      var ctx = chart.ctx;
      var xAxis = chart.scales.x;
      var yAxis = chart.scales.y;

      xAxis.ticks.forEach((value, index) => {
        if (alertedIndexes[index]) {
          var x = xAxis.getPixelForTick(index);

          ctx.drawImage(
            alertedIcon,
            x - alertIconSize / 2,
            yAxis.bottom + PADDING_TOP,
            alertIconSize,
            alertIconSize
          );
        }
      });
    },
  };
}
