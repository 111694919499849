import { Controller } from 'stimulus';
import debounce from 'lodash/debounce';
import scrollIntoView from 'scroll-into-view-if-needed';

export default class extends Controller {
  static values = {
    selector: String,
  };

  get scrollOptions() {
    return {
      block: 'center',
      inline: 'center',
      boundary: this.element,
    };
  }

  scrollDescendantIntoView() {
    const elementToScroll = this.element.querySelector(this.selectorValue);
    if (elementToScroll) {
      scrollIntoView(elementToScroll, this.scrollOptions);
    }
  }

  connect() {
    this.scrollDescendantIntoView();

    this.handleResize = debounce(this.scrollDescendantIntoView.bind(this), 100);
    window.addEventListener('resize', this.handleResize);
  }

  disconnect() {
    window.removeEventListener('resize', this.handleResize);
  }
}
