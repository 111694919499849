import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'eventFilter',
    'alertFilter',
    'eventReplacementText',
    'checkbox',
    'alertParams',
  ];

  toggleFilters(event) {
    if (['events', 'alerts'].includes(event.target.value)) {
      this.eventFilterTarget.classList.remove('d-none');
      this.eventReplacementTextTarget.classList.add('d-none');
      this.checkboxTargets.forEach((el) => (el.disabled = false));
    } else {
      this.eventFilterTarget.classList.add('d-none');
      this.eventReplacementTextTarget.classList.remove('d-none');
      this.checkboxTargets.forEach((el) => (el.disabled = true));
    }

    if (event.target.value == 'alerts') {
      this.alertFilterTarget.classList.remove('d-none');
      this.alertParamsTargets.forEach((el) => (el.disabled = false));
    } else {
      this.alertFilterTarget.classList.add('d-none');
      this.alertParamsTargets.forEach((el) => (el.disabled = true));
    }
  }
}
