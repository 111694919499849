/**
 * A copy of appsignal's plugin-window-events:
 * https://docs.appsignal.com/front-end/plugins/plugin-window-events.html
 *
 * We've extended this to filter out errors with no error message or stacktrace
 */

Object.defineProperty(exports, '__esModule', { value: true });
exports.plugin = void 0;

function windowEventsPlugin() {
  var ctx = window;

  return function () {
    var self = this;
    var prev = {
      onError: ctx.onerror,
      unhandledRejection: ctx.onunhandledrejection,
    };

    function _onErrorHandler(event, source, lineno, colno, error) {
      var span = self.createSpan();

      if (
        typeof event === 'string' &&
        lineno === 0 &&
        /Script error\.?/.test(event)
      ) {
        console.warn(
          '[APPSIGNAL]: Cross-domain or eval script error detected, error ignored'
        );
      } else {
        if (error) {
          span.setError(error);
        } else {
          span.setError({
            name: 'Error',
            message:
              typeof event === 'string'
                ? event
                : 'An HTML onerror="" handler failed to execute',
            stack: 'at ' + source + ':' + lineno + (colno ? ':' + colno : ''),
          });
        }

        self.send(span);
      }

      if (typeof prev.onError === 'function') {
        prev.onError.apply(this, arguments);
      }
    }

    function _onUnhandledRejectionHandler(error) {
      var _a;
      var span = self.createSpan();
      const message = reasonFromError(error);
      const stack =
        ((_a = error === null || error === void 0 ? void 0 : error.reason) ===
          null || _a === void 0
          ? void 0
          : _a.stack) || 'No stacktrace available';

      if (hasErrorInfo(message, stack)) {
        span.setError({
          name: 'UnhandledPromiseRejectionError',
          message: message,
          stack: stack,
        });

        self.send(span);
        if (typeof prev.unhandledRejection === 'function') {
          prev.unhandledRejection.apply(this, arguments);
        }
      }
    }

    ctx.onerror = _onErrorHandler;
    ctx.onunhandledrejection = _onUnhandledRejectionHandler;
  };

  function reasonFromError(error) {
    if (!error) {
      return undefined;
    }
    if (typeof error.reason === 'string') {
      return error.reason;
    }
    return JSON.stringify(error.reason, circularReplacer());
  }

  function circularReplacer() {
    var seenValue = [];
    var seenKey = [];
    return function (key, value) {
      if (typeof value === 'object' && value !== null) {
        var i = seenValue.indexOf(value);
        if (i !== -1) {
          return '[cyclic value: ' + (seenKey[i] || 'root object') + ']';
        } else {
          seenValue.push(value);
          seenKey.push(key);
        }
      }
      return value;
    };
  }

  // We get unhandled promise rejection errors that don't contain any message or stacktrace.
  // As they are impossible to locate with no info, filter out that noise here
  function hasErrorInfo(message, stack) {
    return !(message === '{}' && stack === 'No stacktrace available');
  }
}
exports.plugin = windowEventsPlugin;
