import { Controller } from 'stimulus';

const CLASS_HIDDEN = 'd-none';

/**
 * Handle address & radius input values, converting
 * radius units and updating the value of the map
 * element so it's re-rendered.
 *
 * @value mapData - Use following structure:
 *   {
 *     0: {
 *      name:"Home",
 *        center: {
 *          lat: 51.4357796,
 *          lng: -2.6069599
 *        },
 *        :radius=>1000.0
 *      }
 *    }
 */
export default class extends Controller {
  static targets = [
    'addressInput',
    'radiusDistanceInput',
    'radiusUnitInput',
    'mapContainer',
    'map',
    'feedback',
    'notEnoughDataMessage',
    'invalidAddressMessage',
    'loadingMessage',
    'mapLoadingError',
  ];

  static values = {
    mapData: Object,
  };

  connect() {
    this.mapRendered;
  }

  geocodeCheck() {
    if (this.addressPresent) {
      this.geocode();
    } else {
      this.showNotEnoughDataMessage();
    }
  }

  geocode() {
    window.loader
      .load()
      .then((google) => {
        this.showLoadingMessage();

        const geocoder = new google.maps.Geocoder();
        const address = this.addressInputTargets
          .map((input) => input.value)
          .join(', ');

        geocoder.geocode({ address: address }, (results, status) => {
          if (status === 'OK') {
            // If results returned, format the data and update
            // the map element `mapData` value
            this.mapData = this.formatDataFor(results[0].geometry.location);
            this.mapContainerTarget.dataset.mapWithRadiiMapDataValue =
              JSON.stringify(this.mapData);
            this.mapRendered = true;
          } else {
            this.showInvalidAddressMessage();
            this.mapRendered = false;
          }
        });
        return;
      })
      .catch(() => {
        this.showMapLoadingErrorMessage();
        this.mapRendered = false;
      });
  }

  // Update only the radius
  updateRadius() {
    if (this.addressPresent && this.mapRendered) {
      const radius = this.radiusInMetres;

      if (radius) {
        // Get the map data from either the data the geocoder has returned,
        // or the value passed in
        const mapData = this.mapData || this.mapDataValue;

        mapData[0].radius = radius;
        this.mapContainerTarget.dataset.mapWithRadiiMapDataValue =
          JSON.stringify(mapData);
      }
    }
  }

  formatDataFor(location) {
    return {
      0: {
        name: '',
        center: {
          lat: location.lat(),
          lng: location.lng(),
        },
        radius: this.radiusInMetres,
      },
    };
  }

  get radiusInMetres() {
    const radiusArr = [
      this.radiusDistanceInputTarget.value,
      this.radiusUnitInputTarget.value,
    ];

    if (radiusArr.some((value) => value.length === 0)) return false;

    switch (radiusArr[1]) {
      case 'km':
        return radiusArr[0] * 1000;
      case 'mi':
        return radiusArr[0] * 1609.344;
      default:
        return parseInt(radiusArr[0]);
    }
  }

  get addressPresent() {
    return this.addressInputTargets.every((input) => input.value.length);
  }

  // Handle feedback messages

  showMapLoadingErrorMessage() {
    this.hideFeedbackFields();
    this.mapTarget.classList.add(CLASS_HIDDEN);
    this.mapLoadingErrorTarget.classList.remove(CLASS_HIDDEN);
  }

  showNotEnoughDataMessage() {
    this.hideFeedbackFields();
    this.mapTarget.classList.add(CLASS_HIDDEN);
    this.notEnoughDataMessageTarget.classList.remove(CLASS_HIDDEN);
  }

  showLoadingMessage() {
    this.hideFeedbackFields();
    this.mapTarget.classList.add(CLASS_HIDDEN);
    this.loadingMessageTarget.classList.remove(CLASS_HIDDEN);
  }

  showInvalidAddressMessage() {
    this.hideFeedbackFields();
    this.mapTarget.classList.add(CLASS_HIDDEN);
    this.invalidAddressMessageTarget.classList.remove(CLASS_HIDDEN);
  }

  hideFeedbackFields() {
    this.feedbackTargets.forEach((el) => el.classList.add(CLASS_HIDDEN));
  }
}
