import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';

// Only import what we need from bootstrap
import 'bootstrap/js/src/alert';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/tab';

import '@oddcamp/cocoon-vanilla-js';
import 'focus-visible';

import 'chartkick/chart.js';

Rails.start();

import '../javascript/lib/bridge';

import application from '../controllers';
import { setupAppSignal } from '../javascript/lib/appsignal';
import { metaContent } from '../javascript/lib/dom/meta';

setupAppSignal({
  key: metaContent('appsignal:key'),
  revision: metaContent('appsignal:revision'),
  application,
});

// Needs to be in `vendor` so there's only one import of Bootstrap's `Modal`
import '../javascript/lib/bootstrap_turbo';
