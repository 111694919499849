import { makeTransparent, opacify } from './hex_colors';

/**
 * Make dataset containing the given `activeItem` stand out on the `chart`.
 * Restores all datasets to their base style if no `activeItem` is given
 * @param {Chart} chart
 * @param {Object} activeItem
 */
export function emphasizeActiveSeries(chart, activeItem) {
  if (activeItem) {
    chart.data.datasets.forEach((dataset, index) => {
      const datasetColor =
        index === activeItem.datasetIndex
          ? opacify(dataset.borderColor)
          : makeTransparent(dataset.borderColor);

      dataset.borderColor = datasetColor;
      dataset.pointBackgroundColor = datasetColor;

      if (index !== activeItem.datasetIndex) {
        dataset.borderWidth = 1;
      }
    });
  } else {
    chart.data.datasets.forEach((dataset) => {
      const opaqueBorderColor = opacify(dataset.borderColor);
      dataset.borderColor = opaqueBorderColor;
      dataset.pointBackgroundColor = opaqueBorderColor;

      dataset.borderWidth = 2;
    });
  }
}
