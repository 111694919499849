import morphdom from 'morphdom';
import { preserve as preserveAttributes } from '../../../dom/attributes';

const MORPHDOM_OPTIONS = {
  onBeforeElUpdated(original, replacement) {
    preserveAttributes(original, replacement);
  },
};

/**
 * Alertnative to the `replace` action that uses `morphdom`
 * to update the DOM, allowing to keep elements that didn't
 * change in the DOM, preserving the focus or any entry.
 *
 * To preserve some of the attributes of the element being replaced,
 * the replacement can list, space separated, the names of these
 * attributes in its `data-morphdom-preserve-attributes`
 * @param {Element} target
 * @param {TemplateElement} template
 */
export default function (target, template) {
  const content = template.content.cloneNode(true);
  if (content.children.length == 1) {
    morph(target, content.firstElementChild);
  } else {
    morph(target, content);
  }
}

export function morph(element, into) {
  const result = morphdom(element, into, MORPHDOM_OPTIONS);
  result.dispatchEvent(new CustomEvent('morphdom:morphed', { bubbles: true }));
}
