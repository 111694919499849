import { Controller } from 'stimulus';

export default class extends Controller {
  // This controller can be used to control a checkbox elements based on the
  // value of a radio button. The radio button must call the toggle method
  // when a change is detected. The checkbox must have a data attribute
  // called data-radio-toggled-checkbox-condition with a value of a Ruby hash
  // that contains the value of the radio button that will enable the checkbox.
  // Example: app/views/organisations/admin/organisation_users/shared/_feature_attributes.html.haml
  // paired with app/views/organisations/admin/organisation_users/shared/_role_radio_buttons.html.haml
  // Can be used with the keepCheckedValue attribute to keep some checkboxes checked.
  static targets = ['checkbox'];
  static values = {
    keepChecked: Array,
  };

  toggle({ target: { value: selectedValue } }) {
    this.checkboxTargets.forEach((checkbox) => {
      const condition = JSON.parse(
        checkbox.dataset.radioToggledCheckboxCondition
      );
      const isEnabled = condition.value.includes(selectedValue);

      // Disable checkbox if it's not enabled
      checkbox.disabled = !isEnabled;

      // If the checkbox is not enabled and it's not in the keepCheckedValue array, uncheck it
      if (!isEnabled && !this.keepCheckedValue.includes(checkbox.value)) {
        checkbox.checked = false;
      }
    });
  }
}
