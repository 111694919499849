import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.originalValue = this.inputTarget.value;

    document.addEventListener(
      'turbo:before-cache',
      () => {
        this.inputTarget.value = this.originalValue;
      },
      { once: true }
    );
  }
}
