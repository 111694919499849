/**
 * Turbo may dispatch fetch related events from various places:
 * - the frame itself
 * - the form that got submitted with the aim to update a frame
 *
 * This means that to get all fetch events related to a specific frame
 * we need to listen on the `document` (or at list an element wrapping
 * both the frame and whichever form would submit to update it).
 *
 * This also means we need to check whether the fetch events are actually
 * relevant to the frame we're concerned about, which is the reason to be
 * of the helpers in that module.
 */

/**
 * Wraps the given event listener into one that only runs if the event
 * is relevant to the given `<turbo-frame>` element.
 *
 * Any binding on the listener needs to happen **before** it is wrapped.
 *
 * @example
 * // Inside a Stimulus `connect` method, for ex.
 * document.addEventListener('turbo:before-fetch-request', ifRelevantTo(this.element, this.doSomething.bind(this)))
 *
 * @param {HTMLElement} turboFrameElement
 * @param {Function} listener
 * @returns {Function}
 */
export function ifRelevantTo(turboFrameElement, listener) {
  return function (event) {
    if (isRelevantTo(turboFrameElement, event)) {
      // TODO: Maybe use `listener.call(this, event)` to allow the wrapped function
      // to be bound, but document what'd happen if the listerner is already bound
      // (in which context will it run?) and what happens with arrow methods
      return listener(event);
    }
  };
}

/**
 * @param {HTMLElement} turboFrameElement
 * @param {Event} event
 * @returns {Boolean}
 */
export function isRelevantTo(turboFrameElement, event) {
  return (
    event.target == turboFrameElement || // Navigation
    turboFrameElement.contains(event.target) || // Form submission from within the frame
    event.target.closest(`[data-turbo-frame='${turboFrameElement.id}']`) // Form submission from outside the frame
  );
}
