import { Controller } from 'stimulus';
import Vimeo from '@vimeo/player';
import { throttle } from 'lodash';
import { updateProgressRequest } from '../../javascript/lib/academy/progress';

const THROTTLE_INTERVAL = 300;

export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    this.player = new Vimeo(this.element);

    this.handleTimeUpdateThrottledEvent = throttle(
      this.handleTimeUpdate,
      THROTTLE_INTERVAL
    );
    this.player.on('timeupdate', this.handleTimeUpdateThrottledEvent);
  }

  disconnect() {
    this.player.off('timeupdate', this.handleTimeUpdateThrottledEvent);
  }

  shouldUpdate(percentage) {
    return percentage % 10 === 0;
  }

  handleTimeUpdate = ({ percent }) => {
    let completedPercentage = Math.round(percent * 100);

    // If the progress is over 90%, round it up to 100%
    if (completedPercentage >= 90) {
      completedPercentage = 100;

      this.videoCompleted = true;
    }

    if (this.shouldUpdate(completedPercentage)) {
      updateProgressRequest(completedPercentage, this.urlValue);

      if (this.videoCompleted) this.disconnect();
    }
  };
}
