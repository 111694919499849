import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  checkAll() {
    this.checkboxTargets.forEach((el) => (el.checked = true));
  }

  clearAll() {
    this.checkboxTargets.forEach((el) => (el.checked = false));
  }
}
