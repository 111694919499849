export function getActiveDatasetIndex(chart) {
  const activeElements = chart.getActiveElements();
  if (activeElements.length) {
    return activeElements[0].datasetIndex;
  }
}

export function datasetForLegendItem(chart, item) {
  return chart.data.datasets[item.datasetIndex];
}

export function isSameItem(a, b) {
  return (
    (typeof a == 'undefined' && typeof b == 'undefined') ||
    (a?.datasetIndex == b?.datasetIndex && a?.index == b?.index)
  );
}
