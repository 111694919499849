const ATTR_PRESERVE = 'data-preserve-attributes';

/**
 * Preserve attributes listed, space separated, by `data-preserve-attributes`
 * on the `original` element
 * @param {Element} original
 * @param {Element} replacement
 */
export function preserve(original, replacement) {
  if (original.hasAttribute(ATTR_PRESERVE)) {
    const attributes = getArray(original, ATTR_PRESERVE);

    for (const attributeName of attributes) {
      copy(attributeName, { from: original, to: replacement });
    }

    copy(ATTR_PRESERVE, { from: original, to: replacement });
  }
}

function copy(attributeName, { from, to }) {
  to.setAttribute(attributeName, from.getAttribute(attributeName));
}

/**
 * Reads an array out of the given attribute, splitting
 * its value by the provided separator (default ' ')
 * @param {String} attributeName
 * @param {Element} element
 * @param {String} options.separator
 */
function getArray(element, attributeName, { separator = ' ' } = {}) {
  return element.getAttribute(attributeName)?.split(separator) || [];
}
