import { Controller } from 'stimulus';
import { Loader } from '@googlemaps/js-api-loader';

import EventIcon from '../images/icons/maps/circle.svg';
import AlertIcon from '../images/icons/maps/exclamation-triangle.svg';

const CLASS_HIDDEN = 'd-none';

const MAP_CONTROL_SIZE = 30;
const MAP_MAX_ZOOM = 16;

const MARKER_ZINDEX_DEFAULT = 1;
const MARKER_ZINDEX_ALERT = 2;
const MARKER_ZINDEX_ACTIVE = 3;

/**
 * Renders a map with markers (events or alerts).
 *
 * When clicked they show an infoWindow with more details and also dispatch
 * a "marker active" event.
 * Updating the `activeMarkerIdValue` will also show the infoWindow.
 */

export default class extends Controller {
  static targets = ['map', 'mapLoadingError', 'loadingMessage', 'feedback'];

  static values = {
    apiKey: String,
    markerData: Array,
    activeMarkerId: Number,
    addSingleMarkerData: Object,
  };

  initialize() {
    if (!window.loader) {
      window.loader = new Loader({
        apiKey: this.apiKeyValue,
        version: 'weekly',
        libraries: ['places'],
      });
    }

    this.allMarkerData = [];
  }

  addSingleMarkerDataValueChanged(value) {
    if (Object.entries(value).length) {
      this.renderMarker(value);

      this.zoomAndPanMap();
    }
  }

  connect() {
    window.loader
      .load()
      .then((google) => {
        // Reveal the map container
        this.mapTarget.classList.remove(CLASS_HIDDEN);

        // Init the map
        this.map = new google.maps.Map(this.mapTarget, {
          controlSize: MAP_CONTROL_SIZE,
          styles: this.mapStyles,
        });

        this.setMaxZoomLevel();

        this.bounds = new google.maps.LatLngBounds();
        this.infoWindow = new google.maps.InfoWindow();

        // Create an array to store all markers
        this.markers = [];

        this.renderMarkers();
        return;
      })
      .catch(() => {
        this.loadingMessageTarget.classList.add(CLASS_HIDDEN);
        this.mapTarget.classList.add(CLASS_HIDDEN);
        this.mapLoadingErrorTarget.classList.remove(CLASS_HIDDEN);
      });
  }

  renderMarkers() {
    this.markerDataValue.forEach((data) => {
      // Create the marker
      this.renderMarker(data);
    });

    this.zoomAndPanMap();
  }

  renderMarker(data) {
    const marker = new window.google.maps.Marker({
      icon: this.getMarkerIcon(data),
      zIndex: this.getMarkerZIndex(data),
      position: {
        lat: data.lat,
        lng: data.lng,
      },
      map: this.map,
      metadata: {
        id: data.id, // Store the id so we can find specific markers
      },
    });

    // On click show the infoWindow and dispatch an event
    marker.addListener('click', () => {
      this.showInfoWindowFor(data, marker);

      const event = new CustomEvent('amba:map_with_markers.marker_active', {
        bubbles: true,
        detail: {
          id: data.id,
        },
      });
      this.element.dispatchEvent(event);
    });

    // Open the infoWindow if this is the first data entry
    if (this.markerDataValue[0].id === data.id) {
      this.showInfoWindowFor(data, marker);
    }

    // Add marker coords to bounds and our array
    this.bounds.extend(marker.position);
    this.markers.push(marker);
    this.allMarkerData.push(data);
  }

  activeMarkerIdValueChanged(id) {
    // Show the infoWindow for the marker with this id and
    // check for markerData, as this is fired on restoration visits
    if (id && this.allMarkerData.length) {
      const markerData = this.allMarkerData.find((datum) => datum.id === id);
      const marker = this.markers.find((marker) => marker.metadata.id === id);

      this.showInfoWindowFor(markerData, marker);
    }
  }

  // Info Window

  showInfoWindowFor(markerData, marker) {
    this.infoWindow.setContent(this.getInfoWindowContent(markerData));
    this.infoWindow.open(this.map, marker);

    // Reset all marker z-indexes apart from the active one
    this.markers.forEach((marker) =>
      marker.setZIndex(this.getMarkerZIndex(markerData))
    );
    marker.setZIndex(MARKER_ZINDEX_ACTIVE);
  }

  getInfoWindowContent(data) {
    const svgId = data.is_alert ? 'exclamation_triangle' : 'circle';

    return `
      <div class="rythm-y-2">
        <p class="mb-1 d-flex align-items-center rythm-x-1">
          <svg width="12" height="12" aria-hidden="true">
            <use href="#svg_${svgId}"></use>
          </svg>
          <span>${data.device_name || ''}</span>
        </p>
        <time datetime="${data.occurred_at}" class="fw-bold">${
      data.occurred_at_label
    }</time>
      </div>
    `;
  }

  // Markers

  getMarkerIcon(data) {
    return {
      url: data.is_alert ? AlertIcon : EventIcon,
      scaledSize: new window.google.maps.Size(20, 20),
    };
  }

  getMarkerZIndex(data) {
    return data.is_alert ? MARKER_ZINDEX_ALERT : MARKER_ZINDEX_DEFAULT;
  }

  // Helpers

  zoomAndPanMap() {
    this.map.fitBounds(this.bounds);
    this.map.panToBounds(this.bounds);
  }

  // Map settings

  setMaxZoomLevel() {
    window.google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
      if (this.map.getZoom() > MAP_MAX_ZOOM) this.map.setZoom(MAP_MAX_ZOOM);
    });
  }

  get mapStyles() {
    // Remove landmarks so our markers are nice and clear
    return [
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ];
  }
}
