import { Controller } from 'stimulus';

const MEDIA_QUERY_BREAKPOINT = '(min-width: 768px)';

/**
 * Where a form spans multiple tabpanels, disable
 * tabpanels that are visually hidden.
 * Attach this to the form and ensure all tabpanel targets
 * are fieldsets so they can be disabled.
 *
 * As the tabs render as a list on smaller screens, check the viewport size
 * to ensure sections are always enabled if they're visible.
 */
export default class extends Controller {
  static targets = ['tabpanel'];

  connect() {
    this.mediaQueryList = window.matchMedia(MEDIA_QUERY_BREAKPOINT);

    if (this.isWindowPastBreakpoint) {
      this.disableInactiveTabpanels();
    }

    this.mediaQueryList.addListener(
      'change',
      this.toggleTabpanelAvailabilityCheck
    );
  }

  disconnect() {
    this.mediaQueryList.removeListener(
      'change',
      this.toggleTabpanelAvailabilityCheck
    );
  }

  toggleTabpanelAvailabilityCheck = () => {
    if (this.isWindowPastBreakpoint) {
      this.disableInactiveTabpanels();
    } else {
      this.enableAllTabpanels();
    }
  };

  switchTabpanels(event) {
    this.toggleTabpanelAvailabilityFor(this.getPanelFor(event.target));
    this.toggleTabpanelAvailabilityFor(this.getPanelFor(event.relatedTarget));
  }

  toggleTabpanelAvailabilityFor(panel) {
    panel.disabled = !panel.disabled;
    if (panel.disabled) {
      resetFieldset(panel);
    }
  }

  disableInactiveTabpanels() {
    this.tabpanelTargets.forEach((panel) => {
      if (!panel.classList.contains('active')) {
        panel.disabled = true;
        // No reset on resizing to avoid loss of data
        // from mishaps like accidentally tilting a device
        // as those do not trigger prompt.
      }
    });
  }

  enableAllTabpanels() {
    this.tabpanelTargets.forEach((panel) => (panel.disabled = false));
  }

  getPanelFor(tab) {
    return this.tabpanelTargets.find(
      (panel) => panel.id === tab.getAttribute('aria-controls')
    );
  }

  get isWindowPastBreakpoint() {
    return this.mediaQueryList.matches;
  }
}

function resetFieldset(fieldset) {
  for (const element of fieldset.elements) {
    reset(element);
  }
}

function reset(element) {
  if (element instanceof HTMLInputElement) {
    if (element.type == 'checkbox' || element.type == 'radio') {
      element.checked = element.getAttribute('checked');
    } else {
      element.value = element.getAttribute('value');
    }
  } else if (element instanceof HTMLSelectElement) {
    element.value = (
      element.querySelector('[selected]') || element.options[0]
    ).value;
  } else if (element instanceof HTMLTextAreaElement) {
    element.value = element.textContent;
  }
}
