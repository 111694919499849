import { Controller } from 'stimulus';

const CLASS_HIDDEN = 'd-none';

export default class extends Controller {
  static targets = ['escalationSection'];

  connect() {
    const highRadioButtons = document.querySelectorAll(
      'input[type="radio"][value="high"]'
    );
    let anyHighChecked = false;

    highRadioButtons.forEach((radioButton) => {
      if (radioButton.checked) {
        anyHighChecked = true;
      }
    });

    if (anyHighChecked) {
      this.showEscalationSection();
    } else {
      this.hideEscalationSection();
    }
  }

  showEscalationSection() {
    const escalationSection = this.escalationSectionTarget;
    escalationSection.classList.remove(CLASS_HIDDEN);
  }

  hideEscalationSection() {
    const escalationSection = this.escalationSectionTarget;
    escalationSection.classList.add(CLASS_HIDDEN);
  }

  escalationToggle() {
    const escalationSection = this.escalationSectionTarget;

    const highRadioButtons = document.querySelectorAll(
      'input[type="radio"][value="high"]:checked'
    );

    if (highRadioButtons.length > 0) {
      escalationSection.classList.remove(CLASS_HIDDEN);
    } else {
      escalationSection.classList.add(CLASS_HIDDEN);
    }
  }
}
