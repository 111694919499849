import { format } from 'date-fns';

/**
 * Additional axis options that require either operations to happen in a callback
 * or calculations on the JS side
 */

/**
 * From all values, calculate the lowest & highest, rounded
 * down & up to the nearest unit respectively
 */
export function roundToNearest(data, unit) {
  const values = Object.values(data).map((datum) => datum.value);

  return {
    min: Math.floor(Math.min(...values) / unit) * unit,
    max: Math.ceil(Math.max(...values) / unit) * unit,
  };
}

/**
 * Transforms labels from a 24hr number (0 - 24) to 12hr hour formats (0am - 11am, 12pm - 11pm)
 */
export function timeOfDay() {
  return {
    ticks: {
      callback: (value) => {
        let currentDateWithHoursSet = new Date().setHours(value);

        return format(currentDateWithHoursSet, 'haaa');
      },
    },
  };
}

/**
 * Set the axis labels to our apnea thresholds
 */
export function apneaIndex(data) {
  // We need to set a `max` value so the y-axis renders the "Severe" label
  // with some space above. However sometimes datapoints can be exceptionally
  // high (e.g 150+).
  // To cater for this, set `max: 45` when values are low, but remove it when there's
  // a value over 45 and let chart.js handle the axis limit.
  const values = Object.values(data).map((d) => d.value);
  const anyValueOver45 = values.some((i) => i > 45);

  return {
    display: true,
    title: {
      display: true,
      text: 'Severity',
    },
    min: 0,
    max: anyValueOver45 ? null : 45,
    ticks: {
      display: true,
      autoSkip: true,
      stepSize: 5,
      callback: (value) => {
        const label = {
          0: 'mild',
          30: 'severe',
        };

        if (Object.keys(label).includes(value.toString())) {
          return label[value];
        } else {
          return undefined;
        }
      },
    },
  };
}

/**
 * Set the axis labels to our pain score thresholds
 */
export function painScore() {
  return {
    display: true,
    title: {
      display: true,
      text: 'Score',
    },
    min: 0,
    max: 20,
    ticks: {
      stepSize: 1,
      autoSkip: false,
      callback: (value) => {
        const label = {
          7: 'Mild',
          16: 'Severe',
        };

        if (Object.keys(label).includes(value.toString())) {
          return label[value];
        } else {
          return undefined;
        }
      },
    },
  };
}
