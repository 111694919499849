import { Controller } from 'stimulus';
import { ifRelevantTo } from '../../javascript/lib/turbo/fetch_events';

/**
 * Renders a loading state while an **empty** Turbo frame is loading.
 *
 * Set a custom loading through a `<template>` element
 * defined as the controllers' `template` target.
 */
export default class extends Controller {
  static targets = [
    'template', // `<template>` element with the content for the loading state
  ];

  connect() {
    this.loaderElement = this.createLoaderElement();

    this.listeners = {
      'turbo:before-fetch-request': ifRelevantTo(
        this.element,
        this.renderLoader.bind(this)
      ),
      // We only need to handle the error case as the frame being loaded
      // will clear the content of the frame
      'amba:fetch-error': ifRelevantTo(
        this.element,
        this.removeLoader.bind(this)
      ),
    };

    for (const [eventType, listener] of Object.entries(this.listeners)) {
      // Listening on `document` to get the same flow for both:
      // - requests from clicking links, where event is triggered from the frame itself
      // - requests from submitting forms, where event is triggered from the form
      document.addEventListener(eventType, listener);
    }
  }

  disconnect() {
    for (const [eventType, listener] of Object.entries(this.listeners)) {
      // Listening on `document` to get the same flow for both:
      // - requests from clicking links, where event is triggered from the frame itself
      // - requests from submitting forms, where event is triggered from the form
      document.removeEventListener(eventType, listener);
    }
  }

  renderLoader() {
    // We only want to set a loading state if the element has no content
    if (this.element.textContent == '') {
      this.element.insertAdjacentElement('afterbegin', this.loaderElement);
    }
  }

  removeLoader() {
    this.loaderElement.remove();
  }

  createLoaderElement() {
    if (this.hasTemplateTarget) {
      return this.templateTarget.content.cloneNode(true).firstElementChild;
    } else {
      const div = document.createElement('div');
      div.textContent = 'Loading...';
      return div;
    }
  }
}
