/**
 * AppSignal's setup.
 * https://docs.appsignal.com/front-end
 * (esp. https://docs.appsignal.com/front-end/hooks.html
 * for the decorators allowing to customize the data sent away)
 * @module lib/appsignal
 */

import Appsignal from '@appsignal/javascript';
import { installErrorHandler } from '@appsignal/stimulus';
import { plugin as windowEvents } from '../lib/appsignal/plugin_window_events';
import { plugin as pathDecorator } from '@appsignal/plugin-path-decorator';

export function setupAppSignal({ key, revision, application }) {
  if (key) {
    const appsignal = new Appsignal({
      key,
      revision,
    });

    // Capture uncaught Error and Promise rejections
    // if this gets too noisy we can revisit and filter things
    // ourselves a little
    appsignal.use(windowEvents());

    // Helpful to know where things broke
    appsignal.use(pathDecorator());
    appsignal.addDecorator(urlParamsDecorator);

    // Fix AppSignal's grouping of errors
    appsignal.addDecorator(errorGroupingDecorator);

    appsignal.addDecorator(taggingDecorator);

    appsignal.addDecorator(browserInfoDecorator);

    installErrorHandler(appsignal, application);

    window.appsignal = appsignal;
  }
}

/**
 * AppSignal's path decorator only adds the `path`
 * but the query params and hash can also be handy
 * for debugging
 */
function urlParamsDecorator(span) {
  return span.setTags({
    query_params: window.location.search,
    hash: window.location.hash,
  });
}
/**
 * AppSignal groups issues by `name`, but JS Errors
 * are not as typed as Ruby's so they'll mostly end
 * up with an "Error" name. Instead, the actual message
 * will get us clearer grouping
 */
function errorGroupingDecorator(span) {
  const error = span.serialize().error;
  return span.setError({
    name: `${error.name}: ${error.message}`,
    message: error.message,
    // Destructuring the error leads to missing stacktrace
    // as it seems the `error` object expects a `stack` property
    // as a String, unlike what's described in the docs
    // https://docs.appsignal.com/front-end/span.html#span-seterror-error-error-object
    stack: error.backtrace.join('\n'),
  });
}

/**
 * Extra browser info useful for debugging
 * (web or mobile platform, viewport size)
 * User agent should be already captured
 * by AppSignal
 */
function browserInfoDecorator(span) {
  return span.setTags({
    platform: platform(),
    viewport_width: window.innerWidth,
    viewport_height: window.innerHeight,
  });
}

function platform() {
  if (window.webkit?.messageHandlers?.nativeApp) {
    return 'iOS';
  }
  if (window.nativeApp) {
    return 'Android';
  }
  return 'web';
}

/**
 * Adds custom tags from the metadata
 */
function taggingDecorator(span) {
  return span.setTags(getPageTags());
}

function getPageTags() {
  const tags = {};

  for (const element of document.head.querySelectorAll(
    "meta[name^='appsignal:tags']"
  )) {
    const key = element.name.replace('appsignal:tags:', '');
    tags[key] = element.content;
  }

  return tags;
}
