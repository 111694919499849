import { Controller } from 'stimulus';

/**
 * The overview uses an accordion and tabs for mobile & desktop respectively
 * (as separate elements).
 * There may be a case where the user changes viewport size, like an orientation change,
 * that'll switch between the two. This controller keeps the two in sync.
 *
 * We can't use the bootstrap methods like `show()` & `hide()` as when the elements
 * aren't visible, they're not initialized, so doing it manually instead.
 */
export default class extends Controller {
  static targets = ['tab', 'tabpanel', 'collapseBtn', 'collapse'];

  connect() {
    this.element.addEventListener('show.bs.tab', ({ target }) => {
      this.updateAccordion(target.dataset.categoryKey);
    });

    this.element.addEventListener('show.bs.collapse', ({ target }) => {
      this.updateTabs(target.dataset.categoryKey);
    });
  }

  updateAccordion(key) {
    this.collapseBtnTargets.forEach((btn) => {
      const isActive = btn.dataset.categoryKey === key;

      btn.setAttribute('aria-expanded', isActive);
      btn.classList.toggle('collapsed', !isActive);
    });

    this.collapseTargets.forEach((collapse) => {
      const isActive = collapse.dataset.categoryKey === key;

      collapse.classList.toggle('show', isActive);
    });
  }

  updateTabs(key) {
    this.tabTargets.forEach((tab) => {
      const isActive = tab.dataset.categoryKey === key;

      tab.setAttribute('aria-selected', isActive);
      tab.classList.toggle('active', isActive);
    });

    this.tabpanelTargets.forEach((tabpanel) => {
      const isActive = tabpanel.dataset.categoryKey === key;

      ['active', 'show'].map((cls) => tabpanel.classList.toggle(cls, isActive));
    });
  }
}
