import { Controller } from 'stimulus';
import Collapse from 'bootstrap/js/src/collapse';

export default class extends Controller {
  static targets = ['collapse'];

  connect() {
    this.collapse = Collapse.getOrCreateInstance(this.collapseTarget, {
      toggle: false,
    });
  }

  toggleCollapse() {
    this.collapse.toggle();
  }
}
