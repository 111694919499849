import { Controller } from 'stimulus';

/**
 * Hide & show the vip quick nav. If the `trigger` element is no
 * longer in view, the `hidden` class is applied to the `nav`
 */
export default class extends Controller {
  static targets = ['nav', 'trigger'];
  static classes = ['minimized'];

  connect() {
    if (this.hasTriggerTarget) {
      const observer = new IntersectionObserver((entries) => {
        entries[0].intersectionRatio <= 0 ? this.hideNav() : this.showNav();
      });

      observer.observe(this.triggerTarget);
    }
  }

  hideNav() {
    this.navTarget.classList.add(this.minimizedClass);
  }

  showNav() {
    this.navTarget.classList.remove(this.minimizedClass);
  }
}
