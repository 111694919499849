import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['titleInput', 'customTitleCheckbox', 'propertySelect'];

  toggleTitle() {
    this.titleInputTarget.readOnly = !this.titleInputTarget.readOnly;

    if (this.customTitleCheckboxTarget.checked) {
      this.titleInputTarget.focus();
    } else {
      this.updateDefaultTitle();
    }
  }

  updateDefaultTitle() {
    if (!this.customTitleCheckboxTarget.checked) {
      const property =
        this.propertySelectTarget.options[
          this.propertySelectTarget.selectedIndex
        ].textContent;
      this.titleInputTarget.value = `Analytics - ${property}`;
    }
  }
}
