import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';
import { alertedTickX } from '../chart_controller/plugins/alerted_tick_x_plugin';
import {
  chartBackground,
  currentTheme,
} from '../chart_controller/plugins/background_color_plugin';
import { axesOptions } from '../chart_controller/plugins/key_indicators/axes_options';
import merge from 'lodash/merge';

const COLOR_WHITE = '#fff';
const COLOR_GRAY = '#AAAFB5';
const COLOR_BRAND_BLUE = '#146ff7';
const COLOR_BRAND_ORANGE = '#EC8333';

const SELECTOR_IMAGE_ICON_ALERTED = '.js-icon-alerted';

const CHART_PADDING_X = 16;
const CHART_ICON_SIZE = 12;
// Offset value against the vertical spacing
// chart.js add to account for large points
const CHART_PADDING_Y_OFFSET = -10;

const POINT_RADIUS_SMALL = 3;
const POINT_RADIUS_LARGE = 4;

/**
 * Generates a simplified line chart with no labels,
 * grid or legend.
 * Attach to a <canvas> element.
 */

export default class extends Controller {
  static values = {
    data: Object,
    options: Object,
    config: Object,
    hideAlerts: Boolean,
  };

  connect() {
    // When the theme is toggled, re-init the chart to get the correct colours
    document.addEventListener('amba:theme_changed', this.reInitOnColorChange);
    this.chartInit();
  }

  disconnect() {
    document.removeEventListener(
      'amba:theme_changed',
      this.reInitOnColorChange
    );
    this.chart.destroy();
  }

  chartInit() {
    this.chart = new Chart(this.element, {
      type: 'line',
      plugins: this.plugins,
      data: this.chartData,
      options: this.chartOptions,
    });
  }

  reInitOnColorChange = () => {
    this.chart.destroy();
    this.chartInit();
  };

  get chartData() {
    return {
      labels: Object.keys(this.dataValue),
      datasets: [
        {
          data: this.chartDataPoints,
          // Pass an array of values, where we can adjust the
          // styling of the last one
          pointBackgroundColor: this.pointBackgroundColorArray,
          pointBorderColor: this.pointBorderColor,
          pointRadius: this.pointRadiusArray,
          hoverRadius: this.pointRadiusArray,
        },
      ],
    };
  }

  get chartOptions() {
    // If we need to render axes labels, add some extra options to configure them
    if (this.configValue.render_axes_labels) {
      return {
        ...this.baseChartOptions,
        ...axesOptions(this.dataValue, this.optionsValue, this.configValue),
      };
    }

    return merge(this.baseChartOptions, this.optionsValue);
  }

  get baseChartOptions() {
    return {
      borderColor: COLOR_BRAND_BLUE,
      borderWidth: 1,
      clip: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              return this.pointLabelsArray[tooltipItem.dataIndex];
            },
          },
          displayColors: false,
        },
      },
      tension: 0.3,
      scales: {
        x: {
          display: false,
          ticks: {
            display: false,
          },
        },
        y: {
          display: false,
          ticks: {
            display: false,
            // Ensure the y-axis doesn't go higher than the
            // highest value displayed
            stepSize: 1,
          },
        },
      },
      layout: {
        padding: {
          left: CHART_PADDING_X,
          right: CHART_PADDING_X,
          bottom: this.bottomPadding,
        },
      },
      hover: {
        mode: 'dataset',
      },
      elements: {
        point: {
          backgroundColor: this.pointBackgroundColor,
          borderColor: COLOR_BRAND_BLUE,
          // Slightly enlarged hit radius to make tooltip appear more easily
          hitRadius: 10,
        },
      },
      animation: {
        duration: 300,
      },
    };
  }

  get plugins() {
    if (this.hideAlertsValue) {
      return [chartBackground()];
    } else {
      return [
        alertedTickX(this.alertedIndexes, this.alertedIcon, CHART_ICON_SIZE),
        chartBackground(),
      ];
    }
  }

  get bottomPadding() {
    if (this.hideAlertsValue) {
      return CHART_PADDING_Y_OFFSET;
    } else {
      return CHART_ICON_SIZE - CHART_PADDING_Y_OFFSET;
    }
  }

  // Transform the data object to remove alerted states, just
  // for rendering the data points. E.g:
  //
  // {Fri Jun 17th: {value: 6, alerted: true}}
  // =>
  // {Fri Jun 17th: 6}
  get chartDataPoints() {
    let chartDataPoints = {};

    for (const [key, value] of Object.entries(this.dataValue)) {
      chartDataPoints[key] = value.value;
    }
    return chartDataPoints;
  }

  // Create an array of booleans respresenting whether each
  // tick should have an alert icon
  get alertedIndexes() {
    return Object.values(this.dataValue).map((value) => value.alerted);
  }

  get pointLabelsArray() {
    return Object.values(this.dataValue).map((value) => value.label);
  }

  get pointRadiusArray() {
    // A value for each datapoint, enlarging the last
    const arr = Array(this.dataLength - 1).fill(POINT_RADIUS_SMALL);
    arr.push(POINT_RADIUS_LARGE);

    return arr;
  }

  get pointBackgroundColorArray() {
    // A value for each datapoint, changing colour for the last
    const arr = Array(this.dataLength - 1).fill(this.pointBackgroundColor);

    const latestPointAlerted =
      this.alertedIndexes[this.alertedIndexes.length - 1];

    let latestPointColor = COLOR_BRAND_BLUE;

    if (!this.hideAlertsValue) {
      latestPointColor = latestPointAlerted
        ? COLOR_BRAND_ORANGE
        : COLOR_BRAND_BLUE;
    }

    arr.push(latestPointColor);

    return arr;
  }

  get pointBorderColor() {
    return this.alertedIndexes.map((value) => {
      if (this.hideAlertsValue) {
        return COLOR_BRAND_BLUE;
      } else {
        return value ? COLOR_BRAND_ORANGE : COLOR_BRAND_BLUE;
      }
    });
  }

  get dataLength() {
    return Object.keys(this.dataValue).length;
  }

  get alertedIcon() {
    // There's a single hidden image on the page that we need to clone.
    // If we don't remove the ".d-none" here, it won't show unless
    // an alerted point is hovered on the chart
    const alertedImg = document
      .querySelector(SELECTOR_IMAGE_ICON_ALERTED)
      .cloneNode();
    alertedImg.classList.remove('d-none');

    return alertedImg;
  }

  get pointBackgroundColor() {
    return currentTheme() === 'light' ? COLOR_WHITE : COLOR_GRAY;
  }
}
