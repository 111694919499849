import { Controller } from 'stimulus';

export default class extends Controller {
  collapse(event) {
    const contentId = event.currentTarget.id;
    const toggle = document.querySelector(
      `[data-bs-toggle="collapse"][href="#${contentId}"],
       [data-bs-toggle="collapse"][data-bs-target="#${contentId}"]`
    );
    if (event.currentTarget.classList.contains('show')) {
      if (
        // Allows direct invocation from other events than 'clickoutside'
        event.type != 'clickoutside' ||
        // Checks that the clickoutside wasn't on or inside
        // the toggle to avoid hiding and showing again
        !toggle.contains(event.detail.event.target)
      ) {
        toggle.click();
      }
    }
  }
}
