import { html } from 'lit';
import { repeat } from 'lit/directives/repeat';
import { datasetForLegendItem } from '../../chart_js';
import legendIcon from './legend_icon';

const LEGEND_ICON_SIZE = 20;
const ALERT_ICON_SIZE = 14;

export default function legendList(
  items,
  { chart, activeDatasetIndex, separateLineAndPoint }
) {
  if (!items.length) {
    return html`<p class="fs-small">No properties selected</p>`;
  } else {
    return html`<ul
      class="list-unstyled d-flex flex-wrap wrapping-spacing-x-3 wrapping-spacing-y-2"
    >
      ${repeat(
        // https://lit.dev/docs/templates/lists/#when-to-use-map-or-repeat
        items,
        (item) => item.text,
        (item) =>
          listItem(item, chart, activeDatasetIndex, separateLineAndPoint)
      )}
    </ul>`;
  }
}

function listItem(item, chart, activeDatasetIndex, separateLineAndPoint) {
  const dataset = datasetForLegendItem(chart, item);
  return html`<li
    class="fs-small"
    data-active="${activeDatasetIndex == item.datasetIndex}"
  >
    ${legendIcon(dataset, { separateLineAndPoint, size: LEGEND_ICON_SIZE })}
    <span class="visually-hidden"
      >${item.text}${dataset.alerted ? ', has data in alert period' : ''}</span
    >
    <span aria-hidden="true">${item.text}</span>
    ${alertedIcon(dataset)}
  </li>`;
}

function alertedIcon(dataset) {
  if (dataset.alerted) {
    return html`<svg
      width="${ALERT_ICON_SIZE}"
      height="${ALERT_ICON_SIZE}"
      class="text-warning"
      aria-hidden="true"
    >
      <use href="#svg_exclamation_triangle"></use>
    </svg>`;
  }
}
