import { Controller } from 'stimulus';

/**
 * Allows a bootstrap collapse to be responsive
 */
export default class extends Controller {
  static values = {
    media: String,
  };

  connect() {
    this.mediaQueryList = window.matchMedia(this.mediaValue);

    this.handleChange = this.handleChange.bind(this);
    this.mediaQueryList.addListener(this.handleChange);
  }

  disconnect() {
    this.mediaQueryList.removeListener(this.handleChange);
  }

  handleChange(event) {
    if (!event.target.matches) {
      this.closeCollapse();
    }
  }

  closeCollapse() {
    const contentId = this.element.id;
    const toggle = document.querySelector(
      `[data-bs-toggle="collapse"][href="#${contentId}"],
       [data-bs-toggle="collapse"][data-bs-target="#${contentId}"]`
    );
    if (this.element.classList.contains('show')) {
      // Don't use the `click` on toggle as we want it to close instantly
      // when the media query stops matching
      toggle.setAttribute('aria-expanded', 'false');
      this.element.classList.remove('show');
    }
  }
}
