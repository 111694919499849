import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'toggle', 'icon'];

  toggle() {
    if (this.inputTarget.type === 'password') {
      this.inputTarget.type = 'text';

      this.iconTarget.classList.remove('fa-eye-slash');
      this.iconTarget.classList.add('fa-eye');

      this.toggleTarget.setAttribute('aria-expanded', 'true');
      this.toggleTarget.setAttribute('aria-label', 'hide password');
    } else {
      this.inputTarget.type = 'password';

      this.iconTarget.classList.remove('fa-eye');
      this.iconTarget.classList.add('fa-eye-slash');

      this.toggleTarget.setAttribute('aria-expanded', 'false');
      this.toggleTarget.setAttribute('aria-label', 'show password');
    }
  }
}
