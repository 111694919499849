import { Controller } from 'stimulus';

/**
 * Attach this to a tablist to make the list respond to arrow & space keys in accordance with
 * WCAG compliance - https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-2/tabs.html#kbd_label
 *
 * @target tabLink - Each tab link that can be a <button> or <a>.
 */

const KEYCODE = {
  LEFT: 37,
  RIGHT: 39,
  SPACE: 32,
};

export default class extends Controller {
  static targets = ['tabLink'];

  connect() {
    // Store the active tab index
    const activeTab = this.tabLinkTargets.find((el) => el.tabIndex === 0);
    this.activeIndex = this.tabLinkTargets.indexOf(activeTab);
  }

  onKeyDown(event) {
    switch (event.keyCode) {
      case KEYCODE.RIGHT:
        event.preventDefault();
        this.focusNextItem();
        break;
      case KEYCODE.LEFT:
        event.preventDefault();
        this.focusPreviousItem();
        break;
      case KEYCODE.SPACE:
        event.preventDefault();
        event.target.click();
        break;
    }
  }

  onClick(event) {
    if (!event.defaultPrevented) {
      // Make sure the clicked item is one of the buttons and
      // not something random :)
      if (
        this.tabLinkTargets.some((tabLink) => tabLink.contains(event.target))
      ) {
        this.activate(event.currentTarget);
        this.activeIndex = this.tabLinkTargets.indexOf(event.currentTarget);
      }
    }
  }

  focusNextItem() {
    // Get next index including if we need to loop back round
    this.activeIndex = (this.activeIndex + 1) % this.tabLinkTargets.length;
    this.activate(this.tabLinkTargets[this.activeIndex]);
  }

  focusPreviousItem() {
    // Get previous index including if we need to loop back round
    this.activeIndex =
      (this.activeIndex + this.tabLinkTargets.length - 1) %
      this.tabLinkTargets.length;
    this.activate(this.tabLinkTargets[this.activeIndex]);
  }

  // This is where the roving tabindex magic happens!
  activate(item) {
    // Set all of the buttons to tabindex -1
    this.tabLinkTargets.forEach((tabLink) => (tabLink.tabIndex = -1));

    // Make the current button "active"
    item.tabIndex = 0;
    item.focus();
  }
}
