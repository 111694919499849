import { svg } from 'lit';

export default function legendIcon(dataset, { separateLineAndPoint, size }) {
  // When separating line and icon, shift things up a bit for better visual alignment
  const yOffset = separateLineAndPoint ? (dataset.pointRadius || 0) / 2 : 0;
  return svg`
    <svg class="amba-chart-legend-icon" role="presentation" focusable="false" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}">
      <g transform="translate(0, ${-yOffset})">
        ${legendIconLine(dataset, { separateLineAndPoint, size: size })}
        ${legendIconPoint(dataset, size)}
      </g>
    </svg>`;
}

function legendIconLine(dataset, { separateLineAndPoint, size }) {
  const dashArray = dataset.borderDash || [];
  let dashSize = dashArray.reduce((total, size) => total + size, 0);

  if (separateLineAndPoint) {
    // Double the sample line size if it's too small
    if (dashSize < 10) {
      dashSize = 2 * dashSize;
    }
    // And add an extra dash to help visualize how the pattern repeats
    dashSize += dashArray[0];
    const offset = (size - dashSize) / 2;
    return svg`<line x1="${offset}" x2="${size - offset}" y1="${
      size - dataset.borderWidth
    }" y2="${size - dataset.borderWidth}" stroke="${
      dataset.borderColor
    }" stroke-width="${dataset.borderWidth}" stroke-dasharray="${dashArray.join(
      ' '
    )}" ></line>`;
  } else {
    // No dashes, full-width line behind the icon
    return svg`<line x1="0" x2="${size}" y1="${size / 2}" y2="${
      size / 2
    }" stroke="${dataset.borderColor}" stroke-width="${
      dataset.borderWidth
    }" stroke-dasharray="${dashArray.join(' ')}" ></line>`;
  }
}

const LEGEND_ICON_SHAPES = {
  circle(dataset, x, y) {
    return svg`<circle r="${dataset.pointRadius}" cx="${x}" cy="${y}" fill="${
      dataset.pointBackgroundColor
    }" stroke="${
      dataset.pointBorderColor || dataset.borderColor
    }" stroke-width="${dataset.pointBorderWidth}"></circle>`;
  },
  rect(dataset, x, y) {
    const size =
      (dataset.pointRadius || 3) +
      (dataset.pointBorderWidth || dataset.borderWidth || 2);
    return svg`<rect x="${x - size / 2}" y="${
      y - size / 2
    }" width="${size}" height="${size}" fill="${
      dataset.pointBackgroundColor
    }" stroke="${
      dataset.pointBorderColor || dataset.borderColor
    }" stroke-width="${dataset.pointBorderWidth}" style="transform: rotate(${
      dataset.pointRotation
    }deg); transform-origin: center"></rect>`; // Use inline style for transform for Safari
  },
  triangle(dataset, x, y) {
    return svg`<path d="${trianglePath(dataset.pointRadius, x, y)}" fill="${
      dataset.pointBackgroundColor
    }" stroke="${
      dataset.pointBorderColor || dataset.borderColor
    }" stroke-width="${dataset.pointBorderWidth}" style="transform: rotate(${
      dataset.pointRotation
    }deg); transform-origin: center"></path>
    `;
  },
};

function trianglePath(radius = 3, x = 0, y = 0) {
  // Draw a triangle the same way Chart.JS draws theirs, rotating around a circle
  // https://github.com/chartjs/Chart.js/blob/8f98515f4518b3a9801998088e8afc90d8bf49d7/src/helpers/helpers.canvas.js#L156
  let rad = 0;
  const instructions = [];
  instructions.push(
    `M ${x + Math.sin(rad) * radius} ${y - Math.cos(rad) * radius}`
  );
  rad += (2 / 3) * Math.PI;
  instructions.push(
    `${x + Math.sin(rad) * radius} ${y - Math.cos(rad) * radius}`
  );
  rad += (2 / 3) * Math.PI;
  instructions.push(
    `${x + Math.sin(rad) * radius} ${y - Math.cos(rad) * radius}`
  );
  instructions.push('Z'); // Close the path
  return instructions.join(' ');
}

function legendIconPoint(dataset, size) {
  // Shift the icon up for visual alignment when separated from the libe
  return LEGEND_ICON_SHAPES[dataset.pointStyle](dataset, size / 2, size / 2);
}
