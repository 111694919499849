import { Controller } from 'stimulus';
// eslint-disable-next-line
import autocomplete from 'autocompleter';

const SELECTOR_AUTOCOMPLETE_CONTAINER = '.amba-vip-search-autocomplete';
const CLASS_HIDDEN = 'd-none';

const DEBOUNCE_WAIT_TIME = 500;
const MIN_TEXT_LENGTH = 1;

export default class extends Controller {
  static targets = ['input'];
  static values = { url: String };

  connect() {
    this.autocomplete = autocomplete({
      input: this.inputTarget,
      debounceWaitMs: DEBOUNCE_WAIT_TIME,
      emptyMsg: 'No results',
      showOnFocus: true,
      minLength: MIN_TEXT_LENGTH,
      className: 'amba-autocomplete amba-vip-search-autocomplete',
      preventSubmit: true, // Prevent 'Enter' submitting the form so it instead follows the vip links.
      fetch: async (text, update) => {
        if (text.length >= MIN_TEXT_LENGTH) {
          const response = await fetch(this.urlValue, {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify({
              q: { name_or_room: text, vip_category: 'all' },
            }),
          });

          if (response.ok) {
            const json = await response.json();
            update(json); // Trigger the `render` callback
          } else {
            window.appsignal.sendError(
              `Error retrieving vip search results: ${response.statusText}`
            );
          }
        }
      },
      render: (vip) => {
        return this.buildResultMarkupFor(vip);
      },
      // Callback after `render` has finished. We manually hide the autocomplete results
      // when closing the modal, so make sure they're shown here.
      customize: () => {
        this.showAutocomplete();
      },
      onSelect: function (vip) {
        window.Turbo.visit(vip.url);
      },
    });
  }

  buildResultMarkupFor(vip) {
    const html = document.createElement('div');
    html.classList.add('amba-vip-search-autocomplete__item');

    let label = ``;

    if (vip.muted) label += `<i class="far fa-snooze" aria-hidden="true"></i>`;

    label += `<span>${vip.label}</span>`;

    if (vip.custom_name) label += `<span>- ${vip.custom_name}</span>`;

    if (vip.residence_label) {
      label += `<span class="amba-vip-search-autocomplete__item-residence-number">${vip.residence_label}</span>`;
    }

    if (vip.recent_alerts_count > 0) {
      label += `
        <span class="btn btn-secondary btn-xs fw-bold amba-vip-search-autocomplete__item-alerts-count">
          <i class="fas fa-exclamation-triangle text-amba-alert"></i>
          <span>${vip.recent_alerts_count} open alerts</span>
        </span>
      `;
    }

    const htmlContent = `
      <a class="amba-vip-search-autocomplete__link" href="${vip.url}">${label}</a>
    `;
    html.innerHTML = htmlContent;

    return html;
  }

  disconnect() {
    this.autocomplete.destroy();
  }

  focusInput() {
    this.inputTarget.focus();
  }

  clearInput() {
    this.inputTarget.value = '';
  }

  showAutocomplete() {
    this.autocompleteElement.classList.remove(CLASS_HIDDEN);
  }

  hideAutocomplete() {
    if (this.autocompleteElement) {
      this.autocompleteElement.classList.add(CLASS_HIDDEN);
    }
  }

  get autocompleteElement() {
    return document.querySelector(SELECTOR_AUTOCOMPLETE_CONTAINER);
  }

  get headers() {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
        ?.content,
      'Content-type': 'application/json; charset=UTF-8',
    };
  }
}
