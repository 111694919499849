const SHAPES = [
  {
    pointStyle: 'circle',
    pointRotation: 0,
    pointRadius: 5,
    pointHoverRadius: 6,
  },
  {
    pointStyle: 'rect',
    pointRadius: 5.5,
    pointHoverRadius: 6.5,
    pointRotation: 0,
  },
  {
    pointStyle: 'triangle',
    pointRadius: 6,
    pointHoverRadius: 7,
    pointRotation: 0,
  },
  {
    pointStyle: 'triangle',
    pointRotation: 180,
    pointRadius: 6,
    pointHoverRadius: 7,
  },
  {
    pointStyle: 'rect',
    pointRadius: 5.5,
    pointHoverRadius: 6.5,
    pointRotation: 45,
  },
  {
    pointStyle: 'triangle',
    pointRotation: 90,
    pointRadius: 6,
    pointHoverRadius: 7,
  },
  {
    pointStyle: 'triangle',
    pointRotation: -90,
    pointRadius: 6,
    pointHoverRadius: 7,
  },
];

const DASHES = [
  [2, 2], //. .
  [4, 4], //_ _
  [8, 8], //__ __
  [2, 2, 4, 2], // . _ . _
  [2, 2, 8, 2], // . __ . __
];

export const DEFAULT_POINT_SHAPE = SHAPES[0];

/**
 * Automatically style or clear styles of the given `chart`'s
 * datasets to render different shapes and dashed lines
 * @param {Chart} chart
 * @param {Boolean} active
 */
export function toggleShapesAndDashes(chart, active) {
  chart.data.datasets.forEach((dataset, index) => {
    if (active) {
      Object.assign(
        dataset,
        SHAPES[(index ? index + 3 : index) % SHAPES.length],
        {
          borderDash: DASHES[(index ? index + 3 : index) % DASHES.length],
        }
      );
    } else {
      Object.assign(dataset, SHAPES[0]);
      dataset.borderDash = [];
    }
  });
}
