import { Controller } from 'stimulus';
import { defineCustomElements } from '@duetds/date-picker/dist/loader';
import { format, parse } from 'date-fns';
import { enUS, enGB } from 'date-fns/locale';
import DUET_DEFAULT_LOCALIZATION from '@duetds/date-picker/dist/collection/components/duet-date-picker/date-localization';
defineCustomElements(window);

export default class extends Controller {
  static targets = ['input'];
  static values = { enableFutureDates: Boolean };

  connect() {
    const picker = (this.picker = document.createElement('duet-date-picker'));

    // Set the proper ID so that the label keeps working
    picker.setAttribute('identifier', this.inputTarget.id);
    this.inputTarget.id = this.inputTarget.id + '_hidden';

    // Prevent polution of the submission with Duet's hidden picker
    // TODO: Figure out how it gets updated so we can simply rename it
    picker.name = '';

    // Propagate the value to the date picker
    picker.value = this.inputTarget.value;
    picker.addEventListener('duetChange', ({ target }) => {
      this.inputTarget.value = target.value;
    });
    // Sets up localization
    const locale = localeFor(navigator.language);
    picker.dateAdapter = this.adapterFor(locale);
    picker.localization = {
      ...DUET_DEFAULT_LOCALIZATION,
      placeholder: locale.code == 'en-GB' ? 'DD/MM/YYYY' : 'MM/DD/YYYY',
      locale: locale.code,
    };

    // Disable future dates unless specifiied
    if (!this.enableFutureDatesValue) picker.max = this.ISOTimeToday;

    // Propagate invalid state of date picker
    // Need to wait 1 frame that the picker is actually rendered
    requestAnimationFrame(() => {
      const pickerInput = picker.querySelector('input');
      if (this.inputTarget.hasAttribute('aria-invalid')) {
        pickerInput.setAttribute('aria-invalid', 'true');
        pickerInput.classList.add('is-invalid');
      }
    });

    // Replace the date pickers
    this.inputTarget.classList.add('d-none');
    this.inputTarget.insertAdjacentElement('beforebegin', picker);
  }

  disconnect() {
    // Restore the input
    this.inputTarget.classList.remove('d-none');
    this.inputTarget.id = this.inputTarget.id.replace(/_hidden$/, '');
    // And remove the injected picker from the DOM
    this.picker.parentElement.removeChild(this.picker);
  }

  adapterFor(locale) {
    return {
      parse: (value = '', createDate) => {
        try {
          const parsed = parse(value, 'P', new Date(), { locale });
          createDate(
            parsed.getFullYear(),
            parsed.getMonth() + 1,
            parsed.getDate()
          );

          // Duet doesn't produce a `duetChange` event, so we set the value ourselves
          //
          // We need to format the string ourselves as `Date.prototype.toISOString()`
          // converts the date to UTC, meaning the date may get shifted
          // to the next or previous day depending on the locale
          this.inputTarget.value = [
            parsed.getFullYear(),
            twoDigits(parsed.getMonth() + 1),
            twoDigits(parsed.getDate()),
          ].join('-');
        } catch {
          // We'll just let Rails handle badly formatted values
        }
      },
      format(date) {
        return format(date, 'P', { locale });
      },
    };
  }

  get ISOTimeToday() {
    // Get timezone offset in milliseconds
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000;

    // Get ISO string of the current time in the local timezome
    let localISOTime = new Date(Date.now() - timeZoneOffset).toISOString();

    // Format to just the date e.g 2023-01-01
    localISOTime = localISOTime.slice(0, -1).split('T')[0];

    return localISOTime;
  }
}

function twoDigits(number) {
  // https://stackoverflow.com/a/13861999
  // Can soon be replaced by `String.prototype.padStart`
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
  return ('0' + number).slice(-2);
}

const LANGUAGES_WITH_US_FORMAT = ['CA', 'US'];
const REGEXP_US_FORMAT = new RegExp(
  `-(${LANGUAGES_WITH_US_FORMAT.join('|')})$`
);

function localeFor(language) {
  if (language.match(REGEXP_US_FORMAT)) {
    return enUS;
  } else {
    return enGB;
  }
}
