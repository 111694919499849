import { Controller } from 'stimulus';

/**
 * Exposes an action to update the value of a different controller.
 *
 * Example:
 *
 * 1. Attach this controller & pass the data-attr for the value you want to change.
 *
 *  <div
 *    data-controller="update-controller-value"
 *    update-controller-value-controller-attribute-value: "data-other-controller-value">
 *
 * 2. Attach the `updateValue` action, with the `value-param` containing the new value
 * to update with.
 *
 *  <button
 *    data-action="update-controller-value#updateValue"
 *    data-update-controller-value-value-param="[NEW VALUE]">
 *
 * 3. Set the controller whose value needs updating as a target. It's value will then be
 * updated to the [NEW VALUE].
 *
 *  <div
 *    data-controller="other-controller",
 *    data-other-controller-value="[OLD VALUE]"
 *    update-controller-value-target: "controller">
 */
export default class extends Controller {
  static targets = ['controller'];

  static values = {
    controllerAttribute: String,
  };

  updateValue(event) {
    event.preventDefault();

    this.controllerTarget.setAttribute(
      this.controllerAttributeValue,
      JSON.stringify(event.params.value)
    );
  }
}
