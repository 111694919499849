import { Controller } from 'stimulus';

const OFFSET_MARGIN = '500px';
const CLASS_HIDDEN = 'd-none';

export default class extends Controller {
  static targets = ['entries', 'pagination', 'loadMoreText', 'loadingText'];

  initialize() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.processIntersectionEntries(entries),
      this.options
    );
  }

  connect() {
    this.startObserver();
  }

  disconnect() {
    this.stopObserver();
  }

  startObserver() {
    this.intersectionObserver.observe(this.paginationTarget);
  }

  stopObserver() {
    this.intersectionObserver.unobserve(this.paginationTarget);
  }

  toggleLoadingState(shouldToggle) {
    this.element.toggleAttribute('aria-busy', shouldToggle);

    if (this.hasLoadMoreTextTarget) {
      this.loadMoreTextTarget.classList.toggle(CLASS_HIDDEN, shouldToggle);
      this.loadingTextTarget.classList.toggle(CLASS_HIDDEN, !shouldToggle);
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  async loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']");
    if (next_page == null) return;

    this.stopObserver();
    this.toggleLoadingState(true);

    try {
      let url = next_page.href;
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
        },
      });
      const { entries, pagination } = await response.json();

      this.entriesTarget.insertAdjacentHTML('beforeend', entries);
      this.paginationTarget.innerHTML = pagination;

      this.startObserver();
      this.toggleLoadingState(false);

      this.element.dispatchEvent(
        new CustomEvent('infinite-scroll.loaded', {
          bubbles: true,
        })
      );
    } catch (error) {
      console.error(error);
      this.startObserver();
      this.toggleLoadingState(false);
    }
  }

  get options() {
    return {
      rootMargin: OFFSET_MARGIN,
    };
  }
}
