import { Controller } from 'stimulus';

const CLASS_TOGGLE = 'amba-sortable--show-controls';
const CLASS_BTN_ACTIVE = 'active';

/**
 * Respresents a section where that contains a sortable list.
 * The `toggle` action toggles the "show controls" class to the
 * list element
 */
export default class extends Controller {
  static targets = ['sortableContainer', 'announcement'];

  toggle(event) {
    this.sortableContainerTarget.classList.toggle(CLASS_TOGGLE);
    event.currentTarget.classList.toggle(CLASS_BTN_ACTIVE);

    const btnIsActive =
      event.currentTarget.classList.contains(CLASS_BTN_ACTIVE);

    event.currentTarget.setAttribute('aria-pressed', btnIsActive);

    this.announcementTarget.textContent = `Re-order mode turned ${
      btnIsActive ? 'on' : 'off'
    }`;
  }
}
