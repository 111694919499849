import { Controller } from 'stimulus';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const COLOR_WHITE = '#fff';
const COLOR_BRAND_BLUE = '#146ff7';
const COLOR_BRAND_LIGHT_BLUE = '#f4f9fd';
const COLOR_BRAND_ORANGE = '#EC8333';
const COLOR_BRAND_GREEN = '#20a372';
const POINT_RADIUS_SMALL = 3;
const POINT_RADIUS_LARGE = 4;
const CHART_PADDING_X = 16;
const SELECTOR_IMAGE_ICON_ALERTED = '.js-icon-alerted';
const COLOR_CHART_BACKGROUND_THEME_LIGHT = '#f4f9fd';
const COLOR_CHART_BACKGROUND_THEME_DARK = '#474c4e';

export default class extends Controller {
  static values = { chartData: Object, iconFontUrl: String };

  connect() {
    document.addEventListener('amba:theme_changed', this.handleThemeChange);

    // We're force loading fontawesome here to ensure that the font-icons render when the
    // data point is alerted, chartjs-plugin-datalabels does not (and no plans to) support
    // rendering an actual image in the label callback.
    const faFont = new FontFace('FontAwesome', `url(${this.iconFontUrlValue})`);

    faFont
      .load()
      .then((font) => {
        document.fonts.add(font);

        this.chart = new Chart(this.element, {
          type: 'line',
          data: this.chartData,
          plugins: [ChartDataLabels],
          options: {
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => {
                    return this.pointLabelsArray[tooltipItem.dataIndex];
                  },
                },
                displayColors: false,
              },
              datalabels: {
                clamp: true,
                align: this.datalabelsAlignment,
                offset: '10',
                font: {
                  family: 'FontAwesome, Public Sans, sans-serif',
                  weight: '900',
                  size: 15,
                },
                color: (context) => {
                  return context.dataset.alerted[context.dataIndex]
                    ? COLOR_BRAND_ORANGE
                    : COLOR_BRAND_GREEN;
                },
                formatter: (value, context) => {
                  if (context.dataset.alerted[context.dataIndex]) {
                    return `${context.dataset.icons[context.dataIndex]} ${
                      context.dataset.formattedTime[context.dataIndex]
                    }`;
                  } else {
                    return `${
                      context.dataset.formattedTime[context.dataIndex]
                    }`;
                  }
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                beginAtZero: false,
                ticks: {
                  align: 'inner',
                  font: {
                    weight: '500',
                  },
                },
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  min: 20000,
                  max: 35000,
                  stepSize: 5000,
                },
                display: false,
              },
            },
            layout: {
              padding: {
                top: 60,
                left: CHART_PADDING_X,
                right: CHART_PADDING_X,
                bottom: 10,
              },
            },
          },
        });
        this.updateChartAppearance(); // Add this line here
        return font;
      })
      .catch((error) => {
        console.log(`Error loading font: ${error}`);
      });
  }

  disconnect() {
    // Remove the theme change event listener when disconnecting
    document.removeEventListener('amba:theme_changed', this.handleThemeChange);

    this.chart.destroy();
  }

  updateChartAppearance = () => {
    // Update the chart's background color based on the current theme
    const backgroundColor =
      this.currentTheme === 'light'
        ? COLOR_CHART_BACKGROUND_THEME_LIGHT
        : COLOR_CHART_BACKGROUND_THEME_DARK;
    this.chart.data.datasets[0].backgroundColor = backgroundColor;

    // Update the color of the x-axis labels based on the current theme
    const labelColor = this.currentTheme === 'light' ? '#000' : COLOR_WHITE;
    this.chart.options.scales.x.ticks.color = labelColor;

    this.chart.update();
  };

  handleThemeChange = () => {
    this.updateChartAppearance();
  };

  get currentTheme() {
    // Get the current theme from the cookie
    const value = `; ${document.cookie}`;
    const parts = value.split(`; theme=`);
    const theme =
      parts.length === 2
        ? parts.pop().split(';').shift()
        : document.body.classList.contains('dark')
        ? 'dark'
        : 'light';

    return theme;
  }

  get chartData() {
    const chartDataPoints = [];
    const isAlerted = [];
    const chartFormattedtimes = [];

    const uppercaseLabels = Object.keys(this.chartDataValue).map((label) =>
      label.toUpperCase()
    );

    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(this.chartDataValue)) {
      chartDataPoints.push(value.value);
      chartFormattedtimes.push(value.label);
      isAlerted.push(value.alerted);
    }

    return {
      labels: uppercaseLabels, // Use the uppercase labels here
      datasets: [
        {
          icons: ['\uf071', '\uf071', '\uf071'],
          data: chartDataPoints,
          alerted: isAlerted,
          formattedTime: chartFormattedtimes,
          borderColor: COLOR_BRAND_BLUE,
          borderWidth: 1,
          backgroundColor: COLOR_BRAND_LIGHT_BLUE,
          fill: true,
          pointHoverRadius: 8,
          lineTension: 0.4, // make the line curved
          pointBackgroundColor: this.pointBackgroundColorArray,
          pointBorderColor: this.pointBorderColor,
          pointRadius: this.pointRadiusArray,
          hoverRadius: this.pointRadiusArray,
          elements: {
            point: {
              backgroundColor: COLOR_WHITE,
              borderColor: COLOR_BRAND_BLUE,
              // Slightly enlarged hit radius to make tooltip appear more easily
              hitRadius: 10,
            },
          },
        },
      ],
    };
  }

  get alertedIndexes() {
    return Object.values(this.chartDataValue).map((value) => value.alerted);
  }

  get alertedIcon() {
    // There's a single hidden image on the page that we need to clone.
    // If we don't remove the ".d-none" here, it won't show unless
    // an alerted point is hovered on the chart
    const alertedImg = document
      .querySelector(SELECTOR_IMAGE_ICON_ALERTED)
      .cloneNode();
    alertedImg.classList.remove('d-none');

    return alertedImg;
  }

  get datalabelsAlignment() {
    const dataLength = Object.entries(this.chartDataValue).length;

    if (dataLength === 2) {
      return ['300', '240'];
    } else if (dataLength === 3) {
      return ['300', '270', '240'];
    } else {
      return null; // return null if there is no data
    }
  }

  get pointLabelsArray() {
    return Object.values(this.chartDataValue).map((value) => value.label);
  }

  get pointRadiusArray() {
    // A value for each datapoint, enlarging the last
    const arr = Array(this.dataLength - 1).fill(POINT_RADIUS_SMALL);
    arr.push(POINT_RADIUS_LARGE);

    return arr;
  }

  get pointBackgroundColorArray() {
    // A value for each datapoint, changing colour for the last
    const arr = Array(this.dataLength - 1).fill(COLOR_WHITE);

    const latestPointAlerted =
      this.alertedIndexes[this.alertedIndexes.length - 1];
    const latestPointColor = latestPointAlerted
      ? COLOR_BRAND_ORANGE
      : COLOR_BRAND_BLUE;
    arr.push(latestPointColor);

    return arr;
  }

  get pointBorderColor() {
    return this.alertedIndexes.map((value) =>
      value ? COLOR_BRAND_ORANGE : COLOR_BRAND_BLUE
    );
  }

  get dataLength() {
    return Object.keys(this.chartDataValue).length;
  }
}
