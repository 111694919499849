import { Controller } from 'stimulus';

const ACTIVITY_DETECTOR_CONFIG = { initialState: 'idle' };
const ACTIVITY_DETECTOR_EVENTS = ['idle', 'active'];
const ACTIVITY_DETECTOR_INIT_TIMEOUT = 1000;
const ACTIVITY_DETECTOR_EVENT_TIMEOUT = 200;

const SECONDS_LAST_ANNOUNCEMENT = 20000;

const SELECTOR_PLAY = 'play';
const SELECTOR_PAUSE = 'pause';

const CLASS_SHOW = 'd-block';
const CLASS_HIDDEN = 'd-none';

/**
 * A countdown timer that can be paused/resumed and refreshes the
 * page when it ends. It will also pause/resume when the user is
 * active/idle.
 *
 * @target timerEl - The element display the time.
 * @target heading - Region heading whose aria-label gets updated every 30 seconds.
 * @target buttonIcon - Icons inside the timer button whose display get's toggled.
 * @target pauseToggle - Button for toggle pause & resume.
 * @target pauseToggleLabel - Descriptive label inside pauseToggle button.
 * @target minuteAnnouncements - Alert to update every 30 seconds
 *
 * @value totalMinutes - Start time. This also accepts a decimal value. Eg - 2.5
 * would start the timer at 2:30.
 * @value paused - Whether the timer should initialise as paused.
 */

export default class extends Controller {
  static targets = [
    'timerEl',
    'heading',
    'buttonIcon',
    'pauseToggleLabel',
    'minuteAnnouncements',
  ];

  static values = {
    totalMinutes: Number,
    paused: Boolean,
  };

  connect() {
    this.timeInMinutes = this.totalMinutesValue;
    this.currentTime = Date.parse(new Date());
    this.endTime = new Date(this.currentTime + this.timeInMinutes * 60 * 1000);
    this.timeInterval;
    this.paused = this.pausedValue;
    this.pausedTimeLeft;
    this.pausedByUser = this.pausedValue;

    if (this.paused) {
      this.setTimeLeft();
      this.toggleButtonIconTo(SELECTOR_PLAY);
    } else {
      this.runTimer();
      this.initActivityDetector();
    }
  }

  disconnect() {
    document.cookie = `timer_paused=${this.pausedByUser}`;
    clearInterval(this.timeInterval);
  }

  // Handle running & updating

  runTimer() {
    this.updateTimer();
    this.timeInterval = setInterval(this.updateTimer, 1000);
  }

  updateTimer = () => {
    const total = this.getTimeRemaining(this.endTime);
    this.timerElTarget.textContent = `${total.minutes}:${total.seconds}`;

    this.updateHeadingAriaLabel(total);
    this.updateAnnouncementAlert(total);

    if (total.total <= 0) {
      this.refreshPage();
    }
  };

  // Handle pausing & resuming

  pauseToggle() {
    // Activity detector doesn't provide us with an event, so
    // we can work out if the function was triggered by the user
    // or the detector.
    const triggeredByUser = typeof event === 'object';

    if (this.paused) {
      // Only resume when paused by the detector or when using the button.
      if (!this.pausedByUser || triggeredByUser) {
        this.resumeTimer();
      }

      if (triggeredByUser) {
        this.reInitActivityDetector();
        this.pausedByUser = false;
      }
    } else {
      // If paused by the user, disallow pausing by the detector.
      if (!this.pausedByUser) {
        this.pauseTimer();
      }

      if (triggeredByUser) {
        this.stopActivityDetector();
        this.pausedByUser = true;
      }
    }
  }

  resumeTimer = () => {
    if (this.pausedTimeLeft) {
      // Update the endTime to preserve the amount of time remaining
      this.endTime = new Date(Date.parse(new Date()) + this.pausedTimeLeft);
    }

    this.pauseToggleLabelTarget.textContent = 'Pause timer';

    this.toggleButtonIconTo(SELECTOR_PAUSE);
    this.invertPauseState();

    this.runTimer();
  };

  pauseTimer = () => {
    clearInterval(this.timeInterval);
    this.pauseToggleLabelTarget.textContent = 'Resume timer';
    this.setTimeLeft();

    this.toggleButtonIconTo(SELECTOR_PLAY);
    this.invertPauseState();
  };

  toggleButtonIconTo(state) {
    this.buttonIconTargets.forEach((icon) => {
      if (icon.classList.value.includes(state)) {
        icon.classList.add(CLASS_SHOW);
        icon.classList.remove(CLASS_HIDDEN);
      } else {
        icon.classList.add(CLASS_HIDDEN);
      }
    });
  }

  // Update assitive labels

  updateHeadingAriaLabel(total) {
    if (total.seconds.endsWith('0')) {
      const minutesText =
        total.minutes > 0 ? `${total.minutes} minutes and ` : '';
      const secondsText =
        total.seconds !== '00' ? `${total.seconds} seconds` : '';
      this.headingTarget.setAttribute(
        'aria-label',
        `This page will automatically refresh in ${minutesText} ${secondsText}`
      );
    }
  }

  updateAnnouncementAlert(total) {
    // Alert the user when the timer has nearly ended...
    if (total.total === SECONDS_LAST_ANNOUNCEMENT) {
      this.minuteAnnouncementsTarget.textContent = `This page will automatically refresh in ${
        SECONDS_LAST_ANNOUNCEMENT / 100
      } seconds`;

      // ...or every minute excluding the start
    } else if (total.minutes !== 5 && total.seconds === '00') {
      this.minuteAnnouncementsTarget.textContent = `This page will automatically refresh in  ${total.minutes} minutes`;
    }
  }

  // Activity detector

  setupActivityDetectorEvents() {
    ACTIVITY_DETECTOR_EVENTS.forEach((event) => {
      window.activityDetector.on(event, () => {
        setTimeout(() => {
          this.pauseToggle();
        }, ACTIVITY_DETECTOR_EVENT_TIMEOUT);
      });
    });
  }

  reInitActivityDetector() {
    this.stopActivityDetector();
    this.initActivityDetector();
  }

  initActivityDetector() {
    setTimeout(() => {
      window.activityDetector.init(ACTIVITY_DETECTOR_CONFIG);

      this.setupActivityDetectorEvents();
    }, ACTIVITY_DETECTOR_INIT_TIMEOUT);
  }

  stopActivityDetector() {
    window.activityDetector.stop();
  }

  // Time helpers

  setTimeLeft() {
    this.pausedTimeLeft = this.getTimeRemaining(this.endTime).total;
  }

  getTimeRemaining() {
    const total = Date.parse(this.endTime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    return {
      total: total,
      minutes: minutes,
      seconds: this.formatZerosFor(seconds),
    };
  }

  formatZerosFor(seconds) {
    return seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  // Other

  invertPauseState() {
    this.paused = !this.paused;
  }

  refreshPage() {
    clearInterval(this.timeInterval);
    window.location.reload();
  }
}
