import { Controller } from 'stimulus';
import Tooltip from 'bootstrap/js/src/tooltip';

const DEFAULT_FALLBACK_PLACEMENTS = ['top', 'right', 'bottom', 'left'];

export default class extends Controller {
  static values = {
    fallbackPlacement: Array,
    disabled: Boolean,
  };

  connect() {
    const options = {
      fallbackPlacements: this.fallbackPlacements,
    };

    this.tooltip = new Tooltip(this.element, options);

    // Tooltips will overlap modals if they're open at the same time
    document.addEventListener('show.bs.modal', this.hideTooltip);
  }

  disconnect() {
    this.tooltip.hide();
    document.removeEventListener('show.bs.modal', this.hideTooltip);
  }

  get fallbackPlacements() {
    if (this.hasFallbackPlacementValue) {
      return this.fallbackPlacementValue;
    }

    return DEFAULT_FALLBACK_PLACEMENTS;
  }

  // A lexically scoped method to be added & removed by event listeners
  hideTooltip = () => {
    this.tooltip.hide();
  };

  disabledValueChanged() {
    // This may be set by another controller before the
    // tooltip is initialised, so skipping a frame before
    // running
    requestAnimationFrame(() => {
      if (this.disabledValue) {
        this.tooltip.disable();
      } else {
        this.tooltip.enable();
      }
    });
  }
}
