import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dailyDigestWrapper'];

  click(event) {
    if (
      event.currentTarget.value == 'admin' ||
      event.currentTarget.value == 'member'
    ) {
      this.dailyDigestWrapperTarget.classList.remove('js-d-none');
    } else {
      this.dailyDigestWrapperTarget.classList.add('js-d-none');
    }
  }
}
