import debounce from 'lodash/debounce';
import { Controller } from 'stimulus';

const CLASS_OVERFLOWS = 'amba-overflows';

/**
 * Marks its element with an `amba-overflows` class when it overflows,
 * allowing to attach styles to it or its siblings/descendants.
 */
export default class extends Controller {
  static classes = ['overflows'];

  connect() {
    // Schedule an update after next frame to let Stimulus finish connecting
    // all its controllers
    requestAnimationFrame(() => {
      this.updateOverflowState();
    });

    // Debounce to not risk degrading performances during the resizing
    this.handleResize = debounce(this.updateOverflowState.bind(this), 100);
    window.addEventListener('resize', this.handleResize);
  }

  disconnect() {
    window.removeEventListener('resize', this.handleResize);
  }

  updateOverflowState() {
    if (this.element.scrollWidth > this.element.clientWidth) {
      this.element.classList.add(CLASS_OVERFLOWS);
    } else {
      if (this.element.classList.contains(CLASS_OVERFLOWS)) {
        this.element.classList.remove(CLASS_OVERFLOWS);
        const event = new CustomEvent('amba:overflows-no-longer', {
          bubbles: true,
        });
        this.element.dispatchEvent(event);
      }
    }
  }
}
