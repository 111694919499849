import { Controller } from 'stimulus';

/**
 * Expose a print action that dispatches events either side of it, useful
 * for forcing a theme during printing
 */
export default class extends Controller {
  print() {
    this.dispatch('print_start');

    window.print();

    this.dispatch('print_end');
  }
}
