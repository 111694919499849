import { Controller } from 'stimulus';

const SELECTOR_NESTED_FIELDS = '.nested-fields';

/**
 * Handle focusing when adding & removing cocoon nested
 * fields.
 */
export default class extends Controller {
  static targets = ['nestedField'];

  nestedFieldTargetConnected(el) {
    this.focusFirstFormElementFor(el);
  }

  // This must be used as an action on the remove button,
  // as we need to traverse the dom before it's deleted.
  focusPreviousField({ currentTarget }) {
    const fieldsetParent = currentTarget.closest(SELECTOR_NESTED_FIELDS);
    const previousFieldsetSibling =
      this.getPreviousFieldsetSiblingFor(fieldsetParent);

    if (previousFieldsetSibling) {
      this.focusFirstFormElementFor(
        this.getPreviousFieldsetSiblingFor(fieldsetParent)
      );
    }
  }

  focusFirstFormElementFor(fieldset) {
    let firstFormElement = Array.from(fieldset.elements).find(
      (el) => !el.disabled
    );

    if (firstFormElement) {
      firstFormElement.focus();
    }
  }

  // There's often a hidden input after the first fieldset, so find
  // the previous fieldset more reliably then just previousElementSibling.
  getPreviousFieldsetSiblingFor(fieldset) {
    let sibling = fieldset.previousElementSibling;

    while (sibling) {
      if (sibling.matches(SELECTOR_NESTED_FIELDS)) return sibling;
      sibling = sibling.previousElementSibling;
    }
  }
}
