import { Controller } from 'stimulus';

const CLASS_LIGHT_THEME = 'amba-theme-light';
const CLASS_DARK_THEME = 'amba-theme-dark';

/**
 * Handle toggling of the app colour theme.
 * This should be attached to the `<body>` element.
 *
 * This controller handles the setting of the theme cookie
 * and changing the theme state when the toggle is changed, or
 * the user changes their colour-scheme preference.
 */
export default class extends Controller {
  static targets = ['inputLight', 'inputDark'];

  connect() {
    // Printing a page can trigger a theme change, so hook onto the print events to
    // disconnect before printing & reconnect straight after
    document.addEventListener(
      'print:print_start',
      () => {
        this.disconnect();
      },
      { once: true }
    );

    document.addEventListener(
      'print:print_end',
      () => {
        this.connect();
      },
      { once: true }
    );

    // If there's no theme cookie set on page load, set the theme
    // with the users colour-scheme preference
    if (!this.currentThemeFromCookie) this.setThemeState();

    this.setupColorSchemeListener();
  }

  disconnect() {
    if (this.colorPreference) {
      this.colorPreference.removeEventListener(
        'change',
        this.toggleThemeOnPreferenceChange
      );
    }
  }

  toggle() {
    let theme;

    if (this.prefersDarkScheme.matches) {
      this.element.classList.toggle(CLASS_LIGHT_THEME);

      theme = this.element.classList.contains(CLASS_LIGHT_THEME)
        ? 'light'
        : 'dark';
    } else {
      this.element.classList.toggle(CLASS_DARK_THEME);

      theme = this.element.classList.contains(CLASS_DARK_THEME)
        ? 'dark'
        : 'light';
    }

    this.setThemeState(theme);
  }

  // Set the theme to either the passed argument, or the users
  // colour-scheme preference
  setThemeState(theme = null) {
    if (!theme) {
      theme = this.prefersDarkScheme.matches ? 'dark' : 'light';
    }

    // Set the theme cookie
    // Note: the path must be set to avoid the cookie being duplicated
    document.cookie = 'theme=' + theme + ';path=/';

    // Set the body class to apply the theme
    this.element.classList.remove('amba-theme-light');
    this.element.classList.remove('amba-theme-dark');
    this.element.classList.add(`amba-theme-${theme}`);

    // Incase this state isn't triggered by the toggle,
    // update it to reflect the change
    if (this.hasInputLightTarget) {
      const toggleInputToCheck =
        theme === 'light' ? this.inputLightTarget : this.inputDarkTarget;
      toggleInputToCheck.checked = true;
    }

    // Dispatch an event for other JS code to hook onto
    const event = new CustomEvent('amba:theme_changed', {
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  setupColorSchemeListener() {
    this.colorPreference = window.matchMedia('(prefers-color-scheme: dark)');
    this.colorPreference.addEventListener(
      'change',
      this.toggleThemeOnPreferenceChange
    );
  }

  toggleThemeOnPreferenceChange = (event) => {
    const theme = event.matches ? 'dark' : 'light';
    this.setThemeState(theme);
  };

  get prefersDarkScheme() {
    return window.matchMedia('(prefers-color-scheme: dark)');
  }

  // Gets theme value from the cookie string
  get currentThemeFromCookie() {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; theme=`);
    const theme = parts.length === 2 ? parts.pop().split(';').shift() : null;

    return theme;
  }
}
