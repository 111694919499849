export function updateProgressRequest(percentage, url) {
  fetch(url, {
    method: 'PATCH',
    headers: requestHeaders(),
    body: JSON.stringify({
      progress: {
        completion_percentage: percentage,
      },
    }),
  });
}

function requestHeaders() {
  return {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content,
    'Content-type': 'application/json; charset=UTF-8',
  };
}
