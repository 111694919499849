import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'input'];

  connect() {
    this.toggle();
  }

  toggle() {
    this.inputTargets.forEach((input) => (input.disabled = !this.checked));
  }

  get checked() {
    return this.checkboxTarget.checked;
  }
}
