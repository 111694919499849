import { Controller } from 'stimulus';

/**
 * Injects the content of a `<template>` element inside the DOM
 * to have a clean DOM when JavaScript is not there (and avoid duplicates)
 */
export default class extends Controller {
  connect() {
    const content = this.element.content.cloneNode(true);
    this.element.parentElement.replaceChild(content, this.element);
  }
}
