export function announce(
  content,
  {
    level = 'polite',
    // Set a default region, but allow to pass a specific element
    // if necessary (for ex. in the `announce` Turbo Stream action)
    region = document.querySelector(`.a11y-announcements-${level}`),
  } = {}
) {
  // We don't want Turbo caching the content
  region.innerHTML = `<div data-turbo-cache="false">${content}</div>`;

  setTimeout(() => {
    // Clear the live region for future use
    region.textContent = '';
  }, 1000);
  // 1000 pulled from this article by Adrian Roselli
  // https://adrianroselli.com/2021/04/sortable-table-columns.html#Announce
  // Unfortunately, the browser gives no feedback as to when the content was
  // was announced, so that's the best we can do :(
}
