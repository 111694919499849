import { Controller } from 'stimulus';
import SwaggerUI from 'swagger-ui';

export default class extends Controller {
  static values = { clientId: String };

  connect() {
    const ui = SwaggerUI({
      url: '/api/swagger_doc.json',
      dom_id: '#swagger',
    });

    ui.initOAuth({
      clientId: this.clientIdValue,
    });
  }
}
