import { Controller } from 'stimulus';

// This controller is to ensure that the inline styles passed to the score_wheel charts in pdf view render correctly
// we do not have access to scss variables in the PDF view through puppeteer hence why we're using this JS to set the inline style with hardcoded
// hex values. The styles set in the webapp through the sleep_score_component file will be used in place of this code when the charts are rendered in app.
export default class extends Controller {
  static values = { degrees: String };
  connect() {
    // check if content is loaded in pdf
    function isPDFView() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('format') === 'pdf';
    }

    if (isPDFView()) return;

    if (this.element.classList.contains('amba-score-wheel__target')) {
      this.applyTargetStyle();
    } else if (this.element.classList.contains('amba-score-wheel__score')) {
      this.applySleepStyle();
    }
  }

  applyTargetStyle() {
    const styleString = `background: conic-gradient(from 0deg, #a8cafc ${this.degreesValue}deg, #fff 0)`;
    this.element.setAttribute('style', styleString);
  }

  applySleepStyle() {
    const styleString = `background: conic-gradient(from 0deg, #0866f7 ${this.degreesValue}deg, #e9ecef 0)`;
    this.element.setAttribute('style', styleString);
  }
}
