// A little offset when bringing the active element
// into view so that it's not straight at the edge

import { matchesFocusVisible } from '../../../javascript/lib/dom/matches';

// of the chart area
const IN_VIEW_OFFSET = 10;

export class KeyboardNavigationPlugin {
  // Default the accessor to the visibleElementBeforeFirst
  // to make the left/right navigation hit the first element
  // when focus is acquired on the graph through mouse
  // but user hits the keyboard/uses their screenreader
  get activeElement() {
    return this._activeElement || this.visibleElementBeforeFirst;
  }

  set activeElement(value) {
    this._activeElement = value;
  }

  get hasActiveElement() {
    return !!this._activeElement;
  }

  get visibleElementBeforeFirst() {
    return {
      datasetIndex: this.subsequentVisibleDataset(-1, 1),
      index: -1,
    };
  }

  afterInit(chart) {
    this.chart = chart;

    chart.canvas.setAttribute('tabindex', '0');
    chart.canvas.addEventListener('focus', (event) => {
      if (matchesFocusVisible(event.target)) {
        this.highlightFirstElement(chart);
      }
    });
    chart.canvas.addEventListener('blur', (event) => {
      this.clearHighlight(event);
    });
    chart.canvas.addEventListener('keydown', keyboardEventHandler(this));
    chart.canvas.addEventListener('click', (event) => event.preventDefault());
  }
  onArrowDown() {
    this.activeElement = {
      datasetIndex: this.subsequentVisibleDataset(
        this.activeElement.datasetIndex,
        1
      ),
      index: 0,
    };
    this.highlight();
  }
  onArrowUp() {
    this.activeElement = {
      datasetIndex: this.subsequentVisibleDataset(
        this.activeElement.datasetIndex,
        -1
      ),
      index: 0,
    };
    this.highlight();
  }
  onArrowLeft() {
    const newIndex = positiveModulo(
      this.activeElement.index - 1,
      this.chart.data.datasets[this.activeElement.datasetIndex].data.length
    );

    this.activeElement = {
      ...this.activeElement,
      index: newIndex,
    };
    this.highlight();
  }
  onArrowRight() {
    const newIndex = positiveModulo(
      this.activeElement.index + 1,
      this.chart.data.datasets[this.activeElement.datasetIndex].data.length
    );

    this.activeElement = {
      ...this.activeElement,
      index: newIndex,
    };
    this.highlight();
  }
  subsequentVisibleDataset(currentDatasetIndex, increment) {
    // Start at 1 as we'll use the `iteration` as a multiplier
    for (
      let iteration = 1;
      iteration <= this.chart.data.datasets.length;
      iteration++
    ) {
      const newIndex = positiveModulo(
        currentDatasetIndex + iteration * increment,
        this.chart.data.datasets.length
      );
      if (!this.chart.data.datasets[newIndex].hidden) {
        return newIndex;
      }
    }
  }
  highlightFirstElement() {
    this.activeElement = {
      datasetIndex: this.subsequentVisibleDataset(-1, 1),
      index: 0,
    };
    this.highlight();
  }
  clearHighlight() {
    this.activeElement = null;
    this.highlight();
  }
  highlight() {
    // Use the private value here as we don't want to have a default
    // when no activeElement is set
    const activeElements = this.hasActiveElement ? [this.activeElement] : [];

    this.chart.setActiveElements(activeElements);
    this.chart.tooltip.setActiveElements(activeElements);
    if (this.activeElement) {
      this.maybePanActiveElementInView();
    }
    this.chart.update();
  }

  // ** PUTTING ACTIVE ELEMENT IN VIEW **

  maybePanActiveElementInView() {
    // Grab the active element from the chart so we get the actual positioning info
    const activeElement = this.chart.getActiveElements()[0];
    if (activeElement) {
      const y = activeElement.element.y;
      if (y < this.chart.chartArea.top) {
        this.automaticallyPan(this.chart.chartArea.top - y + IN_VIEW_OFFSET);
      } else if (y > this.chart.chartArea.bottom) {
        this.automaticallyPan(this.chart.chartArea.bottom - y - IN_VIEW_OFFSET);
      }
    }
  }

  automaticallyPan(panOffset) {
    this.chart.pan(
      {
        y: panOffset,
      },
      undefined,
      'default'
    );
  }
}

/**
 * Ensures a positive modulo value, as the `%` operator
 * gives negative values for negative dividends
 * @param {Integer} dividend
 * @param {Integer} divisor
 */
function positiveModulo(dividend, divisor) {
  return (divisor + (dividend % divisor)) % divisor;
}

/**
 * Creates an event handler for keyboard events
 * that looks up the actual method to execute
 * in a hash of methods using the event's `code`:
 *
 * ```js
 * element.addEventListener(keydown, keyboardEventHandler({
 *   onArrowUp(event) {},
 *   onArrowDown(event) {},
 *   ...
 * }))
 * ```
 *
 * @param {Object} methods - The hash of methods
 * @param {Function} options.getMethodName - Customize how the method name is derived from event (default `on{keyboardEvent.code}`)
 * @param {Function} options.shouldPreventDefault - Customize if the handling the event should `preventDefault` (default `() => true`)
 * @returns
 */
function keyboardEventHandler(
  methods,
  {
    getMethodName = (keyboardEvent) => `on${keyboardEvent.code}`,
    shouldPreventDefault = () => true,
  } = {}
) {
  return function (keyboardEvent) {
    const methodName = getMethodName(keyboardEvent);

    if (methods[methodName]) {
      if (shouldPreventDefault(keyboardEvent)) {
        keyboardEvent.preventDefault();
      }
      methods[methodName](keyboardEvent);
    }
  };
}
