import { html } from 'lit';
import { datasetForLegendItem } from '../../chart_js';

export default function filtersItem(item, { chart }) {
  const inputId = `legend-${item.datasetIndex}`;
  const dataset = datasetForLegendItem(chart, item);

  // 'value' is the property id to be stored in the db.
  // 'data-chart-index-value' is the property dataset index used
  // to toggle the property in the chart.
  return html`<div class="form-check" ?data-hidden="${item.hidden}">
    <input
      class="form-check-input check_boxes required"
      type="checkbox"
      .checked="${!item.hidden}"
      value="${dataset.id}"
      name="analytics_setting[property_ids][]"
      id="${inputId}"
      data-analytics-data-target="propertyInput"
      data-chart-index-value="${item.datasetIndex}"
      data-action="change->chart#toggleDataset change->analytics-data#reloadDataFrame"
      data-chart-submit-properties-with-date-target="propertyInput"
    />
    <label class="form-check-label collection_check_boxes" for="${inputId}"
      >${item.text}
    </label>
  </div>`;
}
