import { Controller } from 'stimulus';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // `timezone` plugin relies on `utc`
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// Use dayjs to alter the text content of a `<time>` element.
// It'll use the `datetime` attribute and swap the date to either
// "Today" or "Yesterday" if applicable.
// This relies on the current timezone set in a `<meta>` tag
export default class extends Controller {
  connect() {
    if (this.currentTimezone && this.datetime && this.isTodayOrYesterday) {
      this.updateTextContent();
    }
  }

  updateTextContent() {
    const dayText = this.isToday ? 'Today' : 'Yesterday';

    // Remove the date (everything before "|")
    const timeText = this.element.textContent.substring(
      this.element.textContent.indexOf('|') + 1
    );

    this.element.textContent = `${dayText} | ${timeText}`;
  }

  // Day comparisons

  get isTodayOrYesterday() {
    return this.isToday || this.isYesterday;
  }

  get isToday() {
    return this.userDatetime.isSame(this.datetime, 'day');
  }

  get isYesterday() {
    const userDatetimeYesterday = this.userDatetime.subtract(1, 'day');

    return userDatetimeYesterday.isSame(this.datetime, 'day');
  }

  // Get the datetimes to compare in the correct timezone

  get userDatetime() {
    return dayjs().tz(this.currentTimezone);
  }

  get datetime() {
    const datetimeAttribute = this.element.getAttribute('datetime');

    return dayjs(datetimeAttribute).tz(this.currentTimezone);
  }

  get currentTimezone() {
    return document.querySelector('meta[name="current_timezone"]')?.content;
  }
}
