import { Controller } from 'stimulus';
import Sortable from 'sortablejs';

const CLASS_SORTABLE_GHOST = 'amba-sortable-ghost';
const SELECTOR_HANDLE_BTN = '.amba-sortable-handle';

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      ghostClass: CLASS_SORTABLE_GHOST,
      handle: SELECTOR_HANDLE_BTN,
      onUpdate: this.onUpdate,
    });
  }

  onUpdate = (event) => {
    const url = event.item.dataset.sortableDraggableOnlyUrl;
    const insertAt = event.newIndex + 1;

    fetch(url, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify({
        insert_at: insertAt,
      }),
    });
  };

  get headers() {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
        ?.content,
      'Content-type': 'application/json; charset=UTF-8',
    };
  }
}
