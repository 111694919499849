import { Controller } from 'stimulus';
import { morph } from '../javascript/lib/turbo/stream/custom_actions/morphdom';

const CACHE = new Map();

/**
 * Keeps the element up to date with the latest version
 * that was added to the page (usually from the server),
 * allowing to keep up to date versions when Turbo shows
 * its cache.
 *
 * This is different from `data-turbo-permanent` as
 * `data-turbo-permanent` carries the initial version
 * from page to page, while this brings the newest element.
 *
 * Elements are cached and restored using their `id` attribute
 * (though we could introduce a `key` value to the controller
 * if need arose).
 *
 * Sometimes you'll want to preserve some attributes from the element
 * on the page. A link being active for example. You pass a space separated
 * list to the `data-preserve-attributes` to tell which attributes should
 * be keep the value they already have on the page
 */
export default class extends Controller {
  static values = {
    // Marks whether this is a new version of the element
    // or one that comes from the cache
    cached: Boolean,
    // Marks if the element has been restored already
    // so we avoid looping infinitely
    restored: Boolean,
  };

  get cacheKey() {
    return this.element.id;
  }

  get ignoredAttributes() {
    if (this.hasIgnoreAttributesValue) {
      return this.ignoreAttributesValue.split(' ');
    }
    return [];
  }

  connect() {
    // If we're already cached and connecting
    // that means Turbo is restoring the page
    // so we need to restore whatever is in the cache
    if (this.cachedValue) {
      if (!this.restoredValue) {
        const cachedElement = CACHE.get(this.cacheKey);

        if (cachedElement) {
          // Unlikely, but let's be safe
          this.restore(cachedElement);
        }
      }
    } else {
      this.cache();
    }

    // Morphdom will not disconnect/reconnect the element
    // (which is what it's there for, so we keep focus on the element)
    // If we're inside a morphed element though, we may
    // need to add a MutationObserver, however
    // this doesn't seem to catch text changes only
    this.handleMorph = this.handleMorph.bind(this);
    this.element.addEventListener('morphdom:morphed', this.handleMorph);
  }

  disconnect() {
    this.element.removeEventListener('morphdom:morphed', this.handleMorph);
  }

  handleMorph() {
    this.cache();
  }

  scheduleCaching() {
    // We only want to run it once a frame
    if (!this.animationFrame) {
      this.animationFrame = requestAnimationFrame(() => {
        this.animationFrame = null;
        this.cache();
      });
    }
  }

  cache() {
    // Prevent looping from the mutation observer
    // which gets triggered each time the value is set
    if (!this.cachedValue) {
      this.cachedValue = true;
      // Store a direct reference to the element so that any update
      // to the page gets stored in the cache
      CACHE.set(this.cacheKey, this.element);
    }
  }

  restore(cachedElement) {
    // Clone the element so we can restore multiple times in a row
    // for example when pressing back multiple times
    const replacement = cachedElement.cloneNode(true);
    replacement.setAttribute('data-evergreen-restored-value', 'true');

    morph(this.element, replacement);
  }
}
