/**
 * Helpers to bind Stimulus controllers to Turbo lifecycle
 * @module lib/stimulus_turbo
 */

/**
 * Wraps given `connect` method to add a call to `this.disconnect`
 * before Turbo caches the page. Necessary as `disconnect` is called
 * when the element leaves the DOM when Turbo replaces the page content
 * which is after `turbo:before-cache` has happened
 * @param {Function} fn - The `connect` method to wrap
 * @returns Function
 */
export function withDisconnectBeforeCache(fn) {
  return function () {
    document.addEventListener(
      'turbo:before-cache',
      () => {
        this.disconnect();
      },
      { once: true }
    );
    fn.apply(this, arguments);
  };
}

/**
 * Wraps given function to prevent its execution
 * if the page is a Turbo preview
 * @param {Function} fn
 * @returns Function
 */
export function withPreviewPrevention(fn) {
  return function () {
    // Check for the instance variable first as, when decorating `disconnect`
    // Turbo will have replaced the page with a non-preview
    if (this.isTurboPreview || isTurboPreview()) {
      // Mark that we're in a turbo preview
      this.isTurboPreview = true;
      return;
    }

    fn.apply(this, arguments);
  };
}

/**
 * Checks if the page currently displayed is a Turbo preview
 * @returns Boolean
 */
export function isTurboPreview() {
  return document.documentElement.hasAttribute('data-turbo-preview');
}
