import { isSameItem } from '../chart_js';

/**
 * Provides screen-reader announcements by updating the content
 * of the given element with the content of the tooltip for the
 * current active element on the chart.
 * @param {HTMLElement} announcementElement - The element whose content will be updated. The plugins automatically makes it a live element
 * @returns {Object}
 */
export function announceTooltips(announcementElement) {
  announcementElement.setAttribute('aria-live', 'polite');

  return {
    beforeDraw(chart) {
      const activeItem = chart.getActiveElements()[0];
      // Technically we'll write the same thing if the item
      // remains the same so that shouldn't re-trigger announcements
      // But as a safety, as well as not to re-render unnecessarily
      // best to to check that there's been a change.
      if (!isSameItem(activeItem, this.activeItem)) {
        if (activeItem) {
          this.announceTooltip(chart, activeItem);
        } else {
          this.clearAnnouncement();
        }

        this.activeItem = activeItem;
      }
    },
    announceTooltip(chart, { datasetIndex, index }) {
      const controller = chart.getDatasetMeta(datasetIndex).controller;
      const { label: time, value } = controller.getLabelAndValue(index);
      const datasetName = chart.data.datasets[datasetIndex].label;

      // Put the dataset name last to avoid delaying users access to the information (time, value)
      // when going through a series, similarly to how fieldset get announced last
      announcementElement.textContent = `${time}: ${value}, ${datasetName}`;
    },
    clearAnnouncement() {
      announcementElement.textContent = '';
    },
  };
}
