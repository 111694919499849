import Modal from 'bootstrap/js/src/modal';
import { Controller } from 'stimulus';

/**
 * Make a Bootstrap modal responsive, closing automatically
 * when the viewport no longer matches the `mediaQuery` provided as value
 */
export default class extends Controller {
  static values = {
    mediaQuery: String,
  };

  connect() {
    this.mediaQueryList = window.matchMedia(this.mediaQueryValue);

    this.handleChange = this.handleChange.bind(this);
    this.mediaQueryList.addListener(this.handleChange);
  }

  disconnect() {
    this.mediaQueryList.removeListener(this.handleChange);
  }

  handleChange(event) {
    if (!event.matches) {
      const instance = Modal.getInstance(this.element);
      if (instance) {
        instance.hide();
      }
    }
  }
}
