import { Controller } from 'stimulus';
import createActivityDetector from 'activity-detector';

const CLASS_HIDDEN = 'd-none';
const TIMER_DURATION_SECONDS = 30;
const ACTIVITY_DETECTOR_TIME_TO_IDLE_SECONDS = 600;

const ACTIVITY_DETECTOR_CONFIG = {
  timeToIdle: ACTIVITY_DETECTOR_TIME_TO_IDLE_SECONDS * 1000,
  ignoredEventsWhenIdle: [], // Defaults to `['mousemove']` which we don't want to ignore
  inactivityEvents: [], // Ignore all inactivity events that force a change from `active` to `idle`, we only want the timer
};

/**
 * Detect active time spent by a user on a page (`<body>`) that this controller is attached to.
 *
 * - Run a repeating timer for `TIMER_DURATION_SECONDS`
 * - When finished send a POST request to `this.urlValue`, with `this.requestData`
 * - Use activity-detector to pause the timer when the user's been inactive for `ACTIVITY_DETECTOR_TIME_TO_IDLE_SECONDS`
 * - Resume the timer when the user is active again
 * - We also use the Screen Wake Lock API to prevent the device from sleeping. A switch is displayed on the
 *   page, and the permission is retried on document click when browsers (Safari) block it when the user's not
 *   interacted with the page yet. More details - https://github.com/Amba-Health/amba/pull/2016
 * - Note: Safari PWA state they support the Screen Wake Lock API but there is long-standing bug preventing it
 *         from working
 */
export default class extends Controller {
  static targets = ['wakeLockToggleContainer', 'wakeLockToggle'];

  static values = {
    url: String,
    userId: Number,
    controllerName: String,
    controllerAction: String,
  };

  connect() {
    this.setupTimer();
    this.initActivityDetector();
    this.setupWakeLock();

    /**
     * The `disconnect` method will automatically handle turbo visits.
     *
     * This `beforeunload` event will catch all other page changes:
     *   - Hard refreshes
     *   - Hard page navigation
     *   - Closing the tab/browser
     */
    window.addEventListener(
      'beforeunload',
      () => {
        this.disconnect();
      },
      { once: true }
    );
  }

  disconnect() {
    this.activityDetector.stop();
    clearInterval(this.timer);
    this.updateLog(false);
    this.releaseWakeLock();
  }

  // Activity detector

  initActivityDetector() {
    this.activityDetector = createActivityDetector(ACTIVITY_DETECTOR_CONFIG);
    this.setupActivityDetectorEvents();
  }

  setupActivityDetectorEvents() {
    this.activityDetector.on('idle', this.pauseTimer);

    this.activityDetector.on('active', this.startTimer);
  }

  // Timer

  setupTimer() {
    this.timeLeft = TIMER_DURATION_SECONDS;
    this.startDatetime = new Date();

    this.startTimer();
  }

  startTimer = () => {
    this.timer = setInterval(this.runTimer, 1000);
  };

  runTimer = () => {
    this.timeLeft = this.timeLeft - 1;

    if (this.timeLeft <= 0) {
      clearInterval(this.timer);
      this.updateLog();
    }
  };

  pauseTimer = () => {
    clearInterval(this.timer);
  };

  // Ajax request

  async updateLog(restartTimer = true) {
    try {
      const response = await fetch(this.urlValue, {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify(this.requestData),
      });

      if (response.ok) {
        if (restartTimer) {
          this.setupTimer();
        }
      } else {
        window.appsignal.sendError('Error creating Vip::RpmStatusLog');
      }
    } catch (error) {
      window.appsignal.sendError(`Error creating Vip::RpmStatusLog: ${error}`);
    }
  }

  get requestData() {
    return {
      user_id: this.userIdValue,
      start_datetime: this.startDatetime,
      end_datetime: new Date(),
      time_elapsed: TIMER_DURATION_SECONDS - this.timeLeft,
      controller_name: this.controllerNameValue,
      controller_action: this.controllerActionValue,
      status: 'chunk',
    };
  }

  get headers() {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
        ?.content,
      'Content-type': 'application/json; charset=UTF-8',
    };
  }

  // WakeLock

  setupWakeLock() {
    if (this.wakeLockSupported) {
      this.wakeLock = null;

      this.wakeLockToggleContainerTarget.classList.remove(CLASS_HIDDEN);

      this.requestWakeLock();

      // We need to re-request wakelock permission after visibility changes e.g changing tabs
      document.addEventListener('visibilitychange', () => {
        if (this.wakeLock !== null && document.visibilityState === 'visible') {
          this.requestWakeLock();
        }
      });
    }
  }

  async requestWakeLock(preventClickRetry = false) {
    try {
      this.wakeLock = await navigator.wakeLock.request('screen');
      this.wakeLockToggleTarget.checked = true;

      this.wakeLock.addEventListener('release', () => {
        this.wakeLockToggleTarget.checked = false;
      });
    } catch (err) {
      if (!preventClickRetry) {
        // On some occasions the request fails as the user needs to interact with the document first
        // On this occasion re-run this method after the user performs a click
        document.addEventListener(
          'click',
          () => {
            this.requestWakeLock();
          },
          { once: true }
        );
      }
    }
  }

  toggleWakeLock({ target }) {
    if (this.wakeLockSupported) {
      if (target.checked) {
        this.requestWakeLock(true);
      } else {
        this.releaseWakeLock();
      }
    }
  }

  releaseWakeLock() {
    if (this.wakelock) {
      this.wakeLock
        .release()
        .then(() => {
          this.wakeLock = null;
          return;
        })
        .catch(() => {
          // Do nothing
        });
    }
  }

  get wakeLockSupported() {
    // Use the iOS safari-only `standalone` to detect if we're in the PWA or not and
    // block wakelock functionality as it won't work (see top of file)
    if ('standalone' in window.navigator && window.navigator.standalone) {
      return false;
    } else {
      return 'wakeLock' in navigator;
    }
  }
}
