const COLOR_CHART_BACKGROUND_THEME_LIGHT = '#f4f9fd';
const COLOR_CHART_BACKGROUND_THEME_DARK = '#474c4e';

/**
 * Add a background color to the chart, only inside the axes.
 * This is needed as Chart.js options add a background color
 * to the entire canvas.
 * @param {String} color - hex color value including #
 * @returns {Object}
 */
export function drawChartBackgroundColor(color) {
  return {
    beforeDraw: (chart) => {
      const {
        ctx,
        chartArea: { top, left, width, height },
      } = chart;

      ctx.save();

      ctx.fillStyle = color;
      ctx.fillRect(left, top, width, height);

      ctx.restore();
    },
  };
}

export function chartBackground() {
  return drawChartBackgroundColor(getChartBackgroundColorForTheme());
}

function getChartBackgroundColorForTheme() {
  return currentTheme() === 'light'
    ? COLOR_CHART_BACKGROUND_THEME_LIGHT
    : COLOR_CHART_BACKGROUND_THEME_DARK;
}

export function currentTheme() {
  // Gets theme value from the cookie string
  const value = `; ${document.cookie}`;
  const parts = value.split(`; theme=`);
  const theme = parts.length === 2 ? parts.pop().split(';').shift() : 'light';

  return theme;
}
