import { Controller } from 'stimulus';

/**
 * An action for scrolling to the top of the window
 * when a turbo-frame is rendered.
 */
export default class extends Controller {
  scroll() {
    document.addEventListener(
      'turbo:frame-load',
      () => {
        window.scrollTo(0, 0);
      },
      { once: true }
    );
  }
}
