import { html } from 'lit';
import { repeat } from 'lit/directives/repeat';
import filtersItem from './filters_item';

export default function filtersCategory(category, { chart }) {
  const dropdownId = `category_dropdown_${category.id}`;
  const shownItemsNumber = category.items.filter((item) => !item.hidden).length;

  // data-bs-display="static" is used to ensure the
  // dropdown always appears at the bottom
  return html`<div class="amba-dropdown-checkbox-select dropdown w-100">
    <button
      aria-expanded="false"
      class="amba-dropdown-checkbox-select__btn form-select select rythm-x-2 text-start fw-bold"
      data-bs-auto-close="outside"
      data-bs-toggle="dropdown"
      data-bs-display="static"
      id="${dropdownId}"
      type="button"
    >
      <span class="visually-hidden"
        >${category.name} properties, ${shownItemsNumber} selected</span
      >
      <span aria-hidden="true" class="ms-0">${category.name}</span>
      <span
        aria-hidden="true"
        class="amba-dropdown-checkbox-select__btn-counter bg-primary text-white fw-bold ${shownItemsNumber
          ? ''
          : 'd-none'}"
        >${shownItemsNumber}</span
      >
    </button>
    <fieldset
      class="check_boxes amba-dropdown-checkbox-select__dropdown dropdown-menu amba-scroll-shadow"
      aria-labelledby="${dropdownId}"
    >
      <div class="amba-scroll-shadow__inner">
        <div class="amba-scroll-shadow__inner-content">
          <legend class="visually-hidden">${category.name}</legend>
          ${repeat(
            // `repeat` will associate a key (1st function)
            // to each element rendered by the template (2nd function)
            // This allows `lit` to reuse the right elements when items
            // get filtered out after being unchecked.
            // In turn, this ensures that the filters items keep the right
            // state for the checkbox, instead of having the unchecked
            // element reused if it was in a position still visible
            // https://lit.dev/docs/templates/lists/#when-to-use-map-or-repeat
            category.items,
            (item) => item.text,
            (item) => filtersItem(item, { chart })
          )}
        </div>
      </div>
    </fieldset>
  </div>`;
}
