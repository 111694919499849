/**
 * Helpers for manipulating hex colors
 */
export function opacify(colorHex) {
  return isTransparent(colorHex) ? colorHex.slice(0, -2) : colorHex;
}

export function makeTransparent(colorHex, transparency = '4d') {
  // Make it update the transparency if the color was already transparent
  return opacify(colorHex) + transparency;
}

export function isTransparent(colorHex) {
  return colorHex.length === 9;
}

/**
 * Normalizes the given hex string so the RGB values are on 6 chars
 * rather than on 3
 * @param {*} colorHex
 */
export function normalize(colorHex) {
  // #rrggbb or #rrggbbaa
  if (colorHex.length == 7 || colorHex.length == 9) {
    return colorHex;
  }

  return colorHex
    .split('')
    .map((c) => (c == '#' ? c : c + c))
    .join('');
}
