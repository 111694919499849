import { Controller } from 'stimulus';
import Offcanvas from 'bootstrap/js/src/offcanvas';

/**
 * WeakMap linking elements to their associated Offcanvas object
 * to keep track tidily while allowing garbage collection when
 * the elements disappear
 * @private
 */
const INSTANCES = new WeakMap();

const SELECTOR_OFFCANVAS = '.offcanvas';
const ATTRIBUTE_DATA_DIALOG_PREVENT_DEFAULT =
  'data-aria-dialog-prevent-default';

/**
 * A single controller to manage offcanvas components on the page
 */
export default class extends Controller {
  connect() {
    // If the scroll position is at the top and the offcanvas is opened,
    // some browsers will scroll to it whilst some won't.
    // This causes a constrained height where content gets cut off at
    // the bottom, as its height is set relative to the viewport.
    // This scrolls down to the offcanvas if needed so all browsers behave
    // the same.
    this.element.addEventListener('shown.bs.offcanvas', ({ target }) => {
      this.scrollToOffcanvas(target);
    });
  }

  toggle(event) {
    // Allow ctrl-clicks and other specials ways of clicking the link
    // to function as before
    if (hasModifiers(event)) return;

    const id = event.currentTarget.getAttribute('aria-controls');
    const target = document.getElementById(id);

    if (!target) {
      if (process.env.NODE_ENV == 'development') {
        console.error(
          `No target with this id exists to open offcanvas`,
          `id: ${id}`,
          `target: ${event.currentTarget}`
        );
      }
      return;
    }

    const offcanvas = this.instanceFor(target);

    // Only prevent default after ensuring there's a target dialog to open
    // so that dialogs hooked on links follow the link in case there's no dialog
    // on the page. Preventing default would block turbo requests
    if (shouldPreventDefault(event.currentTarget)) event.preventDefault();

    offcanvas.toggle(event.target);
  }

  close(event) {
    if (shouldPreventDefault(event.currentTarget)) event.preventDefault();

    const offcanvasEl = event.target.closest(SELECTOR_OFFCANVAS);
    if (offcanvasEl) {
      const offcanvas = this.instanceFor(offcanvasEl);

      offcanvas.hide();
    }
  }

  instanceFor(target) {
    return INSTANCES.get(target) || this.createOffcanvas(target);
  }

  createOffcanvas(target) {
    const offcanvas = new Offcanvas(target);
    INSTANCES.set(target, offcanvas);

    return offcanvas;
  }

  scrollToOffcanvas(target) {
    if (scrollPositionAboveOffcanvas(target)) target.scrollIntoView();
  }
}

function scrollPositionAboveOffcanvas(offcanvas) {
  return (
    window.scrollY < window.pageYOffset + offcanvas.getBoundingClientRect().top
  );
}

function shouldPreventDefault(el) {
  return el.getAttribute(ATTRIBUTE_DATA_DIALOG_PREVENT_DEFAULT) !== 'false';
}

/**
 * Checks whether the given event has any modifier (Ctrl, Shift, Alt, meta)
 * or is a right click
 * @param {MouseEvent} event
 */
function hasModifiers(event) {
  return (
    event.which > 1 ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey
  );
}
