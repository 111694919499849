// app/javascript/controllers/escalation_controller.js

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['countdown', 'time'];
  static values = {
    time: String,
    outcome: String,
    escalatedAt: String,
  };

  connect() {
    this.updateCountdown();
    setInterval(() => this.updateCountdown(), 1000);
  }

  updateCountdown() {
    const currentTime = new Date().getTime();
    this.countdownTargets.forEach((target) => {
      const targetTime = new Date(this.timeValue).getTime();

      if (!isNaN(targetTime)) {
        const difference = targetTime - currentTime;
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        // If the outcome is cancelled, we don't want to display the countdown/text
        if (this.outcomeValue == 'cancelled') {
          target.textContent = '';
          return;
        }

        const isTimeNegative = hours < 0 || minutes < 0 || seconds < 0;

        // Handle pending states
        if (this.outcomeValue == 'pending') {
          if (isTimeNegative) {
            // Placeholder text until EscalationIntention is executed
            target.textContent = 'Alert escalated';
          } else {
            // Display the countdown
            if (hours > 0) {
              const datetimeAttribute = `PT${hours}H${minutes}M${seconds}S`;
              const formattedTime = `${hours}:${minutes
                .toString()
                .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
              target.innerHTML = `Escalation in <time datetime="${datetimeAttribute}">${formattedTime}</time>`;
            } else {
              const datetimeAttribute = `PT${minutes}M${seconds}S`;
              const formattedTime = `${minutes}:${seconds
                .toString()
                .padStart(2, '0')}`;
              target.innerHTML = `Escalation in <time datetime="${datetimeAttribute}">${formattedTime}</time>`;
            }
          }
          return;
        }

        // When EscalationIntention is executed, we want to display the escalatedAt time
        if (this.outcomeValue == 'escalated') {
          target.textContent = this.escalatedAtValue;
          return;
        }
      } else {
        target.textContent = 'Invalid time format';
      }
    });
  }
}
