import { Controller } from 'stimulus';

/**
 * On connect, fetch a Turbo stream string and render it to the page
 */
export default class extends Controller {
  static values = { url: String };

  async connect() {
    try {
      this.element.toggleAttribute('aria-busy', 'true');

      const response = await fetch(this.urlValue, {
        method: 'GET',
        headers: this.headers,
      });

      if (response.ok) {
        const newRow = await response.text();
        window.Turbo.renderStreamMessage(newRow);
      } else {
        window.appsignal.sendError(
          `Error fetching VIP RPM table row from ${this.urlValue}`
        );
      }
    } catch (error) {
      window.appsignal.sendError(
        `Error fetching VIP RPM table row from ${this.urlValue}, with error: ${error}`
      );
    }

    this.element.toggleAttribute('aria-busy', 'false');
  }

  get headers() {
    return {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
        ?.content,
      Accept: 'text/vnd.turbo-stream.html',
    };
  }
}
