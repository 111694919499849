import { Controller } from 'stimulus';

const CLASS_HIDDEN = 'd-none';

/**
 * Handles fetching a pdf and generating a blob url,
 * as well as toggling modal feedback content
 */
export default class extends Controller {
  static targets = [
    'generateButton',
    'modalLoadingContent',
    'modalSuccessContent',
    'modalErrorContent',
    'downloadLink',
  ];

  disconnect() {
    this.clearFileFromMemory();
  }

  fetchPdf() {
    const url = this.generateButtonTarget.href;

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        return;
      })
      .then((blob) => {
        this.addFileUrlToButtons(blob);
        this.showModalSuccessContent();
        return;
      })
      .catch(() => {
        this.showModalErrorContent();
      });
  }

  addFileUrlToButtons(blob) {
    // create an object URL from the Blob
    const URL = window.URL || window.webkitURL;
    this.downloadUrl = URL.createObjectURL(blob);

    // set object URL as the anchor's href
    this.downloadLinkTarget.href = this.downloadUrl;
  }

  clearFileFromMemory() {
    // Removes the file from the browser's memory
    URL.revokeObjectURL(this.downloadUrl);
  }

  resetModal() {
    this.modalLoadingContentTarget.classList.remove(CLASS_HIDDEN);
    this.modalSuccessContentTarget.classList.add(CLASS_HIDDEN);
    this.modalErrorContentTarget.classList.add(CLASS_HIDDEN);
    this.downloadLinkTarget.href = '';

    this.clearFileFromMemory();
  }

  showModalSuccessContent() {
    this.modalLoadingContentTarget.classList.add(CLASS_HIDDEN);
    this.modalSuccessContentTarget.classList.remove(CLASS_HIDDEN);
  }

  showModalErrorContent() {
    this.modalLoadingContentTarget.classList.add(CLASS_HIDDEN);
    this.modalErrorContentTarget.classList.remove(CLASS_HIDDEN);
  }
}
