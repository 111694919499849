import { Controller } from 'stimulus';
import { ifRelevantTo } from '../../javascript/lib/turbo/fetch_events';

/**
 * Renders generic error messages when a 500 error
 * or a broken network prevents a Turbo frame from loading.
 *
 * Controller will usually be set-up on the frame,
 * but could be positioned higher up in the DOM tree.
 * It'll prepend the error message, either to itself
 * or to its `container` target if it exists.
 */
export default class extends Controller {
  static targets = [
    'container', // The element into which to inject the error
  ];

  get container() {
    if (this.hasContainerTarget) {
      // This could be updated to pick the deepest target with something like
      // `this.containerTargets[this.containerTargets.length - 1]`.
      return this.containerTarget;
    }

    return this.element;
  }

  connect() {
    this.errorElement = this.createErrorElement();

    this.listeners = {
      'turbo:before-fetch-request': ifRelevantTo(
        this.element,
        this.removeError.bind(this)
      ),
      // We only need to handle the error case as the frame being loaded
      // will clear the content of the frame
      'amba:fetch-error': ifRelevantTo(
        this.element,
        this.handleTurboEvent.bind(this)
      ),
    };

    for (const [eventType, listener] of Object.entries(this.listeners)) {
      // Listening on `document` to get the same flow for both:
      // - requests from clicking links, where event is triggered from the frame itself
      // - requests from submitting forms, where event is triggered from the form
      document.addEventListener(eventType, listener);
    }
  }

  disconnect() {
    for (const [eventType, listener] of Object.entries(this.listeners)) {
      // Listening on `document` to get the same flow for both:
      // - requests from clicking links, where event is triggered from the frame itself
      // - requests from submitting forms, where event is triggered from the form
      document.removeEventListener(eventType, listener);
    }
  }

  removeError() {
    this.errorElement.remove();
  }

  handleTurboEvent(event) {
    // Early break to not render errors when server returns a 4XX status
    // as these kind of errors will already get a notice of some kind in
    // the rendering (eg. invalid state on form fields)
    if (
      event.detail.eventFromTurbo &&
      event.detail.eventFromTurbo.type == 'turbo:before-fetch-response' &&
      event.detail.eventFromTurbo.detail.fetchResponse.response.status < 500
    ) {
      return;
    }

    this.renderError(event);

    // We're taking over the error rendering so we need to prevent Turbo
    // from doing anything with the response: it'd mess with our rendering
    // as Turbo's default behaviour is to clear the frame on error.
    if (
      event.detail.eventFromTurbo &&
      event.detail.eventFromTurbo.type == 'turbo:before-fetch-response'
    ) {
      event.detail.eventFromTurbo.preventDefault();
    }
  }

  renderError(event) {
    this.removeError();
    this.updateErrorElementTextFor(event);
    // Only prepend the error content so that a form whose submission errors
    // remains on-screen to be re-submitted
    this.container.insertAdjacentElement('afterbegin', this.errorElement);
    requestAnimationFrame(() => {
      this.errorElement.focus();
    });
  }

  createErrorElement() {
    const div = document.createElement('div');
    div.classList.add('alert');
    div.classList.add('alert-danger');
    div.classList.add('mb-0');
    div.classList.add('rythm-y-2');
    div.setAttribute('tabindex', '-1'); // Make the div focusable so we can focus it later on
    div.innerHTML = 'Error!';
    return div;
  }

  updateErrorElementTextFor(event) {
    // Only network failures will have an `error` property,
    // server errors will only have the `fetchRequest` or `formSubmission`
    // in `event.detail`. See `lib/turbo/fetch_errors.js`
    if (event.detail.error) {
      this.errorElement.innerHTML = `
        <h2 class="h5">Network error</h2>
        <p>Sorry, we could not contact Amba server to accomplish the action.<br>
        Please check you're connected to the internet and try again.</p>
      `;
    } else {
      this.errorElement.innerHTML = `
        <h2 class="h5">Unexpected error</h2>
        <p>Sorry, we didn't manage to complete the action.<br>
        Please try again in a moment.</p>
      `;
    }
  }
}
