import { Controller } from 'stimulus';
import SimpleBar from 'simplebar';

/**
 * Chrome & Safari mobile apps auto-hide scrollbars when they're
 * not being scrolled. This library replaces the native scrollbar
 * with an identical version that's always visible
 */
export default class extends Controller {
  connect() {
    this.simpleBar = new SimpleBar(this.element, {
      autoHide: false,
    });
  }
}
