import { Controller } from 'stimulus';

const CLASS_SHOW_BULK_ACTIONS = 'amba-vip-feed-table--show-bulk-actions';

export default class extends Controller {
  static targets = ['table'];

  static values = {
    exclusive: Boolean,
  };

  get areBulkActionsShown() {
    return this.tableTarget.classList.contains(CLASS_SHOW_BULK_ACTIONS);
  }

  connect() {
    // It's not guaranteed that a `NodeList` will have a `forEach`
    // property so `for...of` is safer
    for (const toggle of this.findToggles()) {
      toggle.setAttribute('aria-expanded', this.areBulkActionsShown);
    }
  }

  toggleActions() {
    if (this.areBulkActionsShown) {
      this.hideActions();
    } else {
      this.showActions();
    }
  }

  showActions() {
    const event = new CustomEvent('amba:bulk_actions.will.showActions', {
      bubbles: true,
    });
    this.element.dispatchEvent(event);

    this.tableTarget.classList.add(CLASS_SHOW_BULK_ACTIONS);
    for (const toggle of this.findToggles()) {
      toggle.setAttribute('aria-expanded', 'true');
    }
  }

  hideActions() {
    this.tableTarget.classList.remove(CLASS_SHOW_BULK_ACTIONS);
    for (const toggle of this.findToggles()) {
      toggle.setAttribute('aria-expanded', 'false');
    }
    this.resetCheckboxes();

    const event = new CustomEvent('amba:bulk_actions.did.hideActions', {
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  resetCheckboxes() {
    for (const checkbox of this.findCheckboxes()) {
      checkbox.checked = false;
      // We also need to clear the indeterminate state
      checkbox.indeterminate = false;
    }
  }

  findCheckboxes() {
    return this.element.querySelectorAll('[type="checkbox"]');
  }

  findToggles() {
    return this.element.querySelectorAll(
      withStimulusAction('bulk-actions', 'toggleActions')
    );
  }
}

/**
 * Creates a CSS selector to match elements triggering a specific action
 * on a specific controller
 * @returns String
 */
function withStimulusAction(controllerName, actionName) {
  const attributeValue = `${controllerName}#${actionName}`;

  // Match both the action being in the middle of a list of actions
  // or at the end/the only one
  return `[data-action*="${attributeValue} "], [data-action$="${attributeValue}"]`;
}
