import { Controller } from 'stimulus';
import tippy from 'tippy.js';

/**
 * Base controller for mounting [Tippy](https://atomiks.github.io/tippyjs/)
 * tooltips and popover. Configuration is adjusted for each in the respective
 * TooltipController and PopoverController
 */
export default class extends Controller {
  static values = {
    trigger: String,
    placement: String,
    content: String,
    contentSelector: String,
    arrow: Boolean,
    appearance: String,
    appendToSelector: String,
    showOnCreate: Boolean,
    options: Object,
    referenceSelector: String,
    destroyOnceHidden: Boolean,
  };

  get trigger() {
    return this.triggerValue || 'click';
  }

  get arrow() {
    return this.hasArrowValue ? this.arrowValue : this.defaultArrow;
  }

  get defaultArrow() {
    return false;
  }

  get placement() {
    return this.placementValue || 'bottom-end';
  }

  get appearance() {
    return this.appearanceValue;
  }

  get content() {
    return (
      this.contentValue ||
      this.prepareElementContent(
        this.elementFromSelector || this.element.nextElementSibling
      )
    );
  }

  get elementFromSelector() {
    return (
      this.contentSelectorValue &&
      document.querySelector(this.contentSelectorValue)
    );
  }

  get showOnCreate() {
    return this.showOnCreateValue;
  }

  get appendTo() {
    if (this.hasAppendToSelectorValue) {
      return document.querySelector(this.appendToSelectorValue);
    } else {
      return this.element.parentNode;
    }
  }

  get options() {
    return this.optionsValue || {};
  }

  get reference() {
    if (this.referenceSelectorValue) {
      return document.querySelector(this.referenceSelectorValue);
    }

    return this.element;
  }

  connect() {
    this.instance = tippy(
      this.reference,
      Object.assign({}, this.tippyOptions, this.options)
    );
  }

  disconnect() {
    if (this.destroyOnceHiddenValue && this.instance.state?.isVisible) {
      const originalOnHide = this.instance.onHide;
      this.instance.onHide = () => {
        originalOnHide.apply(this.instance, arguments);
        this.instance.destroy();
      };
    } else {
      this.instance.destroy();
    }
  }

  get tippyOptions() {
    return {
      allowHTML: true,
      interactive: true,
      ignoreAttributes: true, // We're using Stimulus values
      trigger: this.trigger,
      // Enforce `this.element` to be the trigger
      // on which events will be listened to,
      // for when using a separate `reference`
      // on which to attach the tooltip
      triggerTarget: this.element,
      placement: this.placement,
      arrow: this.arrow,
      content: this.content,
      showOnCreate: this.showOnCreate,
      maxWidth: null,
      appendTo: this.appendTo,
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            options: {
              // Left in case the screen is small and zoomed in,
              // to let the popover get on the side where there's
              // more space
              fallbackPlacements: ['top-end', 'left'],
              padding: { bottom: 80 },
            },
          },
        ],
      },
      // Setup stimulus controller on the overlay
      // so that content within can close it on click
      // tippy already sets a `_tippy` property on the `Element`
      // That the controller will be able to retrieve
      onMount: () => {
        if (
          !hasDOMToken(this.instance.popper, 'data-controller', 'tippy-content')
        ) {
          prependDOMToken(
            this.instance.popper,
            'data-controller',
            'tippy-content'
          );
        }

        this.instance.popper.dataset.appearance = this.appearance;
      },
      onShown: () => {
        const shownEvent = new CustomEvent('tippy.shown', {
          bubbles: true,
        });
        this.element.dispatchEvent(shownEvent);
      },
    };
  }

  show() {
    this.instance.show();
  }

  hide() {
    this.instance.hide();
  }

  prepareElementContent(element) {
    // Clone the element so the original remains in the DOM, hidden
    const clone = element.cloneNode(true);
    element.setAttribute('hidden', '');

    // This is a temporary fix until we find a better solution (hiding
    // the original element).
    // Add "-hidden" to the original id to prevent issues with duplicated
    // id's
    element.id = `${element.id}-hidden`;
    document.addEventListener(
      'turbo:before-cache',
      () => {
        element.id = element.id.replace('-hidden', '');
      },
      { once: true }
    );

    // Remove the hidden attribute from the clone,
    // in case it was cached after setup or just hidden
    // from the start
    clone.removeAttribute('hidden', '');

    return clone;
  }
}

function hasDOMToken(element, attributeName, token) {
  const attributeValue = element.getAttribute(attributeName);

  return attributeValue && attributeValue.indexOf(token) !== -1;
}

function prependDOMToken(element, attributeName, token) {
  const attributeValue = element.getAttribute(attributeName);

  element.setAttribute(
    attributeName,
    [token, attributeValue].filter(Boolean).join(' ')
  );
}
