import { Controller } from 'stimulus';
import Modal from 'bootstrap/js/src/modal';

/**
 * WeakMap linking elements to their associated Modal object
 * to keep track tidily while allowing garbage collection when
 * the elements disappear
 * @private
 */
const INSTANCES = new WeakMap();

const SELECTOR_MODAL = '.modal';
const ATTRIBUTE_DATA_DIALOG_PREVENT_DEFAULT =
  'data-aria-dialog-prevent-default';

/**
 * A single controller to manage modals on the page
 */
export default class extends Controller {
  toggle(event) {
    // Allow ctrl-clicks and other specials ways of clicking the link
    // to function as before
    if (hasModifiers(event)) return;

    const id = event.currentTarget.getAttribute('aria-controls');
    const target = document.getElementById(id);

    if (!target) {
      if (process.env.NODE_ENV == 'development') {
        console.error(
          `No target with this id exists to open modal`,
          `id: ${id}`,
          `target: ${event.currentTarget}`
        );
      }
      return;
    }

    const modal = this.instanceFor(target);

    // Only prevent default after ensuring there's a target dialog to open
    // so that dialogs hooked on links follow the link in case there's no dialog
    // on the page. Preventing default would block turbo requests
    if (shouldPreventDefault(event.currentTarget)) event.preventDefault();

    modal.toggle(event.target);
  }

  close(event) {
    if (shouldPreventDefault(event.currentTarget)) event.preventDefault();

    const modalEl = event.target.closest(SELECTOR_MODAL);
    if (modalEl) {
      const modal = this.instanceFor(modalEl);

      modal.hide();
    }
  }

  instanceFor(target) {
    return INSTANCES.get(target) || this.createModal(target);
  }

  createModal(target) {
    const modal = new Modal(target);
    INSTANCES.set(target, modal);

    return modal;
  }
}

function shouldPreventDefault(el) {
  return el.getAttribute(ATTRIBUTE_DATA_DIALOG_PREVENT_DEFAULT) !== 'false';
}

/**
 * Checks whether the given event has any modifier (Ctrl, Shift, Alt, meta)
 * or is a right click
 * @param {MouseEvent} event
 */
function hasModifiers(event) {
  return (
    event.which > 1 ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey
  );
}
