import { Controller } from 'stimulus';
import focusableSelectors from 'focusable-selectors';

/*
 * A list of CSS selectors that match interactive elements
 * on which we want to let the browser handle the clicks as usual
 */
const SELECTOR_INTERACTIVE = focusableSelectors.join(',');
const SELECTOR_IGNORE = [SELECTOR_INTERACTIVE, '.tippy-box'].join(',');

const MOVEMENT_THRESHOLD = 10;

/**
 * Extends the clickable area of an child element to
 * be the entirety of the element the controller is set up on.
 * This is necessary to allow whole table rows to be clickable
 * in Safari, as it doesn't handle `position: absolute`
 * required by Bootstrap's `stretched-link` class
 *
 * The controller will ignore clicks on other interactive elements,
 * allowing to have other links and buttons
 */
export default class extends Controller {
  static targets = ['control'];

  // Because it makes a wider area clickable, `click` event may get triggered
  // while users is selecting text: the `mousedown` starting one side of the text,
  // the `mouseup` on the other side. The browser would take care of not treating as clicks
  // `mouseup` events that are not within the same element as the `mousedown`.
  // But because of the wider clickable area, they can be both the same and the click will trigger.
  // To circumvent that, we track the distance from the `mousedown` event and use a threshold
  // to consider whether the interaction was a click or not.
  // If this proves problematic in some cases, this could be coupled with a tracking of whether a selection
  // was actually made or cleared using the `selectionchange` event,
  // with special care for clearing the selection with a single click (this would trigger the click
  // on a regular link for example).
  get isActualClick() {
    return !(this.maxDistanceFromOrigin > MOVEMENT_THRESHOLD);
  }

  connect() {
    this.element.classList.add('amba-spread-click');
    // Two separate listeners to avoid creating functions again and again
    this.element.addEventListener(
      'mousedown',
      () => (this.maxDistanceFromOrigin = 0)
    );
    this.element.addEventListener(
      'mousedown',
      startDistanceTracking((distanceFromOrigin) => {
        this.maxDistanceFromOrigin = Math.max(
          this.maxDistanceFromOrigin,
          distanceFromOrigin
        );
      })
    );

    this.element.addEventListener('click', (event) => {
      if (!event.target.closest(SELECTOR_IGNORE) && this.isActualClick) {
        this.controlTarget.click();
      }
    });
  }
}

/* Reusable way to track distance from an origin mouseevent */
function startDistanceTracking(setDistance) {
  let origin;

  return function (event) {
    origin = event;
    window.addEventListener('mousemove', track);
    window.addEventListener('mouseup', stopTracking);
  };

  function track(event) {
    const dx = event.screenX - origin.screenX;
    const dy = event.screenY - origin.screenY;
    setDistance(Math.sqrt(dx ** 2 + dy ** 2));
  }

  function stopTracking() {
    window.removeEventListener('mousemove', track);
    window.removeEventListener('mouseup', stopTracking);
  }
}
