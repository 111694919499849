import { Controller } from 'stimulus';

/**
 * When there is one cocoon .nested-field, block the
 * removal of the section and instead clear the fields.
 *
 * This saves the user removing then re-adding the fields
 * and avoids styling issues.
 */
export default class extends Controller {
  static targets = ['nestedField'];

  clear() {
    if (this.visibleFields.length === 1) {
      event.preventDefault();
      event.stopImmediatePropagation();

      Array.from(this.visibleFields[0].elements).forEach(
        (el) => (el.value = '')
      );
    }
  }

  get visibleFields() {
    return this.nestedFieldTargets.filter(
      (field) => field.style.display !== 'none'
    );
  }
}
