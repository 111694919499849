import { isSameItem } from '../chart_js';
import { emphasizeActiveSeries } from '../fade_inactive_series';

export function highlightSeriesWithTooltip() {
  return {
    beforeDraw(chart) {
      const activeItem = chart.getActiveElements()[0];
      if (!isSameItem(activeItem, this.activeItem)) {
        requestAnimationFrame(() => {
          emphasizeActiveSeries(chart, activeItem);
          chart.update();
        });
        this.activeItem = activeItem;
      }
    },
  };
}
