import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['output', 'input'];

  readURL() {
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      var reader = new FileReader();

      reader.onload = () => {
        this.outputTarget.src = reader.result;
      };

      reader.readAsDataURL(this.inputTarget.files[0]);
    }
  }
}
