import { Controller } from 'stimulus';
import { updateProgressRequest } from '../../javascript/lib/academy/progress';

export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    window.addEventListener('message', this.checkForProgressUpdate);
  }

  disconnect() {
    window.removeEventListener('message', this.checkForProgressUpdate);
  }

  checkForProgressUpdate = ({ data }) => {
    if (this.isStorylaneEvent(data)) {
      this.updateProgressFor(data.payload.event);
    }
  };

  isStorylaneEvent(data) {
    return (
      data.message === 'storylane-demo-event' &&
      ['flow_start', 'flow_end'].includes(data.payload.event)
    );
  }

  updateProgressFor(event) {
    let percentage;

    if (event === 'flow_start') {
      percentage = 0;
    } else if (event === 'flow_end') {
      percentage = 100;
    }

    updateProgressRequest(percentage, this.urlValue);
  }
}
